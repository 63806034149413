/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RolesEnum {
    SUPER_ADMIN_MANAGE = 'super admin manage',
    SUPER_ADMIN_READ = 'super admin read',
    BEHEERDER = 'beheerder',
    BEHEERDER_BEKIJKEN = 'beheerder bekijken',
    ORDER_BEHEREN = 'order beheren',
    WEBSHOP_BEHEREN = 'webshop beheren',
    ARTIKEL_BEHEREN = 'artikel beheren',
    PROMOTIE_BEHEREN = 'promotie beheren',
    KLANTEN_BEHEREN = 'klanten beheren',
    FINANCIEEL_BEHEREN = 'financieel beheren',
    PERSONEEL_BEHEREN = 'personeel beheren',
    BEDRIJFSGEGEVENS_BEHEREN = 'bedrijfsgegevens beheren',
    WETGEVING_EN_ACCREDITATIE_BEHEREN = 'wetgeving en accreditatie beheren',
    EXTERNE_KOPPELING_BEHEREN = 'externe koppeling beheren',
    VOORRAAD_BEHEREN = 'voorraad beheren',
    RECEPTEN_BEHEREN = 'recepten beheren',
    PRODUCTIE_BEHEREN = 'productie beheren',
    DISTRIBUTIE_BEHEREN = 'distributie beheren',
    LOGISTIEK_BEHEREN = 'logistiek beheren',
    FILIALEN_BEHEREN = 'filialen beheren',
    KASSA_BEHEREN = 'kassa beheren',
    PRIJSKAART_BEHEREN = 'prijskaart beheren',
    ORDER_BEKIJKEN = 'order bekijken',
    WEBSHOP_BEKIJKEN = 'webshop bekijken',
    ARTIKEL_BEKIJKEN = 'artikel bekijken',
    PROMOTIE_BEKIJKEN = 'promotie bekijken',
    KLANTEN_BEKIJKEN = 'klanten bekijken',
    FINANCIEEL_BEKIJKEN = 'financieel bekijken',
    PERSONEEL_BEKIJKEN = 'personeel bekijken',
    BEDRIJFSGEGEVENS_BEKIJKEN = 'bedrijfsgegevens bekijken',
    WETGEVING_EN_ACCREDITATIE_BEKIJKEN = 'wetgeving en accreditatie bekijken',
    EXTERNE_KOPPELING_BEKIJKEN = 'externe koppeling bekijken',
    VOORRAAD_BEKIJKEN = 'voorraad bekijken',
    RECEPTEN_BEKIJKEN = 'recepten bekijken',
    PRODUCTIE_BEKIJKEN = 'productie bekijken',
    DISTRIBUTIE_BEKIJKEN = 'distributie bekijken',
    LOGISTIEK_BEKIJKEN = 'logistiek bekijken',
    FILIALEN_BEKIJKEN = 'filialen bekijken',
    KASSA_BEKIJKEN = 'kassa bekijken',
    PRIJSKAART_BEKIJKEN = 'prijskaart bekijken',
}

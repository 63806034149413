/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ArtikelGroupOverviewOut = {
    id: string;
    img_url?: (string | null);
    Artikelgroep: string;
    aantallen?: (Array<string> | null);
    Artikelcode: string;
    Periode_actief?: (string | null);
    Subgroep_van?: (string | null);
    Status: ArtikelGroupOverviewOut.Status;
};

export namespace ArtikelGroupOverviewOut {

    export enum Status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}


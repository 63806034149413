import { Backdrop, Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../../buttons/ButtonFull";
import { useNavigate } from "react-router";

const MollieSuccess: React.FC = () => {
    const navigate = useNavigate();
    
    useSignals();
    const open: Signal<boolean> = useSignal(true);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    return (
        <Modal
            aria-labelledby="connect-mollie-extra-modal"
            aria-describedby="Modal to connect mollie extra info"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false;}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineMedium'>
                            Autorisatie succesvol
                        </Typography>
                        <Typography variant='bodyLarge'>
                            De autorisatie in jouw Mollie account is succesvol uitgevoerd. Je kunt nu webshops openen en betalingen ontvangen.
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Wanneer je een webshop aanmaakt, creëeren wij automatisch een profiel in jouw Mollie account. Op die manier kan jij in Mollie je omzet per webshop bekijken.
                        </Typography>
                        <ButtonFull width='100%' text='Webshop aanmaken' onClick={() => {navigate('/webshops')}}/>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default MollieSuccess;
import { Alert, Box, Button, IconButton, Snackbar, ThemeProvider, Typography } from "@mui/material";

import {QrScanner} from '@yudiel/react-qr-scanner';
import { useMediaQuery } from 'react-responsive'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { apiClient } from "../../functions/Login";
import { ApiError, DriveThruOrderFactIn, DriveThruOrderFactStatus } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruScanner() {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');
    const [failed, setFailed] = useState(false);
    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const shop_id = String(useParams().shop_id)
    const event_location_id = String(useParams().location_id);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleQRScan = (code: string) => {
        apiClient().driveThru.getOrderStatusDriveThruOrderStatusOrderIdGet(code).then((r) => {
            if (r && (r.status === DriveThruOrderFactStatus.GESCAND || r.status === DriveThruOrderFactStatus.AFGESLOTEN)) {
                setSnackbarText('Deze QR-code is al doorgestuurd/afgehaald, bied een andere QR-code aan.'); openSnackbar()
            }
            else if (r && (r.status === DriveThruOrderFactStatus.BETAALD || r.status === DriveThruOrderFactStatus.ORDERBEVESTIGING)) {
                navigate(`/drive-thru/doorsturen/${shop_id}/${event_location_id}/${code}`)
            }
            
        }).catch(() => {
            setSnackbarText('QR-code niet herkend, probeer het opnieuw of bied een andere QR-code aan.'); openSnackbar(); setFailed(true);
        });
    };

    return (
        <ThemeProvider theme={ThempTheme}>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackbar} severity={'error'} sx={{ width: '100%' }}>
                   {snackBarText}
                </Alert>
            </Snackbar>
            {isMobileorTablet ?  
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '30px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}>
                </Box>
                {/* scanner overview */}
                <Box sx={{display: 'flex', flexDirection: 'column', backgroundColor: 'secondary.main', height: '100%'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', my: '32px'}}>
                        <IconButton onClick={() => {navigate(`/drive-thru/opties/${shop_id}/${event_location_id}`)}} sx={{color: '#000'}}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant='headlineLarge' sx={{ml: '4px', my: '32px'}}>
                            QR-code scannen
                        </Typography>
                    </Box>
                    <QrScanner
                        scanDelay={2000}
                        containerStyle={{backgroundColor: 'none', width: '80%', height: '300px', margin: '0px auto', padding: '0px', borderRadius: '16px'}}
                        videoStyle={{width: '100%', height: '100%'}}
                        onDecode={(result) => handleQRScan(result)}
                        onError={() => {setSnackbarText('QR-code niet gevonden, probeer het opnieuw.'); openSnackbar()}}
                    />
                    {
                        failed ? 
                        <Button variant='contained' onClick={() => {navigate(`/drive-thru/opzoeken/${shop_id}/${event_location_id}`)}} sx={{width: '50%', mx: 'auto', height: '32px', mt: '32px', borderRadius: '16px'}}>
                            <Typography variant="labelLarge">
                                Order opzoeken
                            </Typography>
                        </Button>
                        : <></>
                    }
                    
                </Box>
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}



import { Backdrop, Box, CircularProgress, Fade, IconButton, Modal, TextField, Typography } from "@mui/material";
import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../buttons/ButtonFull";
import { apiClient } from "../../functions/Login";
import Split from "../layout/Split";
import ConfirmForm from "./ConfirmForm";

interface ContactFormProps {
    open: Signal<boolean>
    width: string
    mobile?: boolean
}

const ContactForm: React.FC<ContactFormProps> = ({open, width, mobile = false}) => {
    useSignals();
    const first_name: Signal<string> = useSignal('');
    const last_name: Signal<string> = useSignal('');
    const company_name: Signal<string> = useSignal('');
    const mobile_number: Signal<string> = useSignal('');
    const email: Signal<string> = useSignal('');
    const loading: Signal<boolean> = useSignal(false);
    const openConfirm: Signal<boolean> = useSignal(false);
    const error: Signal<boolean> = useSignal(false);

    const sendContact = () => {
        loading.value = true
        error.value = false;
        try {
            apiClient().authentication.formAdvancedAuthFormAdvancedPost(
                {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    company_name: company_name.value,
                    mobile_number: mobile_number.value,
                    email: email.value,
                }
            ).then(() => {
                open.value = false;
                openConfirm.value = true;
                batch(() => {
                    first_name.value = '';
                    last_name.value = '';
                    company_name.value = '';
                    mobile_number.value = '';
                    email.value = '';
                    loading.value = false;
                })
            }).catch((err) => {
                loading.value = false;
                error.value = true;
            })
        } catch(e) {

        }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    return (
        <>
        <ConfirmForm open={openConfirm} width={width}/>
        <Modal
            aria-labelledby="pre-register-modal"
            aria-describedby="Modal to connect mollie extra info"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false;}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <form id='form' onSubmit={(e) => {e.preventDefault(); sendContact()}} style={{display: 'flex', flexDirection: 'column', gap: '16px', paddingRight: mobile ? '16px' : '64px', paddingLeft: mobile ? '16px' : '64px', paddingBottom: mobile ? '16px' : '64px'}}>
                        <Typography variant='headlineMedium' sx={{textAlign: 'center'}}>
                            Kan je niet wachten om te starten met jouw drive-thru?
                        </Typography>
                        <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
                            Meld je nu aan én hoor als eerste wanneer jij jouw drive-thru omgeving kan gaan inrichten!
                        </Typography>
                        <Split 
                            left={
                                <TextField required label="Voornaam" variant="outlined" value={first_name.value} onChange={(e) => {first_name.value = e.currentTarget.value}}/>
                            }
                            right={
                                <TextField required label="Achternaam" variant="outlined" value={last_name.value} onChange={(e) => {last_name.value = e.currentTarget.value}}/>
                            }
                        />
                        <TextField required label="Bedrijfsnaam" variant="outlined" value={company_name.value} onChange={(e) => {company_name.value = e.currentTarget.value}}/>
                        <TextField type='phone' label="Mobiele nummer" variant="outlined" value={mobile_number.value} onChange={(e) => {mobile_number.value = e.currentTarget.value}} />
                        <TextField error={error.value} type='email' required label="Email" variant="outlined" value={email.value} onChange={(e) => {email.value = e.currentTarget.value; error.value = false;}} helperText={error.value ? 'Er lijkt iets mis te gaan, controleer je email en probeer het opnieuw.' : ''}/>
                        {/* <TextField label="Postcode" variant="outlined" value={zipcode.value} onChange={(e) => {zipcode.value = e.currentTarget.value}}/> */}
                        <ButtonFull disabled={loading.value} loading={loading.value} type='submit' width='100%' text='Aanmelden' onClick={() => {}}/>
                    </form>
                </Box>
            </Fade>
        </Modal>
        </>
        
    );
  };

export default ContactForm;
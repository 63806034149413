import { Alert, Box, Button, IconButton, Snackbar, ThemeProvider, Typography } from "@mui/material";

import {QrScanner} from '@yudiel/react-qr-scanner';
import { useMediaQuery } from 'react-responsive'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { apiClient } from "../../functions/Login";
import { ApiError, DriveThruOrderFactIn, DriveThruOrderFactStatus } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruQR() {
    const navigate = useNavigate();
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackBarText, setSnackbarText] = useState('');
    const [succeeded, setSucceeded] = useState(false);
    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const order_id = String(useParams().order_id);
    const shop_id = String(useParams().shop_id);
    const event_location_id = String(useParams().location_id);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };
    useEffect(() => {
        const reload = setInterval(() => {
            //all drive-thrus
            if (!document.hidden) {
                apiClient().driveThru.getOrderStatusDriveThruOrderStatusOrderIdGet(order_id).then((r) => {
                    if (r && (r.status === DriveThruOrderFactStatus.BETAALD || r.status === DriveThruOrderFactStatus.ORDERBEVESTIGING)) {
                        setSucceeded(true);
                        setTimeout(() => {
                            setSucceeded(false);
                            navigate(`/drive-thru/doorsturen/${shop_id}/${event_location_id}/${order_id}`);
                        }, 2000)
                    }
                })
            }
        }, 6000);

        return () => clearInterval(reload);
    }, [])

    return (
        <ThemeProvider theme={ThempTheme}>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackbar} severity={'error'} sx={{ width: '100%' }}>
                   {snackBarText}
                </Alert>
            </Snackbar>
            {isMobileorTablet ?  
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '30px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}>
                </Box>
                {/* scanner overview */}
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'secondary.main', height: '100%'}}>
                    {
                        succeeded ? 
                            <Typography variant="headlineLarge" sx={{mx: 'auto', my: '32px'}}>
                                Betaling geslaagd
                            </Typography>
                        :
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', my: '32px'}}>
                                    <IconButton onClick={() => {navigate(-1)}} sx={{color: '#000'}}>
                                        <ArrowBackOutlinedIcon />
                                    </IconButton>
                                    <Typography variant='headlineLarge' sx={{ml: '4px'}}>
                                        Gegevens invoeren
                                    </Typography>
                                </Box>
                                <img 
                                    style={{marginLeft: 'auto', marginRight: 'auto' ,width: 'auto', height: 'auto', maxWidth: '90%', maxHeight: 'auto'}} 
                                    src={location.state.url} 
                                    alt="QR-code niet zichtbaar? Probeer opnieuw te bestellen." 
                                />
                                <Typography variant='labelLarge' sx={{textAlign: 'center', m: '16px'}}>
                                    Als de klant de QR-code gescand heeft, wacht dan rustig af. Je wordt automatisch doorgestuurd als de betaling voltooid is. 
                                </Typography>
                            </Box>
                       </Box>
                    }
                </Box>
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}



import { Box, Checkbox, IconButton, MenuItem, Select, SvgIconTypeMap, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { batch, Signal } from "@preact/signals-react";
import { CancelablePromise } from "../../assets";
import { memo, useEffect } from "react";
import MoreOptions from "../buttons/MoreOptions";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DeleteButton from "../buttons/DeleteButton";
import CustomTableCell from "./CustomTableCell";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash"

interface CustomTableProps {
    enableSearch?: boolean
    enableSorting?: boolean
    enableExport?: boolean
    enableSelect?: boolean
    entries?: Signal<number>
    dataEndpoint: (...args: any[]) => CancelablePromise<any>,
    requestBody: {}
    excludeColumns?: string[]
    onClickLink: string
    menuItems?: {
        Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
        text: string
        onClick: any
    }[]
}

const CustomTable: React.FC<CustomTableProps> = memo(({enableSearch = true, enableSorting = true, enableExport = true, enableSelect = true, entries, dataEndpoint, requestBody, onClickLink, menuItems}) => {
    const navigate = useNavigate();
    
    useSignals();
    // Define signals
    const searchInput: Signal<string> = useSignal('');
    const ordering: Signal<string> = useSignal('asc');
    const orderColumn: Signal<string> = useSignal('');
    const selected: Signal<string[]> = useSignal([]);
    const page: Signal<number> = useSignal(1);
    const rowsPerPage: Signal<number> = useSignal(50);
    const rows: Signal<any[]> = useSignal([]);
    const total: Signal<number> = useSignal(0);

    const emptyTable = useComputed(() => {
        return rows.value.length === 0;
    })

    const columns = useComputed(() => {
        if (rows.value.length > 0) {
            return Object.keys(rows.value[0]).slice(1)
        }
        return []
    })

    // Table logic
    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.value.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected.value, id);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.value.slice(1));
        } else if (selectedIndex === selected.value.length - 1) {
        newSelected = newSelected.concat(selected.value.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.value.slice(0, selectedIndex),
            selected.value.slice(selectedIndex + 1),
        );
        }
        selected.value = newSelected;
    };

    
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
        const newSelected = rows.value.map((n, i) => n.id);
        selected.value = newSelected;
        return;
        }
        selected.value = [];
    };

    const isSelected = (id: string) => selected.value.indexOf(id) !== -1;
    
    const handleChangePage = (event: unknown, newPage: number) => {
        page.value = newPage + 1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        batch(() => {
            rowsPerPage.value = parseInt(event.target.value, 10);
            page.value = 1;
        });  
    };

    // Handle endpoint and filtering logic
    useEffect(() => {
        dataEndpoint(
            enableSearch ? searchInput.value : undefined,
            enableSorting && orderColumn.value.length > 0 ? orderColumn.value : undefined,
            enableSorting && orderColumn.value.length > 0 ? ordering.value : undefined,
            page.value,
            rowsPerPage.value,
            requestBody
        ).then((r: any) => {
            rows.value = r.items
            rowsPerPage.value = r.size
            total.value = r.total

            if (entries) entries.value = r.items.length
        })
    }, [searchInput.value, orderColumn.value, ordering.value, page.value, rowsPerPage.value, requestBody])

    return (
      <>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
            {/* Title */}
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <Box sx={{display: !(Object.values(requestBody).every(val => val === null || (val as Array<any>).length === 0)) ? 'flex' : 'none'}}>
                    <InfoOutlinedIcon />
                    <Typography variant="bodyLarge" sx={{ml: '4px'}}>
                        Let op: je hebt filters toegepast 
                    </Typography>
                </Box>
            </Box>

            {/* Filtering */}
            
            <Box sx={{display: 'flex', mb: '32px', justifyContent: 'end', alignItems: 'center', gap: '8px'}}>
                {
                    enableSearch && 
                    <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                        }}
                        id="search-bar"
                        onChange={(e) => {
                            searchInput.value = e.target.value;
                        } }
                        value={searchInput.value}
                        variant="outlined"
                        placeholder={'Zoek...'}
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3'
                            },
                            width:'350px'
                        }} 
                    />
                }
                {
                    enableSorting &&
                    <Select
                        required
                        displayEmpty
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        renderValue={() => 
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <SortByAlphaOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Sorteren   
                                </Typography> 
                            </Box>
                        }
                        disabled={emptyTable.value}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem onClick={(e) => e.stopPropagation()} value='options' key='options' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                            <Box onClick={(e) => e.stopPropagation()} sx={{display: 'flex', gap: '8px'}}>
                                <Select
                                    required
                                    displayEmpty
                                    IconComponent={ExpandMoreOutlinedIcon}
                                    value={orderColumn.value}
                                    disabled={emptyTable.value}
                                    onChange={(e) => {e.stopPropagation(); orderColumn.value = e.target.value}}
                                    sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                                >
                                    <MenuItem disabled value='' key='' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Kies kolom
                                        </Box>
                                    </MenuItem>
                                    {
                                        columns.value.map((column) =>
                                            <MenuItem value={column} key={column} sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                                    {column}
                                                </Box>
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                                <Select
                                    required
                                    displayEmpty
                                    IconComponent={ExpandMoreOutlinedIcon}
                                    value={ordering.value}
                                    disabled={emptyTable.value}
                                    onChange={(e) => {e.stopPropagation(); ordering.value = e.target.value}}
                                    sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                                >
    
                                    <MenuItem value='asc' key='asc' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Oplopend
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='desc' key='desc' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Aflopend
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </Box>
                        </MenuItem>

                    </Select>
                }
                {
                    enableExport &&
                    <Select
                        required
                        displayEmpty
                        open={false}
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        disabled={emptyTable.value}
                        onClick={(e) => {}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <FileDownloadOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                    Exporteren  
                                </Typography> 
                            </Box>
                        </MenuItem>

                    </Select>
                } 
            </Box>  
            {
                emptyTable.value ? 
                    <Typography variant='titleLarge' sx={{mx: 'auto'}}>
                        Geen data beschikbaar
                    </Typography>
                :
                <>
            <Table aria-label="Custom table" sx={{}}>
                <TableHead sx={{"& .MuiTableCell-head": {color: "rgba(0,0,0,0.6)"}}}>
                        <TableRow>
                            {
                                enableSelect &&
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        indeterminate={selected.value.length > 0 && selected.value.length < rows.value.length}
                                        checked={rows.value.length > 0 && selected.value.length === rows.value.length}
                                        onChange={handleSelectAllClick}
                                        color="secondary"
                                    />
                                </TableCell>
                            }

                            {
                                rows.value.length !== 0 && Object.keys(rows.value[0]).slice(1).map((key: string) => 
                                    <TableCell>
                                        {key.replaceAll('_', ' ')}
                                    </TableCell>
                                )
                            }
                            <TableCell width={'5%'} align='right'>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.value.map((row, i) => 
                            <TableRow 
                                hover
                                onClick={(e) => {e.preventDefault(); navigate(onClickLink + row.id)}}
                                key={row.id}
                                sx={{mb: '16px', cursor: 'pointer' }}>
                                {
                                    enableSelect &&
                                    <TableCell padding='checkbox'>
                                        <Checkbox
                                            color="secondary"
                                            checked={isSelected(row.id)}
                                            onClick={(event) => {event.stopPropagation(); handleClick(event, row.id)}}
                                        />
                                    </TableCell>
                                }
                                
                                {
                                    Object.values(row).slice(1).map((value) =>
                                        <CustomTableCell value={value as string} />    
                                    )
                                }

                                {
                                    menuItems ?
                                    <TableCell width={'5%'} align='right'>
                                        <MoreOptions menuItems={menuItems} id={row.id}/>
                                    </TableCell>
                                    :
                                    <TableCell width={'5%'} align='right'>
                                        <DeleteButton func={() => {console.log('delete')}}/>
                                    </TableCell>
                                }
                                
                            </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage='Rijen per pagina'
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={total.value}
                    rowsPerPage={rowsPerPage.value }
                    page={page.value - 1}
                    onPageChange={(e, page) => handleChangePage(e, page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Typography variant='labelLarge' sx={{position: 'absolute', bottom: '20px', display: selected.value.length > 0 ? 'flex' : 'none'}}>
                    {selected.value.length + " Geselecteerd"}
                </Typography>
                </>
            } 
            </Box>
      </>
    );
  }, (prevProps, currentProps) => isEqual(prevProps, currentProps));

export default CustomTable;
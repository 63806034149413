import { Backdrop, Box, Fade, IconButton, Link, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../../buttons/ButtonFull";

interface MollieExtraProps {
    open: Signal<boolean>
}

const MollieExtra: React.FC<MollieExtraProps> = ({open}) => {
    useSignals();

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    return (
        <Modal
            aria-labelledby="connect-mollie-extra-modal"
            aria-describedby="Modal to connect mollie extra info"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false;}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineMedium'>
                            Heb je nog geen Mollie account?
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Een Mollie account is nodig om webshops aan te maken en betalingen van jouw klanten te kunnen ontvangen. 
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Mollie is door ons geselecteerd als betalingsprovider, omdat de integratie met Mollie zorgt voor veilig betalingsverkeer. Het is niet mogelijk om via een andere weg betalingen te ontvangen.
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Via <Link href={'https://my.mollie.com/dashboard/signup'}>https://my.mollie.com/dashboard/signup</Link> kan je een account aanmaken bij Mollie voor jouw bedrijf.
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Kijk voor meer informatie over Mollie op <Link href={'https://www.mollie.com/nl'}>https://www.mollie.com/nl</Link>.
                        </Typography>
                        <ButtonFull width='100%' text='Mollie account aanmaken' onClick={() => {window.open('https://my.mollie.com/dashboard/signup', '_blank')}}/>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default MollieExtra;
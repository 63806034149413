import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import ButtonFull from "../buttons/ButtonFull";
import ButtonOutlined from "../buttons/ButtonOutlined";

interface ConfirmProps {
    open: Signal<boolean>
    func: () => void
    information_text: string
}

const Confirm: React.FC<ConfirmProps> = ({open, func, information_text}) => {
const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '32px',
        alignItems: 'center'
      };


    return (
        <Modal
            aria-labelledby="confirm-modal"
            aria-describedby="Modal to confirm action"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    
                    <Typography variant='headlineLarge'>
                        Weet je het zeker?
                    </Typography>
                    <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
                        {information_text}
                    </Typography>
                    <Box sx={{display: 'flex', width: '100%', gap: '8px'}}>
                        <ButtonFull text='Bevestigen' onClick={func} width="50%"/>
                        <ButtonOutlined text='Annuleer' onClick={() => {open.value = false}} width="50%"/>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default Confirm;
import {Routes, Route, Outlet } from "react-router-dom";
import Home from './pages/Home';
import NotFound from "./pages/NotFound";
import NavigationDrawer from "./components/NavigationDrawer";
import Staff from "./pages/Staff/Staff";
import DriveThruShop from "./pages/DriveThru/DriveThruShop";
import Webshops from "./pages/Webshops/Webshops";
import DriveThruEdit from "./pages/DriveThru/DriveThruEdit";
import DriveThruOrders from "./pages/DriveThru/DriveThruOrders";
import DriveThruScanner from "./pages/DriveThru/DriveThruScanner";
import DriveThruLocations from "./pages/DriveThru/DriveThruLocations";
import DriveThruOption from "./pages/DriveThru/DriveThruOption";
import DriveThruPayment from "./pages/DriveThru/DriveThruPayment";
import DriveThruPreview from "./pages/DriveThru/DriveThruPreview";
import DriveThruForward from "./pages/DriveThru/DriveThruForward";
import DriveThruSearch from "./pages/DriveThru/DriveThruSearch";
import DriveThruFastOrder from "./pages/DriveThru/DriveThruFastOrder";
import DriveThruQR from "./pages/DriveThru/DriveThruQR";
import Orders from "./pages/Orders/Orders";
import OrderDetails from "./pages/Orders/OrderDetails";
import Success from "./pages/Auth/Success";
import PersonalDetails from "./pages/Staff/PersonalDetails";
import FunctionDetails from "./pages/Staff/FunctionDetails";
import Customers from "./pages/Customers/Customers";
import BusinessDetails from "./pages/Customers/Business/BusinessDetailsOverview";
import ConsumerDetails from "./pages/Customers/ConsumerDetails";
import { Authenticator } from "./components/Authenticator";
import { fetchAuthSession } from "aws-amplify/auth";
import { signOut } from 'aws-amplify/auth';
import AddressEdit from "./pages/Customers/Business/AddressEdit";
import BusinessSignUp from "./pages/SignUp/Business/BusinessSignUp";
import { UserProvider } from "./components/UserContext";
import Company from "./pages/Company/Company";
import CompanyDetails from "./pages/Company/CompanyDetails";
import Landing from "./pages/Landing";

function App() {

    // is subdomain
    return (
      
      <Routes>
      {
         (window.location.hostname.split('.').length >= 3 && window.location.hostname.split('.')[0] !== 'www') ?
          <>
          {/* Protected routes with default navigation */}
            <Route Component={() => {
              try {
                fetchAuthSession().then((res) => {
                  if ((res.tokens?.accessToken.payload.exp && (res.tokens.accessToken.payload.exp * 1000 <= Date.now())) || (!res.tokens)) {
                    signOut()
                  }
                })
              } catch(e) {
                // Ignore
              }
              
            
              return (
                <Authenticator>
                  <NavigationDrawer>
                    <UserProvider>
                      <Outlet/> 
                    </UserProvider>
                  </NavigationDrawer>
                </Authenticator>
              )
            }}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/drive-thru/bewerk/:id?" element={<DriveThruEdit />} />
            <Route path="/orders/:active_tab?/:id?" element={<Orders />} />
            <Route path="/order/details/:order_number" element={<OrderDetails />} />
            <Route path="/webshops/:active_tab?/:id?" element={<Webshops />} />
            <Route path="/personeel/:active_tab?" element={<Staff />} />
            <Route path="/personeel/medewerkers/:employee_id" element={<PersonalDetails />} />
            <Route path="/personeel/functies/:group_id" element={<FunctionDetails />} />
            <Route path="/klanten/:active_tab?" element={<Customers />} />
            <Route path="/klanten/consumenten/:consumer_id" element={<ConsumerDetails />} />
            <Route path="/klanten/zakelijk/adres/:address_id?" element={<AddressEdit />} />
            <Route path="/klanten/zakelijk/:business_id/:active_tab?" element={<BusinessDetails />} />
            <Route path="/bedrijf/locaties/bewerk/:company_id?" element={<CompanyDetails />} />
            <Route path="/bedrijf/:active_tab?/:mollie_status?" element={<Company />} />
          </Route>
            
          {/* Protected routes without default navigation */}
            <Route Component={() => {
              try {
                fetchAuthSession().then((res) => {
                  if (!res.tokens === undefined) {
                    signOut()
                  }
                })
              } catch(e) {
            
              }
              
              return (
                <Authenticator>
                  <Outlet/>
                </Authenticator> 
              )
            }}>
            <Route path="/drive-thru/locaties/:shop_id" element={<DriveThruLocations />} />
            <Route path="/drive-thru/orders/:location_id" element={<DriveThruOrders />} />
            <Route path="/drive-thru/opties/:shop_id/:location_id/:desk?" element={<DriveThruOption />} />
            <Route path="/drive-thru/opzoeken/:shop_id/:location_id" element={<DriveThruSearch />} />
            <Route path="/drive-thru/bestellen/:shop_id/:location_id" element={<DriveThruFastOrder />} />
            <Route path="/drive-thru/betalen/:shop_id/:location_id/:order_id" element={<DriveThruQR />} />
            <Route path="/drive-thru/scanner/:shop_id/:location_id" element={<DriveThruScanner />} />
            <Route path="/drive-thru/doorsturen/:shop_id/:location_id/:order_id" element={<DriveThruForward />} />
            <Route path="/drive-thru/:shop_id/preview/:preview_key" element={<DriveThruPreview />} />
          </Route>

          {/* Public routes */}
          <Route path="/drive-thru/:shop_id" element={<DriveThruShop />} />
          <Route path="/drive-thru/:shop_id/:order_id?" element={<DriveThruPayment />} />

        
          <Route path="/aanmelden" element={<BusinessSignUp />} />
          <Route path="/auth/geslaagd" element={<Success />} />
          <Route path="*" element={<NotFound />} />
        </>
         :
          <>
            <Route path="/" element={<Landing />} />  
            <Route path="*" element={<NotFound />} />
          </>
      }
      </Routes>

      
  );

 
}

export default App;

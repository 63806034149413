import { Box, Typography } from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


interface CheckTextProps {
    text: any
}

const CheckText: React.FC<CheckTextProps> = ({text}) => {
    return (
        <Box sx={{display: 'flex', gap: '8px'}}>
          <CheckOutlinedIcon />
          <Typography variant='bodyLarge'>
            {text}
          </Typography>
        </Box>
    );
  };

export default CheckText; 
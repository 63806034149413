import { IconButton, Menu, MenuItem, SvgIconTypeMap, Typography } from "@mui/material";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface MoreOptionsProps {
    id?: string
    menuItems: {
        Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
        text: string
        onClick: any
    }[]
}

const MoreOptions: React.FC<MoreOptionsProps> = ({id, menuItems}) => {
    useSignals();
    // Define signals
    const anchorEl: Signal<null | HTMLElement> = useSignal(null);
    
    const open = useComputed(() => {
        return Boolean(anchorEl.value)
    })
    
    // Arrow button that navigates to previous page.
    return (
        <>
            <IconButton 
                aria-controls={open ? 'row-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => {e.stopPropagation(); anchorEl.value = e.currentTarget}}
            >
                <MoreVertOutlinedIcon sx={{color: '#000'}}/>
            </IconButton>

            {/* Menu that shows on click for more options */}
            <Menu
                id="more-options-menu"
                anchorEl={anchorEl.value}
                open={open.value}
                onClick={(e) => e.stopPropagation()}
                onClose={(e) => { anchorEl.value = null}}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {
                    menuItems.map(item => {
                        return (
                            <MenuItem onClick={() => {item.onClick(id)}}>
                                {item.Icon && <item.Icon />}
                                <Typography variant='labelLarge'>
                                   {item.text}
                                </Typography>
                            </MenuItem>
                        )
                    })
                }
                
            </Menu>
        </>
        
    );
  };

export default MoreOptions;
import { Box, Button, Grow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ShopNotFound() {

    return (
        
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#f2f4f4', justifyContent: 'center', width: '100%'}}>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}{...(true ? { timeout: 500 } : {})}>
                <Box sx={{mx: 'auto', display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="headlineLarge" sx={{mx: 'auto', mb: '16px'}}>
                        Winkel niet gevonden.
                    </Typography>
                    <Typography variant="headlineSmall" sx={{mx: 'auto'}}>
                        Excuus, de bezochte winkel bestaat niet of is niet langer actief!
                    </Typography>
                </Box>
                </Grow>
                
            </Box>

        
    )
}
import { Alert, Box, Button, Chip, IconButton, Modal, Paper, Snackbar, TextField, ThemeProvider, Typography } from "@mui/material";

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useMediaQuery } from 'react-responsive'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { apiClient } from "../../functions/Login";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ApiError, DriveThruEventLocationOut, DriveThruOrderFactIn, DriveThruOrderFactStatus, DriveThruOrderOut } from "../../assets";
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import ThempTheme from "../../TempTheme";

export default function DriveThruSearch() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale('nl')
    
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const order = String(useParams().order_id)
    const [selected, setSelected] = useState(-1);
    const [snackBarText, setSnackbarText] = useState('');
    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const shop_id = String(useParams().shop_id)
    const event_location_id = String(useParams().location_id);
    const [deskCount, setDeskCount] = useState(1);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    //Modal constants
    const [open, setOpen] = useState(false);
    const handleOpen = () => {setOpen(true);}
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
        };

    //search Constants
    const [search, setSearch] = useState<string | null>(null);
    const [orders, setOrders] = useState<DriveThruOrderOut []>([]);
    const [selectedOrder, setSelectedOrder] = useState<DriveThruOrderOut>({} as DriveThruOrderOut);

    const searchOrders = () => {
        if (search) {
            apiClient().driveThru.searchOrdersDriveThruSearchOrdersSearchInputGet(search).then((r) => {
                setOrders(r.items);
            })
        }
    }

    const [location, setLocation] = useState<DriveThruEventLocationOut>({} as DriveThruEventLocationOut);
    const [locationsNum, setLocationsNum] = useState(0);
    useEffect(() => {
        apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
            setLocation(r);
        }).catch((error) => {
            console.log(error)
        })

        apiClient().shops.getShopByIdShopsShopsShopIdGet(shop_id).then((r) => {
            setLocationsNum(r.drive_thru_event_locations!.length);
        })   
    }, [])

    return (
        <ThemeProvider theme={ThempTheme}>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackbar} severity={'error'} sx={{ width: '100%' }}>
                   {snackBarText}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Forward order"
                aria-describedby="Forward order popup"
                >
                <Box sx={style}>
                    <Box sx={{p: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant='headlineLarge' sx={{textAlign: 'center'}}>
                           Verwijzen naar balie?
                        </Typography>
                        <Typography variant='headlineSmall' sx={{ my: '32px' }}>
                            {selectedOrder.order_client_name}
                        </Typography>
                        <Typography variant='bodyLarge' >
                            {selectedOrder.order_client_email}
                        </Typography>
                        <Button onClick={() => {navigate(`/drive-thru/doorsturen/${shop_id}/${event_location_id}/${selectedOrder.id}`)}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px', my: '16px'}}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Verwijzen  
                            </Typography> 
                        </Button>
                        <Button onClick={() => {setSelectedOrder({} as DriveThruOrderOut); handleClose()}} variant="outlined" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Annuleren  
                            </Typography> 
                        </Button>
                    </Box>  
                </Box>
            </Modal>
            {isMobileorTablet ?  
            <Box sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'secondary.main'}}>
                {/* Header */}
                <Box sx={{height: '30px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}>
                </Box>
                <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', mt: '32px'}}>
                        <IconButton onClick={() => {navigate(-1)}} sx={{color: '#000'}}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant='headlineLarge' sx={{ml: '4px'}}>
                            Order opzoeken
                        </Typography>
                    </Box>
                    <Typography variant='bodyLarge' sx={{my: '16px'}}>
                        Selecteer een order om deze te verwijzen
                    </Typography>
                    <TextField
                    type="search"
                    InputProps={{
                        startAdornment: (
                        <IconButton color='inherit' sx={{ mr: 1 }}>
                            <SearchOutlinedIcon />
                        </IconButton>
                        ),
                        endAdornment: (
                            <IconButton onClick={() => {setSearch(null)}} color='inherit' sx={{ mr: 1, display: search ? 'flex' : 'none'}}>
                                <ClearOutlinedIcon />
                            </IconButton>
                          ),
                        autoComplete: 'new-password',
                    }}
                    id="search-bar"
                    value={search ? search : ''}
                    onChange={(e) => {
                        setSearch(e.currentTarget.value)
                    }}
                    onSubmit={() => {
                        searchOrders()
                    }}
                    onBlur={(e) => {
                        searchOrders()
                    } }
                    variant="outlined"
                    placeholder="Zoek naam, email of bestelnr."
                    size="small"
                    sx={{
                        color: '#E1E3E3',
                        "& .MuiOutlinedInput-root": {
                        borderRadius: "50px",
                        borderWidth: '0px',
                        backgroundColor: '#fff'
                        },
                        width:'90%'
                    }} />
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', backgroundColor: 'seconday.main', my: '16px', width: '100%', height: '100%'}}>
                        {
                            orders.map(order => 
                                <Paper onClick={() => {setSelectedOrder(order); handleOpen()}} key={order.id} variant='outlined'  sx={{borderRadius: '16px', p: '16px', display: 'flex', width: '80%', justifyContent: 'space-between', mx: 'auto'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                        <Typography variant='labelLarge' sx={{ fontWeight: 'bold', mb: '4px' }}>
                                            {order.order_client_name}
                                        </Typography>
                                        <Typography variant='bodyMedium'>
                                            Bestelnr: {order.order_number}
                                        </Typography>
                                        <Typography variant='bodyMedium'>
                                            {order.order_client_email}
                                        </Typography>
                                        {
                                            order.order_client_business ?
                                            <Typography variant='bodyMedium'>
                                               {order.order_client_business}
                                            </Typography>
                                            :
                                            <></>
                                        }
                                        <Typography variant='bodyMedium'>
                                            {   dayjs.utc(order.timeslot.timeslot_datetime_start).local().format('DD/MM/YYYY HH:mm') 
                                                + dayjs.utc(order.timeslot.timeslot_datetime_end).local().format('[ -] HH:mm ')
                                            }
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyConten: 'end'}}>
                                        {/* <Chip size="small" sx={{color: '#fff', backgroundColor: "#006E2A", width: '80px', top: '-50%', ml: 'auto', position: 'relative', mr: '8px', mt: '8px'}} label={
                                        <Typography variant="labelLarge" sx={{}}>
                                            Actief  
                                        </Typography> 
                                        }/> */}
                                        <Typography variant='bodyLarge' sx={{ml:'auto', mt: 'auto'}}>
                                            €{order.order_products?.reduce((sum, current) => sum + (current.variant_active.full_promotional_price_incl_vat_rounded! * current.amount), 0).toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Paper>
                             )
                        }
                    </Box>
                    {
                        locationsNum > 1 ?
                            <Typography variant='labelLarge' sx={{textAlign: 'center', m: '16px'}}>
                                Zit de order er niet tussen? Misschien is de order op een andere locatie geplaatst. Wissel rechtsbovenin de locatie om dit te checken.
                            </Typography>
                        : <></>
                    }
                   
                </Box>
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}



import { Alert, Box, Button, IconButton, Snackbar, ThemeProvider, Typography } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import theme from "../../Theme";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { useEffect, useState } from "react";
import { apiClient } from "../../functions/Login";
import { DriveThruEventLocationOut } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruOption() {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const shop_id = String(useParams().shop_id)
    const event_location_id = String(useParams().location_id);
    const deskParam = String(useParams().desk);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        
        setSnackbarOpen(false);
    };

    const [location, setLocation] = useState<DriveThruEventLocationOut>({} as DriveThruEventLocationOut);
    const [locationsNum, setLocationsNum] = useState(0);
    useEffect(() => {
        apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
            setLocation(r);
        }).catch((error) => {
            console.log(error)
        })

        apiClient().shops.getShopByIdShopsShopsShopIdGet(shop_id).then((r) => {
            setLocationsNum(r.drive_thru_event_locations!.length);
        })
        if(deskParam !== "undefined") {
            openSnackbar();
        }
        
    }, [])

    return (
        <ThemeProvider theme={ThempTheme}>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackbar} severity={'success'} sx={{ width: '100%' }}>
                   Bestelling doorgestuurd naar balie {deskParam}.
                </Alert>
            </Snackbar>
            {isMobileorTablet ?  
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '90px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}>
                    
                        <Box sx={{display: 'flex', flexDirection: 'column', alignSelf: 'end', ml: 'auto'}}>
                        <Typography variant='labelLarge' sx={{ my: '4px', mx:'auto', color: '#fff' }}>
                            { location.location ? location.location.company.delivery_address.street + ' ' + location.location.company.delivery_address.number + (location.location.company.delivery_address.number_suffix || '')
                            + ', ' + location.location.company.delivery_address.city : ''}
                        </Typography>
                        <Button onClick={() => {navigate(`/drive-thru/locaties/${shop_id}`)}} sx={{height: '40px', borderRadius: '20px', backgroundColor: 'white', color: 'primary.main', border: 0}}>
                            <Typography variant='labelLarge' sx={{mx: '12px', fontWeight: 'bold'}}>
                                {
                                    locationsNum > 1 ? 'Wijzig locatie' : 'Wijzig activiteit'
                                }
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column', overflowY: 'auto', justifyContent: 'center'}}>
                    <Typography variant='headlineLarge' sx={{mb: '32px', mx: 'auto'}}>
                        Selecteer een actie
                    </Typography>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', gap: '64px'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <IconButton onClick={() => {navigate(`/drive-thru/bestellen/${shop_id}/${event_location_id}`)}} sx={{height: '72px', width: '72px', backgroundColor: 'primary.main', color: '#fff'}}>
                                <ShoppingCartOutlinedIcon fontSize='large'/>
                            </IconButton>
                            <Typography variant='labelLarge' sx={{mt: '8px', mx: 'auto'}}>
                                Bestellen
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <IconButton onClick={() => {navigate(`/drive-thru/scanner/${shop_id}/${event_location_id}`)}} sx={{height: '72px', width: '72px', backgroundColor: 'primary.main', color: '#fff'}}>
                                <QrCode2OutlinedIcon fontSize='large'/>
                            </IconButton>
                            <Typography variant='labelLarge' sx={{mt: '8px', mx: 'auto'}}>
                                Scannen
                            </Typography>
                        </Box>
                    </Box>
                    <Button onClick={() => {navigate(`/drive-thru/opzoeken/${shop_id}/${event_location_id}`)}} variant="outlined" color="primary" sx={{ height: '40px', borderRadius: '20px', mt: '32px', mx: 'auto'}}>
                    <Typography variant='bodyLarge' >
                        Order opzoeken
                    </Typography>
                        </Button>
                    
                </Box>
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}



import { Box, MenuItem, Select, ThemeProvider, Typography } from "@mui/material";
import theme from "../../../Theme";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Spaced from "../../../components/layout/Spaced";
import StatsOverview from "../../../components/StatsOverview";
import Card from "../../../components/layout/Card";
import ButtonOutlined from "../../../components/buttons/ButtonOutlined";

export default function DataDetails() {

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {/* Filter selects */}
                <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <CreditCardOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Debiteur   
                                    </Typography> 
                                </Box>)
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <CreditCardOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Debiteur 
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <RoomOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Afleveradres   
                                    </Typography> 
                                </Box>
                                )
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <RoomOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Afleveradres   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <LocalAtmOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Betaling   
                                    </Typography> 
                                </Box>
                                )
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <LocalAtmOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Betaling   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <EditAttributesOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Status   
                                    </Typography> 
                                </Box>
                                )
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value={''} key={''}>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <EditAttributesOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Status   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    
                    </Select>
                    <Typography onClick={() => {}} sx={{display: 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                        Filters wissen  
                    </Typography> 
                </Box>

                <Spaced children={
                        <>
                            <StatsOverview title='Orders' value={3} Icon={ReceiptOutlinedIcon}  />
                            <StatsOverview title='Omzet' value={'84.00'} iconOnValue={true} Icon={EuroSymbolOutlinedIcon}  />
                            <StatsOverview title='Laatste order' value={'20-03-2024'} Icon={AccessTimeOutlinedIcon}  />
                        </>
                    } 
                />

                <Card title='Facturen' button={<ButtonOutlined text='Maak creditfactuur' onClick={() => {}}/>} children={
                    <></>
                    }    
                />

                <Card title='Orders' children={
                    <></>
                    }    
                />
            </Box>
        </ThemeProvider>
    )
}
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CombinedOrderStatusEnum {
    BESTELD = 'Besteld',
    ORDERBEVESTIGING = 'Orderbevestiging',
    IN_BEHANDELING = 'In behandeling',
    VOORRAADCONTROLE = 'Voorraadcontrole',
    GESCAND = 'Gescand',
    ORDERPICKING = 'Orderpicking',
    GEREED_VOOR_LEVERING = 'Gereed voor levering',
    ONDERWEG = 'Onderweg',
    GELEVERD = 'Geleverd',
    FACTURATIE = 'Facturatie',
    AFGESLOTEN = 'Afgesloten',
    GEANNULEERD = 'Geannuleerd',
}

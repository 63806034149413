import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { AuthenticatorDev } from './components/AuthenticatorDev';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const helmetContext = {};

const app = process.env.REACT_APP_ENVIRONMENT === 'dev' ? <AuthenticatorDev><App /></AuthenticatorDev> : <App />

root.render(
    <BrowserRouter>
     <HelmetProvider context={helmetContext}>
      {app}
     </HelmetProvider>
    </BrowserRouter>
);
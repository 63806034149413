import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import VerticalStepper from "./VerticalStepper";
import { Box, Checkbox, Collapse, Divider, FormControl, FormControlLabel, FormGroup, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { apiClient } from "../../../functions/Login";
import Split from "../../layout/Split";
import { AddressIn, Body_create_subsidiary_users_create_subsidiary_post, CompanyIn, LocationNaamOut, LocationSpecialDayIn, LocationWeekDayEdit, LocationWeekDayIn, SubsidiaryIn, SubsidiaryLocationIn } from "../../../assets";
import OpeningTimes from "../../OpeningTimes";
import dayjs, { Dayjs } from "dayjs";
import ButtonFull from "../../buttons/ButtonFull";
import ButtonOutlined from "../../buttons/ButtonOutlined";

interface openingTime {
    id: string
    name: string
    date: Date
    openFrom: Dayjs
    openTill: Dayjs
    active: boolean
}

interface BranchAddProps {
    open: Signal<boolean>
}

const BranchAdd: React.FC<BranchAddProps> = ({open}) => {
    useSignals();

    // category logic
    const categories: Signal<string[]> = useSignal([]);
    const selectedCategories: Signal<string[]> = useSignal([]);
    const handleCategoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedCategories.value.includes(event.target.name)) {
            selectedCategories.value = [...selectedCategories.value, event.target.name]
        } else {
            selectedCategories.value = selectedCategories.value.filter(val => val !== event.target.name)
        }
    };

    // location data logic
    const location_name: Signal<string> = useSignal('');
    const location_description: Signal<string> = useSignal('');
    const location_postal_code: Signal<string> = useSignal('');
    const location_number: Signal<string> = useSignal('');
    const location_number_suffix: Signal<string> = useSignal('');
    const location_street: Signal<string> = useSignal('');
    const location_city: Signal<string> = useSignal('');

    // contact data logic
    const email: Signal<string> = useSignal('');
    const location_phone_number: Signal<string> = useSignal('');
    const contact_person_first_name: Signal<string> = useSignal('');
    const contact_person_last_name: Signal<string> = useSignal('');
    const contact_person_preposition: Signal<string> = useSignal('');
    const contact_person_phone_number: Signal<string> = useSignal('');

    // billing logic
    const isMainDebit: Signal<boolean> = useSignal(false);
    const isNonDebit: Signal<boolean> = useSignal(false);
    const isSameAddress: Signal<boolean> = useSignal(false);
    const debits: Signal<LocationNaamOut[]> = useSignal([]);
    const selectedDebit: Signal<string> = useSignal('');
    const coc_number: Signal<string> = useSignal('');
    const debit_email: Signal<string> = useSignal('');
    const debit_postal_code: Signal<string> = useSignal('');
    const debit_number: Signal<string> = useSignal('');
    const debit_number_suffix: Signal<string> = useSignal('');
    const debit_street: Signal<string> = useSignal('');
    const debit_city: Signal<string> = useSignal('');

    // opening times logic
    const normal_opening_times: Signal<openingTime[]> = useSignal([
        {
            id: '1',
            name: 'Maandag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '2',
            name: 'Dinsdag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '3',
            name: 'Woensdag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '4',
            name: 'Donderdag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '5',
            name: 'Vrijdag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '6',
            name: 'Zaterdag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        },
        {
            id: '7',
            name: 'Zondag',
            date: new Date(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        }

    ]);
    const deviant_opening_times: Signal<openingTime[]> = useSignal([]);

    // order account logic
    const isOrdering: Signal<boolean> = useSignal(false);
    const ordering_email: Signal<string> = useSignal('');

    const reset = () => {
        batch(() => {
            selectedCategories.value = [];
            location_name.value = '';
            location_description.value = '';
            location_postal_code.value = '';
            location_number.value = '';
            location_number_suffix.value = '';
            location_street.value = '';
            location_city.value = '';

            email.value = '';
            location_phone_number.value = '';
            contact_person_first_name.value = '';
            contact_person_last_name.value = '';
            contact_person_preposition.value = '';
            contact_person_phone_number.value = '';

            isMainDebit.value = false;
            isNonDebit.value = false;
            isSameAddress.value = false;
            debits.value = [];
            selectedDebit.value = '';
            coc_number.value = '';
            debit_email.value = '';
            debit_postal_code.value = '';
            debit_number.value = '';
            debit_number_suffix.value = '';
            debit_street.value = '';
            debit_city.value = '';

            deviant_opening_times.value = [];
            isOrdering.value = false;
            ordering_email.value = ''
        })
    }

    useEffect(() => {
        apiClient().userManagement.getAllLocationCategoriesUsersLocationCategoriesGet().then((r) => {
            categories.value = r.items.map(item => item.name);
        })
        apiClient().userManagement.getDebiteurenUsersDebiteurenGet().then((r) => {
            debits.value = r.items
        })
    }, [])

    const createSubsidiary = (activeStep: Signal<number>) => {
        const normal: LocationWeekDayIn[] = normal_opening_times.value.map((n) => ({
            weekday: n.name,
            location_closed: !n.active,
            starttime: n.openFrom.format('HH:mm'),
            endtime: n.openTill.format('HH:mm')
        } as LocationWeekDayIn)) 

        const deviant: LocationSpecialDayIn[] = deviant_opening_times.value.map((n) => ({
            weekday: n.name,
            day: n.openFrom.date(),
            month: n.openFrom.month() + 1,
            location_closed: !n.active,
            starttime: n.openFrom.format('HH:mm'),
            endtime: n.openTill.format('HH:mm')
        } as LocationSpecialDayIn)) 
        const body = {
            new_subsidiary: {
                location: {
                    name: location_name.value,
                    description: location_description.value,
                    is_distribution: selectedCategories.value.includes('Distributie'),
                    is_delivery: selectedCategories.value.includes('Afleveradres (winkelfiliaal)'),
                    is_production: selectedCategories.value.includes('Productie'),
                    is_temporary: selectedCategories.value.includes('Tijdelijke verkooplocatie (bijv. Drive-Thru)')
                } as SubsidiaryLocationIn,
                subcompany_company: {
                    name: location_name.value,
                    email: email.value,
                    phone_number: location_phone_number.value,
                    contact_first_name: contact_person_first_name.value,
                    contact_preposition_name: contact_person_preposition.value,
                    contact_last_name: contact_person_last_name.value,
                    contact_phone_number: contact_person_phone_number.value,
                    ...(isMainDebit && {
                        coc_number: coc_number.value,
                        invoice_email: debit_email.value
                    }),
                    ...((selectedDebit.value.length > 0 && !isMainDebit.value && !isNonDebit.value) && {
                        parent_id: selectedDebit.value
                    })
                } as CompanyIn,
                delivery_address: {
                    street: location_street.value,
                    number: +location_number.value,
                    number_suffix: location_number_suffix.value,
                    postal_code: location_postal_code.value,
                    city: location_city.value
                } as AddressIn,
                invoice_address: isSameAddress ? {
                    street: location_street.value,
                    number: +location_number.value,
                    number_suffix: location_number_suffix.value,
                    postal_code: location_postal_code.value,
                    city: location_city.value
                } as AddressIn 
                : {
                    street: debit_street.value,
                    number: +debit_number.value,
                    number_suffix: debit_number_suffix.value,
                    postal_code: debit_postal_code.value,
                    city: debit_city.value
                } as AddressIn
            } as SubsidiaryIn,
            ...(isOrdering.value && {
                webshop_user_emails: [ordering_email.value]
            }),
           location_week_days: normal,
           location_special_days: deviant,
        } as Body_create_subsidiary_users_create_subsidiary_post

        apiClient().userManagement.createSubsidiaryUsersCreateSubsidiaryPost(body).then((r) => {
            activeStep.value += 1;
        }).catch((err) => {
            console.log(err)
        })
    }

    const finalContent = <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', height: '100%'}}>
        <Typography variant='bodyLarge'>
            De locatie is toegevoegd{isOrdering.value && <Typography variant='bodyLarge'> en er is een uitnodiging verstuurd naar <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{ordering_email.value}</Typography></Typography>}.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', mt: 'auto'}}>
            <ButtonFull text='Sluiten' onClick={() => {open.value = false}} width='100%'/>
            <ButtonOutlined text='Sluiten en nog een locatie toevoegen' onClick={() => {}} width='100%'/>
        </Box>
    </Box>

    const steps = [
        {
            label: 'Categorie',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge' sx={{}}>
                    Wat voor locatie ga je toevoegen?
                </Typography>
                <FormControl required error={selectedCategories.value.length < 1}>
                    <FormGroup>
                        {
                            categories.value.map((category) =>
                                <FormControlLabel
                                    key={selectedCategories.value.length + category}
                                    control={
                                    <Checkbox checked={selectedCategories.value.indexOf(category) > -1} onChange={handleCategoriesChange} name={category} />
                                    }
                                    label={category}
                                />
                            )
                        }
                    </FormGroup>
                </FormControl>
                
            </Box>
        },
        {
            label: 'Afleveradres',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>
                    Vul de locatiegegevens in.
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                        Naam locatie
                    </Typography>
                    <TextField required label="Naam locatie" variant="outlined" value={location_name.value} onChange={(e) => {location_name.value = e.currentTarget.value}}/>
                    <TextField label="Beschrijving" variant="outlined" value={location_description.value} onChange={(e) => {location_description.value = e.currentTarget.value}}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                        Adres locatie
                    </Typography>
                    <TextField required label="Postcode" variant="outlined" value={location_postal_code.value} onChange={(e) => {location_postal_code.value = e.currentTarget.value}}/>
                    <Split 
                        left={
                            <TextField required label="Huisnummer" variant="outlined" value={location_number.value} onChange={(e) => {if (e.currentTarget.value.match('^[0-9]*$')) location_number.value = e.currentTarget.value}}/> 
                        }
                        right={
                            <TextField label="Toevoeging" variant="outlined" value={location_number_suffix.value} onChange={(e) => {location_number_suffix.value = e.currentTarget.value}}/>
                        }
                    />
                    <TextField required label="Straat" variant="outlined" value={location_street.value} onChange={(e) => {location_street.value = e.currentTarget.value}}/>
                    <TextField required label="Plaats" variant="outlined" value={location_city.value} onChange={(e) => {location_city.value = e.currentTarget.value}}/>
                </Box>
            </Box>
        },
        {
            label: 'Contactgegevens',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>
                    Vul de Contactgegevens van de locatie in.
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                        Contactgegegevens locatie
                    </Typography>
                    <TextField type='email' label="Email" variant="outlined" value={email.value} onChange={(e) => {email.value = e.currentTarget.value}}/>
                    <TextField label="Telefoonnummer locatie" variant="outlined" value={location_phone_number.value} onChange={(e) => {location_phone_number.value = e.currentTarget.value}}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                        Primaire contactpersoon
                    </Typography>
                    <TextField label="Voornaam" variant="outlined" value={contact_person_first_name.value} onChange={(e) => {contact_person_first_name.value = e.currentTarget.value}}/>
                    <Split 
                        left={
                            <TextField label="Tussenvoegsel(s)" variant="outlined" value={contact_person_preposition.value} onChange={(e) => {contact_person_preposition.value = e.currentTarget.value}}/>
                        } 
                        right={
                            <TextField label="Achternaam" variant="outlined" value={contact_person_last_name.value} onChange={(e) => {contact_person_last_name.value = e.currentTarget.value}}/>
                        }
                    />
                    <TextField label="Telefoonnummer contactpersoon" variant="outlined" value={contact_person_phone_number.value} onChange={(e) => {contact_person_phone_number.value = e.currentTarget.value}}/>
                </Box>
            </Box>
        },
        {
            label: 'Facturatie',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>
                    Vul de debiteurgegevens in.
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                        Debiteur
                    </Typography>
                    <Select
                        required
                        displayEmpty
                        IconComponent={ExpandMoreOutlinedIcon}
                        value={(isMainDebit.value || isNonDebit.value) ? '' : selectedDebit.value}
                        disabled={(isMainDebit.value || isNonDebit.value)}
                        onChange={(e) => {selectedDebit.value = e.target.value}}
                        sx={{minWidth: '150px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Typography variant='titleMedium'>
                                Kies een hoofddebiteur
                            </Typography>
                        </MenuItem>
                        {
                            debits.value.map(debit =>
                                <MenuItem value={debit.id} key={debit.id} >
                                    <Typography variant='titleMedium'>
                                        {debit.name}
                                    </Typography>
                                </MenuItem>
                            )
                        }
                    </Select>
                    <Divider flexItem>OF</Divider>
                    <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                        <Checkbox checked={isMainDebit.value} onChange={(e) => {isMainDebit.value = e.currentTarget.checked; isNonDebit.value = false}}/>
                        <Typography variant='bodyLarge'>
                            Deze locatie is de hoofddebiteur
                        </Typography>
                    </Box>
                    <Collapse in={isMainDebit.value} >
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <TextField required={isMainDebit.value} label="KVK-nummer" variant="outlined" value={coc_number.value} onChange={(e) => {coc_number.value = e.currentTarget.value}}/>
                            <TextField required={isMainDebit.value} label="Facturatie mailadres" variant="outlined" value={debit_email.value} onChange={(e) => {debit_email.value = e.currentTarget.value}}/>
                            <Typography variant='titleMedium' sx={{fontWeight: '700', mt: '8px'}}>
                                Factuuradres locatie
                            </Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <Checkbox checked={isSameAddress.value} onChange={(e) => {isSameAddress.value = e.currentTarget.checked}}/>
                                <Typography variant='bodyLarge'>
                                    Zelfde als afleveradres
                                </Typography>
                            </Box>
                            <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Postcode" variant="outlined" value={isSameAddress.value ? location_postal_code : debit_postal_code.value} onChange={(e) => {debit_postal_code.value = e.currentTarget.value}}/>
                            <Split 
                                left={
                                    <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Huisnummer" variant="outlined" value={isSameAddress.value ? location_number : debit_number.value} onChange={(e) => {debit_number.value = e.currentTarget.value}}/> 
                                }
                                right={
                                    <TextField disabled={isSameAddress.value} label="Toevoeging" variant="outlined" value={isSameAddress.value ? location_number_suffix : debit_number_suffix.value} onChange={(e) => {debit_number_suffix.value = e.currentTarget.value}}/>
                                }
                            />
                            <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Straat" variant="outlined" value={isSameAddress.value ? location_street : debit_street.value} onChange={(e) => {debit_street.value = e.currentTarget.value}}/>
                            <TextField disabled={isSameAddress.value} required={!isSameAddress.value && isMainDebit.value} label="Plaats" variant="outlined" value={isSameAddress.value ? location_city : debit_city.value} onChange={(e) => {debit_city.value = e.currentTarget.value}}/>
                        </Box>
                    </Collapse>
                    <Divider flexItem>OF</Divider>
                    <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                        <Checkbox checked={isNonDebit.value} onChange={(e) => {isNonDebit.value = e.currentTarget.checked; isMainDebit.value = false}}/>
                        <Typography variant='bodyLarge'>
                            Deze locatie wordt niet gefactureerd
                        </Typography>
                    </Box>
                </Box>
            </Box>
        },
        {
            label: 'Openingstijden',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>
                    Vul de openingstijden van de locatie in. Deselecteer de dagen wanneer je gesloten bent.
                </Typography>
                <OpeningTimes 
                    normal={normal_opening_times}
                    deviant={deviant_opening_times}
                />
            </Box>
        },
        // {
        //     label: 'Bestelaccount',
        //     content: 
        //     <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        //         <Typography variant='bodyLarge'>
        //             Geef eventueel het emailadres voor de zakelijke webshop op, waarmee de locatie kan bestellen.
        //         </Typography>
        //         <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
        //             <Checkbox checked={isOrdering.value} onChange={(e) => {isOrdering.value = e.currentTarget.checked}}/>
        //             <Typography variant='bodyLarge'>
        //                Deze locatie gaat online bestellen
        //             </Typography>
        //         </Box>
        //         <Collapse in={isOrdering.value}>
        //             <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        //                 <TextField type='email' required={isOrdering.value} label="Email voor bestelaccount" variant="outlined" value={ordering_email.value} onChange={(e) => {ordering_email.value = e.currentTarget.value}}/>
        //                 <Box sx={{display: 'flex', gap: '8px'}}>
        //                     <VerifiedUserOutlinedIcon />
        //                     <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        //                         <Typography variant='bodyMedium'>
        //                             Veilige login
        //                         </Typography>
        //                         <Typography variant='bodySmall'>
        //                             verbindingen zijn versleuteld met SSL
        //                         </Typography>
        //                     </Box>
        //                 </Box>     
        //                 <Typography variant='bodyLarge' sx={{fontStyle: 'italic'}}>
        //                     Bij het opslaan worden de inloggegevens voor het bestelaccount automatisch gedeelt met het ingevoerde emailadres.
        //                 </Typography>
        //             </Box>
        //         </Collapse>
                    
        //     </Box>
        // }
    ]
    return (
        <VerticalStepper open={open} title={'Locatie toevoegen'} steps={steps} finalContent={finalContent} func={createSubsidiary} resetFunc={reset}/>
    );
  };

export default BranchAdd;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DriveThruOrderFactStatus {
    DRY_RUN_OPEN = 'dry_run_open',
    OPEN = 'Open',
    BESTELD = 'Besteld',
    IN_AFWACHTING = 'In afwachting',
    GEVERIFIEERD = 'Geverifieerd',
    VERLOPEN = 'Verlopen',
    MISLUKT = 'Mislukt',
    BETALING_GEANNULEERD = 'Betaling Geannuleerd',
    BETAALD = 'Betaald',
    ORDERBEVESTIGING = 'Orderbevestiging',
    IN_BEHANDELING = 'In behandeling',
    GESCAND = 'Gescand',
    ORDERPICKING = 'Orderpicking',
    AFGESLOTEN = 'Afgesloten',
    GEANNULEERD = 'Geannuleerd',
    TERUGBETAALD = 'Terugbetaald',
    VOORRAADCONTROLE = 'Voorraadcontrole',
    GEREED_VOOR_LEVERING = 'Gereed voor levering',
    ONDERWEG = 'Onderweg',
    GELEVERD = 'Geleverd',
    FACTURATIE = 'Facturatie',
}

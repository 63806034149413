import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Tab, Typography } from '@mui/material';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DriveThruOverview from './DriveThruOverview';
import { ShopCategories } from '../../assets';

export default function Webshops() {
    const [tab, setTab] = useState(ShopCategories.DRIVE_THRU);

    const param_tab = String(useParams().active_tab);
    const id = String(useParams().id);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        setTab(ShopCategories.DRIVE_THRU)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/webshops/${newValue}`);
        }
    };
    
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{backgroundColor: '#f2f4f4'}}>
            {/* Header with buttons */}
            <Box sx={{margin: '16px', display: 'flex', justifyContent: 'space-between', mr: '32px'}}>
                <Typography variant="headlineLarge" sx={{}}>
                    Drive-Thru    
                </Typography> 
                <Button onClick={() => {navigate('/drive-thru/bewerk')}} sx={{backgroundColor: 'secondary.main', borderRadius: '20px', width: '250px', color: '#fff',
                    '&:hover': {
                    backgroundColor: 'secondary.light',
                    color: '#fff',
                },}}>
                    <AddOutlinedIcon sx={{mr: '4px'}}/>
                    <Typography variant="labelLarge">
                    Drive-Thru event toevoegen  
                    </Typography>
                </Button>
            </Box>
            <TabContext value={tab}>
                <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'#D18C5C'}}}
                >
                <Tab disabled icon={<EventRepeatOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Abbonnement   
                    </Typography>} value = 'TODO' 
                />
                <Tab icon={<DirectionsCarFilledOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        {ShopCategories.DRIVE_THRU}   
                    </Typography>} value = {ShopCategories.DRIVE_THRU}
                />
                <Tab disabled icon={<BusinessOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Zakelijk    
                    </Typography>} value = '3' 
                />
                <Tab disabled icon={<ShoppingCartOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Standaard   
                    </Typography>} value = '4' 
                />
                </TabList>

                <TabPanel value='1'>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh'}}>
                            <Typography variant="labelLarge">
                                Coming soon 
                            </Typography>
                        </Box>
                </TabPanel>
                <TabPanel value={ShopCategories.DRIVE_THRU}>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                            <DriveThruOverview />
                        </Box>
                </TabPanel>
                <TabPanel value='3'>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh'}}>
                            <Typography variant="labelLarge">
                                Coming soon 
                            </Typography>
                        </Box>
                </TabPanel>
                <TabPanel value='4'>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh'}}>
                            <Typography variant="labelLarge">
                                Coming soon 
                            </Typography>
                        </Box>
                </TabPanel>
            </TabContext>
            </Box>
      </ThemeProvider>
    );
  }
import { Box, Button, CircularProgress, Grow, ThemeProvider, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { apiClient } from "../../functions/Login";
import { DriveThruOrderFactStatus } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruPayment() {
    const navigate = useNavigate();
    const order_id = String(useParams().order_id);
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
    const shopURL = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))
    const [starting, setStarting] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            apiClient().driveThru.getOrderStatusDriveThruOrderStatusOrderIdGet(order_id).then((r) => {
                if (r) {
                    if (r.status === DriveThruOrderFactStatus.BETAALD || r.status === DriveThruOrderFactStatus.ORDERBEVESTIGING) {
                        setPaymentSuccess(true);
                    }
                }
            })
            setTimeout(() => {
                setStarting(false);
                setTimeout(() => {
                    if (paymentSuccess) {
                        window.location.href = shopURL
                    }
                },8000)
            }, 1000)
        }, 2000)

        
    },[])

    return (
        <ThemeProvider theme={ThempTheme}>
            { starting ?
                <Box sx={{display: "flex", justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                    <CircularProgress />
                </Box>
                
            :
                paymentSuccess ?
                    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#f2f4f4', justifyContent: 'center'}}>
                        <Grow in={true} style={{ transformOrigin: '0 0 0' }}{...(true ? { timeout: 500 } : {})}>
                            <Box sx={{mx: 'auto', display: 'flex', flexDirection: 'column', textAlign: 'center', flexBasis: 1}}>
                                <Typography variant="headlineLarge" sx={{mx: 'auto', mb: '24px'}}>
                                    Betaling succesvol
                                </Typography>
                                <Box sx={{mx: 'auto', mb: '16px', width: isMobile ? '90%' : '550px', display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant="bodyLarge" sx={{mx: 'auto', mb: '24px'}}>
                                        Je betaling was succesvol! Binnen enkele minuten ontvang je van ons een orderbevestiging in je mail. Houd hiervoor eventueel ook je spam in de gaten.
                                    </Typography>
                                    <Typography variant="bodyLarge" sx={{mx: 'auto', mb: '24px'}}>
                                        Je wordt automatisch teruggestuurd naar de website. Als dit niet gebeurt, klik dan op onderstaande knop om terug te keren.
                                    </Typography>
                                    <Button onClick={() => navigate(shopURL)} variant="contained" sx={{mx: 'auto', height: '40px', width: '100%', borderRadius: '20px'}}>
                                        <Typography variant="labelLarge" >
                                            Terugkeren naar website
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grow>
                    </Box>
                :
                    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#f2f4f4', justifyContent: 'center', width: '100%'}}>
                        <Grow in={true} style={{ transformOrigin: '0 0 0' }}{...(true ? { timeout: 500 } : {})}>
                            <Box sx={{mx: 'auto', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                                <Typography variant="headlineLarge" sx={{mx: 'auto', mb: '24px'}}>
                                    Betaling mislukt
                                </Typography>
                                <Box sx={{mx: 'auto', mb: '16px', width: isMobile ? '90%' : '550px', display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant="bodyLarge" sx={{mx: 'auto', mb: '24px'}}>
                                        Oeps! Je betaling is mislukt. Klik op onderstaande knop om terug te gaan naar je winkelmandje en het opnieuw te proberen.
                                    </Typography>
                                    <Button onClick={() => navigate(shopURL)} variant="contained" sx={{mx: 'auto', height: '40px', width: '100%', borderRadius: '20px'}}>
                                        <Typography variant="labelLarge" >
                                            Terugkeren naar winkelmandje
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grow>
                    </Box>
            }
        </ThemeProvider>
        
    )
}
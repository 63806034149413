import { Box, Checkbox, MenuItem, Select, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { batch, Signal } from "@preact/signals-react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/table/CustomTable";
import { CancelablePromise, RolesEnum } from "../../assets";
import { apiClient } from "../../functions/Login";


export default function FunctionOverview() {

    useSignals();
    // Define signals
    const status: Signal<string> = useSignal('');
    const selectedRoles: Signal<string[]> = useSignal([]);

    const navigate = useNavigate();
    
    const filtering = useComputed(() => {
        return (status.value !== '' || selectedRoles.value.length > 0)
    })

    const clearFilters = () => {
        batch(() => {
            status.value = '';
            selectedRoles.value = [];
        })
    }

    return(
        <ThemeProvider theme={theme}>
            {/* Filter selects */}
            <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={selectedRoles.value}
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <AdminPanelSettingsOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Toegangsrechten   
                                </Typography> 
                            </Box>
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {selectedRoles.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '200px', maxWidth: '200px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <AdminPanelSettingsOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Toegangsrechten   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    {
                        Object.values(RolesEnum).map((role) => 
                            <MenuItem value={role} key={role}>
                                <Checkbox checked={selectedRoles.value.indexOf(role) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {role}   
                                </Typography> 
                            </MenuItem>
                        )
                    }
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    value={status.value}
                    onChange={(e) => {status.value = e.target.value}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value={''} key={''}>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <EditAttributesOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Status   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    <MenuItem value={'Actief'} key={'Actief'}>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Actief   
                        </Typography> 
                    </MenuItem>
                    <MenuItem value={'Inactief'} key={'Inactief'}>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Inactief   
                        </Typography> 
                    </MenuItem>
                </Select>
                <Typography onClick={clearFilters} sx={{display: filtering.value ?  'flex': 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                    Filters wissen  
                </Typography> 
            </Box>

            {/* Personal Overview */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <CustomTable 
                    dataEndpoint={(...params: any[]): CancelablePromise<any> => {return apiClient().policies.getGroupsPoliciesGroupsPost(...params)}}
                    onClickLink='/personeel/functies/'
                    menuItems={[
                        {
                            Icon: EditOutlinedIcon,
                            text: 'Functie wijzigen',
                            onClick: (id: string) => {navigate('/personeel/functies/' + id)}
                        }
                    ]}
                    requestBody={
                        {
                            'status': status.value ? status.value : null,
                            'toegangsrechten': selectedRoles.value 
                        }
                    }
                />
            
            </Box>

        </ThemeProvider>
    )
}
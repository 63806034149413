/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post } from '../models/Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post';
import type { Body_get_shop_info_by_webshop_url_drive_thru_shop_info_post } from '../models/Body_get_shop_info_by_webshop_url_drive_thru_shop_info_post';
import type { Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview_post } from '../models/Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview_post';
import type { DriveThruEventDayIn } from '../models/DriveThruEventDayIn';
import type { DriveThruEventDayOut } from '../models/DriveThruEventDayOut';
import type { DriveThruEventDayOutList } from '../models/DriveThruEventDayOutList';
import type { DriveThruEventLocationEdit } from '../models/DriveThruEventLocationEdit';
import type { DriveThruEventLocationIn } from '../models/DriveThruEventLocationIn';
import type { DriveThruEventLocationOut } from '../models/DriveThruEventLocationOut';
import type { DriveThruEventLocationOutList } from '../models/DriveThruEventLocationOutList';
import type { DriveThruEventLocationVehicleEdit } from '../models/DriveThruEventLocationVehicleEdit';
import type { DriveThruEventLocationVehicleIn } from '../models/DriveThruEventLocationVehicleIn';
import type { DriveThruEventLocationVehicleOut } from '../models/DriveThruEventLocationVehicleOut';
import type { DriveThruEventLocationVehicleOutList } from '../models/DriveThruEventLocationVehicleOutList';
import type { DriveThruLocalOrderIn } from '../models/DriveThruLocalOrderIn';
import type { DriveThruOrderEdit } from '../models/DriveThruOrderEdit';
import type { DriveThruOrderFactIn } from '../models/DriveThruOrderFactIn';
import type { DriveThruOrderFactOut } from '../models/DriveThruOrderFactOut';
import type { DriveThruOrderFactOutList } from '../models/DriveThruOrderFactOutList';
import type { DriveThruOrderIn } from '../models/DriveThruOrderIn';
import type { DriveThruOrderOut } from '../models/DriveThruOrderOut';
import type { DriveThruOrderOutList } from '../models/DriveThruOrderOutList';
import type { DriveThruOrderProductEdit } from '../models/DriveThruOrderProductEdit';
import type { DriveThruOrderProductIn } from '../models/DriveThruOrderProductIn';
import type { DriveThruOrderProductOut } from '../models/DriveThruOrderProductOut';
import type { DriveThruOrderProductOutList } from '../models/DriveThruOrderProductOutList';
import type { DriveThruPublicOrderProductIn } from '../models/DriveThruPublicOrderProductIn';
import type { DriveThruTimeslotOutList } from '../models/DriveThruTimeslotOutList';
import type { PaymentOut } from '../models/PaymentOut';
import type { ShopOut } from '../models/ShopOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DriveThruService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Event Locations
     * Get all drive thru event locations.
     * @returns DriveThruEventLocationOutList Successful Response
     * @throws ApiError
     */
    public getAllEventLocationsDriveThruEventLocationsGet(): CancelablePromise<DriveThruEventLocationOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations',
        });
    }

    /**
     * Create Event Location
     * Create new drive thru event location.
     * @param requestBody
     * @returns DriveThruEventLocationOut Successful Response
     * @throws ApiError
     */
    public createEventLocationDriveThruEventLocationsPost(
        requestBody: DriveThruEventLocationIn,
    ): CancelablePromise<DriveThruEventLocationOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/event_locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Location By Id
     * Get drive thru event location by id.
     * @param eventLocationId
     * @returns DriveThruEventLocationOut Successful Response
     * @throws ApiError
     */
    public getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventLocationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Event Location
     * Update drive thru event location by id.
     * @param eventLocationId
     * @param requestBody
     * @returns DriveThruEventLocationOut Successful Response
     * @throws ApiError
     */
    public editEventLocationDriveThruEventLocationsEventLocationIdPatch(
        eventLocationId: string,
        requestBody: DriveThruEventLocationEdit,
    ): CancelablePromise<DriveThruEventLocationOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/event_locations/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event Location
     * Delete drive thru event location by id.
     * @param eventLocationId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteEventLocationDriveThruEventLocationsEventLocationIdDelete(
        eventLocationId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/drive_thru/event_locations/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Location By Id
     * Get drive thru event location by shop id.
     * @param shopId
     * @returns DriveThruEventLocationOut Successful Response
     * @throws ApiError
     */
    public getEventLocationByIdDriveThruEventLocationsShopShopIdGet(
        shopId: string,
    ): CancelablePromise<DriveThruEventLocationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/shop/{shop_id}',
            path: {
                'shop_id': shopId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Event Location
     * Restore drive thru event location by id.
     * @param eventLocationId
     * @returns DriveThruEventLocationOut Successful Response
     * @throws ApiError
     */
    public restoreEventLocationDriveThruEventLocationsEventLocationIdRestoreGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventLocationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}/restore',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Event Days By Event Location Id
     * Get all drive thru event days by event location id
     * @param eventLocationId
     * @returns DriveThruEventDayOutList Successful Response
     * @throws ApiError
     */
    public getAllEventDaysByEventLocationIdDriveThruEventLocationsEventLocationIdEventDaysGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventDayOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}/event_days',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Day By Id
     * Get drive thru event day by id.
     * @param eventDayId
     * @returns DriveThruEventDayOut Successful Response
     * @throws ApiError
     */
    public getEventDayByIdDriveThruEventDaysEventDayIdGet(
        eventDayId: string,
    ): CancelablePromise<DriveThruEventDayOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_days/{event_day_id}',
            path: {
                'event_day_id': eventDayId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Create Event Days
     * Create new drive thru event days.
     * @param requestBody
     * @returns DriveThruEventDayOutList Successful Response
     * @throws ApiError
     */
    public bulkCreateEventDaysDriveThruEventDaysBulkPost(
        requestBody: Array<DriveThruEventDayIn>,
    ): CancelablePromise<DriveThruEventDayOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/event_days/bulk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Delete Event Days By Event Location
     * Delete drive thru event days.
     * @param eventLocationId
     * @returns void
     * @throws ApiError
     */
    public bulkDeleteEventDaysByEventLocationDriveThruEventDaysBulkEventLocationIdDelete(
        eventLocationId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/drive_thru/event_days/bulk/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Timeslots
     * Get all drive thru timeslots.
     * @returns DriveThruTimeslotOutList Successful Response
     * @throws ApiError
     */
    public getAllTimeslotsDriveThruTimeslotsGet(): CancelablePromise<DriveThruTimeslotOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/timeslots',
        });
    }

    /**
     * Generate Timeslots By Event Location Id
     * Generate timeslots by event location id, vehicles need to pre-exist
     * @param eventLocationId
     * @returns DriveThruTimeslotOutList Successful Response
     * @throws ApiError
     */
    public generateTimeslotsByEventLocationIdDriveThruTimeslotsEventLocationIdGeneratePatch(
        eventLocationId: string,
    ): CancelablePromise<DriveThruTimeslotOutList> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/timeslots/{event_location_id}/generate',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Event Location Vehicles By Event Location Id
     * Get all drive thru event location vehicles for event location.
     * @param eventLocationId
     * @returns DriveThruEventLocationVehicleOutList Successful Response
     * @throws ApiError
     */
    public getAllEventLocationVehiclesByEventLocationIdDriveThruEventLocationsEventLocationIdEventLocationVehiclesGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventLocationVehicleOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}/event_location_vehicles',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Location Vehicle By Id
     * Get drive thru event location vehicle by id.
     * @param eventLocationVehicleId
     * @returns DriveThruEventLocationVehicleOut Successful Response
     * @throws ApiError
     */
    public getEventLocationVehicleByIdDriveThruEventLocationVehiclesEventLocationVehicleIdGet(
        eventLocationVehicleId: string,
    ): CancelablePromise<DriveThruEventLocationVehicleOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_location_vehicles/{event_location_vehicle_id}',
            path: {
                'event_location_vehicle_id': eventLocationVehicleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Event Location Vehicle
     * Update drive thru event location vehicle by id.
     * @param eventLocationVehicleId
     * @param requestBody
     * @returns DriveThruEventLocationVehicleOut Successful Response
     * @throws ApiError
     */
    public editEventLocationVehicleDriveThruEventLocationVehiclesEventLocationVehicleIdPatch(
        eventLocationVehicleId: string,
        requestBody: DriveThruEventLocationVehicleEdit,
    ): CancelablePromise<DriveThruEventLocationVehicleOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/event_location_vehicles/{event_location_vehicle_id}',
            path: {
                'event_location_vehicle_id': eventLocationVehicleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event Location Vehicle By Id
     * Delete drive thru event location vehicle by id.
     * @param eventLocationVehicleId
     * @returns void
     * @throws ApiError
     */
    public deleteEventLocationVehicleByIdDriveThruEventLocationVehiclesEventLocationVehicleIdDelete(
        eventLocationVehicleId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/drive_thru/event_location_vehicles/{event_location_vehicle_id}',
            path: {
                'event_location_vehicle_id': eventLocationVehicleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Location Vehicle
     * Create new drive thru event location vehicle.
     * @param requestBody
     * @returns DriveThruEventLocationVehicleOut Successful Response
     * @throws ApiError
     */
    public createEventLocationVehicleDriveThruEventLocationVehiclesPost(
        requestBody: DriveThruEventLocationVehicleIn,
    ): CancelablePromise<DriveThruEventLocationVehicleOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/event_location_vehicles',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Orders
     * Get all drive thru orders.
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getAllOrdersDriveThruOrdersGet(): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders',
        });
    }

    /**
     * Create Order
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderDriveThruOrdersPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order By Id
     * Get drive thru order by id.
     * @param orderId
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public getOrderByIdDriveThruOrdersOrderIdGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Order
     * Edit drive thru order on specific fields.
     * @param orderNumber
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public editOrderDriveThruOrdersOrderNumberPatch(
        orderNumber: string,
        requestBody: DriveThruOrderEdit,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/orders/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Public
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderPublicDriveThruCreateOrderPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/create_order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Local
     * Create new drive thru order in the scanapp.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderLocalDriveThruCreateOrderLocalPost(
        requestBody: DriveThruLocalOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/create_order_local',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Products By Order Id
     * Get drive thru order products by order id.
     * @param orderId
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public getOrderProductsByOrderIdDriveThruOrdersOrderIdOrderProductsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}/order_products',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Facts By Order Id
     * Get drive thru order facts by order id.
     * @param orderId
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getOrderFactsByOrderIdDriveThruOrdersOrderIdOrderfactsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}/orderfacts',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Order Products
     * Get all drive thru order products.
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public getAllOrderProductsDriveThruOrderProductsGet(): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_products',
        });
    }

    /**
     * Create Order Product
     * Create new drive thru order product.
     * @param requestBody
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public createOrderProductDriveThruOrderProductsPost(
        requestBody: DriveThruOrderProductIn,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/order_products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Product By Id
     * Get drive thru order product by id.
     * @param orderProductId
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public getOrderProductByIdDriveThruOrderProductsOrderProductIdGet(
        orderProductId: string,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_products/{order_product_id}',
            path: {
                'order_product_id': orderProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Order Product
     * Update drive thru order product by id.
     * @param orderProductId
     * @param requestBody
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public editOrderProductDriveThruOrderProductsOrderProductIdPatch(
        orderProductId: string,
        requestBody: DriveThruOrderProductEdit,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/order_products/{order_product_id}',
            path: {
                'order_product_id': orderProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Drive Thru Order Product
     * Delete drive thru order product by id.
     * @param orderProductId
     * @returns void
     * @throws ApiError
     */
    public deleteDriveThruOrderProductDriveThruOrderProductsOrderProductIdDelete(
        orderProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/drive_thru/order_products/{order_product_id}',
            path: {
                'order_product_id': orderProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Order Facts
     * Get all drive thru order facts.
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getAllOrderFactsDriveThruOrderFactsGet(): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_facts',
        });
    }

    /**
     * Create Order Fact
     * Create new drive thru order fact.
     * @param requestBody
     * @param dryRun
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public createOrderFactDriveThruOrderFactsPost(
        requestBody: DriveThruOrderFactIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/order_facts',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Fact By Id
     * Get drive thru order fact by id.
     * @param orderFactId
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public getOrderFactByIdDriveThruOrderFactsOrderFactIdGet(
        orderFactId: string,
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_facts/{order_fact_id}',
            path: {
                'order_fact_id': orderFactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Distribution Info By Id
     * Get distribution information of drive thru orders by distribution point
     * @param eventLocationId
     * @param distributionPoint
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getDistributionInfoByIdDriveThruEventLocationIdDistributionDistributionPointGet(
        eventLocationId: string,
        distributionPoint: number,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/{event_location_id}/distribution/{distribution_point}',
            path: {
                'event_location_id': eventLocationId,
                'distribution_point': distributionPoint,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Info By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopInfoByWebshopUrlDriveThruShopInfoPost(
        formData: Body_get_shop_info_by_webshop_url_drive_thru_shop_info_post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/shop_info',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Preview By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopPreviewByWebshopUrlDriveThruShopPreviewPost(
        formData: Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview_post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/shop_preview',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Product To Order
     * Add product to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public addProductToOrderDriveThruAddProductToOrderOrderIdPost(
        orderId: string,
        requestBody: DriveThruOrderProductIn,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/add_product_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Product To Order
     * Add producs to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public addProductToOrderDriveThruAddProductsToOrderOrderIdPost(
        orderId: string,
        requestBody: Array<DriveThruPublicOrderProductIn>,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/add_products_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payment By Order Id
     * Create payment by order id
     * @param orderId
     * @param dryRun
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public createPaymentByOrderIdDriveThruPaymentsPublicOrderIdGet(
        orderId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/payments_public/{order_id}',
            path: {
                'order_id': orderId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Order By Order Id
     * Refund order by order id
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public refundOrderByOrderIdDriveThruRefundOrderOrderIdPost(
        orderId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/refund_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Drive Thru Payment Webhook Status Update
     * Endpoint specifically for passing the payment provider a
     * webhook url for them to call on status changes.
     * This triggers the status retrieval process in our backend.
     * This endpoint also includes follow up logic for this specific module.
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public driveThruPaymentWebhookStatusUpdateDriveThruPaymentWebhookPost(
        formData: Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/payment_webhook',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Orders
     * Get count of paid orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountOrdersDriveThruCountPaidOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/count_paid_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Completed Orders
     * Get count of completed orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountCompletedOrdersDriveThruCountCompletedOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/count_completed_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Status
     * Get order status by order id
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOrderStatusDriveThruOrderStatusOrderIdGet(
        orderId: string,
    ): CancelablePromise<(DriveThruOrderFactOut | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_status/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Orders
     * Search uncompleted orders by input
     * @param searchInput
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public searchOrdersDriveThruSearchOrdersSearchInputGet(
        searchInput: string,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/search_orders/{search_input}',
            path: {
                'search_input': searchInput,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Revenue Overview
     * Get revenue overview by event location
     * @param eventLocationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRevenueOverviewDriveThruRevenueOverviewEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/revenue_overview/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

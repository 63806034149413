import { Box, Typography, } from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

interface PaymentStatusChipProps {
    status: string
}

const PaymentStatusChip: React.FC<PaymentStatusChipProps> = ({status}) => {

    
    // Arrow button that navigates to previous page.
    return (
        <>
            { status === 'Open' ? 
                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    <CircleOutlinedIcon sx={{color: '#EAAE3E'}}/>
                    <Typography variant='labelLarge'>Open</Typography>
                </Box>
                : status === 'Betaald' ?
                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    <CheckCircleOutlineOutlinedIcon sx={{color: '#00B002'}}/>
                    <Typography variant='labelLarge'>Betaald</Typography>
                </Box>
                :
                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    <CancelOutlinedIcon sx={{color: '#C00000'}}/>
                    <Typography variant='labelLarge'>Refund</Typography>
                </Box>
            }
        </>
        
    );
  };

export default PaymentStatusChip;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AdminService } from './services/AdminService';
import { AuthenticationService } from './services/AuthenticationService';
import { DefaultService } from './services/DefaultService';
import { DriveThruService } from './services/DriveThruService';
import { ItemManagementService } from './services/ItemManagementService';
import { MailingService } from './services/MailingService';
import { PaymentService } from './services/PaymentService';
import { PoliciesService } from './services/PoliciesService';
import { ShopsService } from './services/ShopsService';
import { UserManagementService } from './services/UserManagementService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly admin: AdminService;
    public readonly authentication: AuthenticationService;
    public readonly default: DefaultService;
    public readonly driveThru: DriveThruService;
    public readonly itemManagement: ItemManagementService;
    public readonly mailing: MailingService;
    public readonly payment: PaymentService;
    public readonly policies: PoliciesService;
    public readonly shops: ShopsService;
    public readonly userManagement: UserManagementService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminService(this.request);
        this.authentication = new AuthenticationService(this.request);
        this.default = new DefaultService(this.request);
        this.driveThru = new DriveThruService(this.request);
        this.itemManagement = new ItemManagementService(this.request);
        this.mailing = new MailingService(this.request);
        this.payment = new PaymentService(this.request);
        this.policies = new PoliciesService(this.request);
        this.shops = new ShopsService(this.request);
        this.userManagement = new UserManagementService(this.request);
    }
}


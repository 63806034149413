import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import CompanyInformation from './CompanyInformation';
import CompanyBranches from './CompanyBranches';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import BranchAdd from '../../components/modals/stepper/BranchAdd';

export default function Company() {
    useSignals();

    const openModal: Signal<boolean> = useSignal(false);

    const [tab, setTab] = useState<string>('gegevens');

    const param_tab = String(useParams().active_tab);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        setTab(param_tab)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/bedrijf/${newValue}`);
        }
    };
    
    return (
      <ThemeProvider theme={theme}>
        <BranchAdd open={openModal}/>
        <Box sx={{backgroundColor: '#f2f4f4'}}>
            {/* Header with buttons */}
            <Box sx={{margin: '16px', display: 'flex', justifyContent: 'space-between', mr: '32px', gap: '8px'}}>
                {
                    tab === 'gegevens' ?
                        <>
                            <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Bedrijfsgegevens & locaties  
                            </Typography> 
                        </>
                    :
                    tab === 'facturen' ?
                        <>
                             <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Bedrijfsgegevens & locaties  
                            </Typography> 
                        </>
                    :
                        <>
                            <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Bedrijfsgegevens & locaties  
                            </Typography> 
                            <ButtonFull Icon={AddOutlinedIcon} text='Locatie toevoegen' onClick={() => {openModal.value = true}}/>
                        </>
                }
            </Box>
            <TabContext value={tab}>
                <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'#D18C5C'}}}
                >
                <Tab icon={<BusinessOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Bedrijfsgegevens   
                    </Typography>} value = 'gegevens' 
                />
                {/* <Tab icon={<BadgeOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Facturen  
                    </Typography>} value = 'facturen'
                /> */}
                <Tab icon={<RoomOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Locaties   
                    </Typography>} value = 'locaties' 
                />
                </TabList>

                <TabPanel value='gegevens' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px', py: '16px', display: 'flex', gap: '16px', flexDirection: 'column'}}>
                        <CompanyInformation />
                    </Box>
                </TabPanel>
                <TabPanel value='facturen' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                       adsa
                    </Box>
                </TabPanel>
                <TabPanel value='locaties' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <CompanyBranches />
                    </Box>
                </TabPanel>
            </TabContext>
            </Box>
      </ThemeProvider>
    );
  }
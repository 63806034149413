import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';

interface CollapseRowProps {
    children: any
    expandComponent: any[]

}

const CollapseRow: React.FC<CollapseRowProps> = ({ children, expandComponent, ...OtherProps}) => {
    useSignals();
    // Define signals
    const collapsed: Signal<boolean> = useSignal(false);
  
    return (
      <>
        <TableRow {...OtherProps}>
            {children}
          <TableCell padding="checkbox">
            {
              expandComponent.length > 0 ?
              <IconButton onClick={() => collapsed.value = !collapsed.value}>
              {collapsed.value ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              : 
              <></>
            }
          </TableCell>
        </TableRow>
        {collapsed.value && (
        
            expandComponent.map(row => {
            return <TableRow key={row} sx={{'& .MuiTableCell-root': {border: 'none'}}}>
              <TableCell padding="checkbox" colSpan={2}/>
              <TableCell width={'10%'} align='left'>
                {row.variant_number}
              </TableCell>
              <TableCell width={'15%'} align='left'>
                {row.variant_name}
              </TableCell>
              <TableCell align='left'>
                {row.product_quantity} ({row.quantity})
              </TableCell>
              <TableCell width={'10%'} align='left'>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                  <EuroSymbolOutlinedIcon />
                  {row.revenue.toFixed(2)}
                </Box>
              </TableCell>
              <TableCell width={'10%'} align='left'>
                 {row.product_quantity_delivered} ({row.quantity_delivered})
              </TableCell>
              <TableCell width={'10%'} align='left'>
                 {row.product_quantity_open} ({row.quantity_open})
              </TableCell>
              <TableCell padding="checkbox"/>
            </TableRow>
            })
          
          
        )}
      </>
    );
  };

export default CollapseRow;
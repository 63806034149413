import {Backdrop, Box, ThemeProvider } from "@mui/material";
import theme from "../../../Theme";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import BackgroundImage from "../../../SignUpBackground.png"
import ThirdPage from "./ThirdPage";
import FourthPage from "./FourthPage";

export default function BusinessSignUp() {
    const steps = ['Aanmelden', 'Email verifiëren', 'Betaalrekening instellen', 'Start gratis proefperiode']
    
    useSignals();
    // Overall
    const activeStep: Signal<number> = useSignal(0);

    // First step
    const first_name: Signal<string> = useSignal('');
    const prefixes: Signal<string> = useSignal('');
    const last_name: Signal<string> = useSignal('');
    const kvk_number: Signal<string> = useSignal('');
    const business_name: Signal<string> = useSignal('');
    const phone_number: Signal<string> = useSignal('');
    const email: Signal<string> = useSignal('');
    const password: Signal<string> = useSignal('');
    const password_check: Signal<string> = useSignal('');
    const id: Signal<string> = useSignal('');

    const setStep = (e: React.FormEvent<HTMLFormElement>, step: number) => {
        e.preventDefault();
        activeStep.value = step;
        
    }
    return (
        <ThemeProvider theme={theme}>
            {/* Render first page */}
            {
                activeStep.value === 0 &&
                <FirstPage 
                    first_name={first_name}
                    prefixes={prefixes}
                    last_name={last_name}
                    kvk_number={kvk_number}
                    business_name={business_name}
                    phone_number={phone_number}
                    email={email}
                    id={id}
                    password={password}
                    password_check={password_check}
                    setStep={setStep}
                    steps={steps}
                    activeStep={activeStep}
                />
            }
            {/* Render second page */}
            {
                activeStep.value > 0 &&
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <Backdrop open sx={{ backgroundColor: '#00000060'}}>
                            { activeStep.value === 1 ?
                                <SecondPage 
                                    setStep={setStep}
                                    steps={steps}
                                    activeStep={activeStep}
                                    email={email}
                                /> 
                            :
                                activeStep.value === 2 ?
                                <ThirdPage 
                                    setStep={setStep}
                                    steps={steps}
                                    activeStep={activeStep}
                                    email={email}
                                    name={business_name}
                                    id={id}
                                /> 
                            :
                                <FourthPage 
                                setStep={setStep}
                                steps={steps}
                                activeStep={activeStep}
                                first_name={first_name}
                                prefixes={prefixes}
                                last_name={last_name}
                                email={email}
                                id={id}
                                password={password}
                            /> 
                            }
  
                    </Backdrop>
                </Box>
            }
        </ThemeProvider>
    )
}
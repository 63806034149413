import { IconButton } from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { To, useNavigate } from "react-router-dom";

interface ButtonBackProps {
    destination?: string
}

const ButtonBack: React.FC<ButtonBackProps> = ({destination}) => {
    const navigate = useNavigate();

    // Arrow button that navigates to previous page.
    return (
        <IconButton onClick={() => { destination ? navigate(destination as To) : navigate(-1)}}>
            <ArrowBackOutlinedIcon sx={{color: '#000'}}/>
        </IconButton>
    );
  };

export default ButtonBack;
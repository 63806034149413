import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box, Checkbox, Divider, Grid, IconButton, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import ButtonBack from '../../components/buttons/ButtonBack';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import ButtonFull from '../../components/buttons/ButtonFull';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import Split from '../../components/layout/Split';
import Card from '../../components/layout/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../functions/Login';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useCallback, useEffect } from 'react';
import { CancelablePromise, GroupOut, ToegangsrechtenOverzichtOut } from '../../assets';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CustomTable from '../../components/table/CustomTable';
import Confirm from '../../components/modals/Confirm';

export default function FunctionDetails() {
    const group_id = String(useParams().group_id);
    const navigate = useNavigate();

    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);

    useSignals()
    const openDeleteModal: Signal<boolean> = useSignal(false);

    const searchInput: Signal<string> = useSignal('');
    const group: Signal<GroupOut | null> = useSignal(null);
    const roles: Signal<ToegangsrechtenOverzichtOut[]> = useSignal([]);
    const ordering: Signal<"asc" | "desc" | undefined> = useSignal('asc');
    const orderColumn: Signal<'Functie' | 'Mag bekijken' | 'Mag beheren' | undefined> = useSignal(undefined);
    const userCount: Signal<number> = useSignal(0);
    const checkedRoles: Signal<string[]> = useSignal([]);

    const group_name: Signal<string> = useSignal('');
    const group_description: Signal<string> = useSignal('');

    const changeRoles = (id: string) => {
        if (checkedRoles.value.indexOf(id) > -1) {
            checkedRoles.value = checkedRoles.value.filter(x => x !== id);
        } else {
            checkedRoles.value = checkedRoles.value.concat(id)
        }
    }

    const tableEndpoint = useCallback((...params: any[]): CancelablePromise<any> => {return apiClient().policies.getUsersByGroupPoliciesGroupsGroupIdUsersPost(group_id, ...params)}, []);

    useEffect(() => {
        apiClient().policies.getGroupByIdPoliciesGroupsGroupIdGet(group_id).then((r) => {
            group.value = r;
            group_name.value = r.name;
            group_description.value = r.description;
        })
        apiClient().policies.getRolesByGroupPoliciesGroupsGroupIdRolesGet(group_id, searchInput.value, orderColumn.value, ordering.value).then((r) => {
            roles.value = r.items
            let list = []
            for (const item of r.items) {
                for (let i = 0; i < item.toegang.length; i++) {
                    if (item.toegang[i]) {
                        list.push(item.ids[i])
                    }
                }
            }
            checkedRoles.value = list
        })
    }, [searchInput.value, orderColumn.value, ordering.value])

    const save = () => {

        apiClient().policies.addRolesToGroupPoliciesGroupsGroupIdRolesPost(group_id, checkedRoles.value.filter(role => typeof(role) === 'string')).then(() => {
            navigate(-1);
        })

    }

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().policies.deleteGroupByIdPoliciesGroupsGroupIdDelete(group_id).then(() => {navigate('/personeel/functies')})}}
            information_text='Door op bevestigen te drukken wordt de functie permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {group.value?.name}    
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Functie verwijderen'/>
                <ButtonFull onClick={() => {save()}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <Split 
                left={
                  <Card title='Status' children={
                    <>
                      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                          <Typography variant='bodyLarge'>
                              Actief
                          </Typography>
                          <Switch defaultChecked />
                      </Box>
                      {/* <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                          <Typography variant='bodyLarge'>
                              Laatste aanpassing
                          </Typography>
                          <Typography variant='bodyLarge'>
                              {dayjs.utc(group.value?.).tz('Europe/Amsterdam').format('DD-MM-YYYY HH:mm')}
                          </Typography>
                      </Box> */}
                    </>
                  }
                  />
                }
                right={
                  <Card title='Functie' children={
                    <>
                      <TextField value={group_name.value} onChange={(e) => {group_name.value = e.currentTarget.value}} label="Functienaam" variant="outlined" InputLabelProps={{ shrink: true }}/>
                      <TextField value={group_description.value} onChange={(e) => {group_description.value = e.currentTarget.value}} label="Beschrijving" variant="outlined" InputLabelProps={{ shrink: true }}/>
                    </>
                  }
                  />
                }
            />
            <Card title='Toegangsrechten' children={
                <>
                  {/* Filtering */}
                  <Box sx={{display: 'flex', mb: '32px', justifyContent: 'end', alignItems: 'center', gap: '8px'}}>
                      <TextField
                          InputProps={{
                              startAdornment: (
                              <IconButton color='inherit' sx={{ mr: 1 }}>
                                  <SearchOutlinedIcon />
                              </IconButton>
                              ),
                          }}
                          id="search-bar"
                          onChange={(e) => {
                              searchInput.value = e.target.value;
                          } }
                          value={searchInput.value}
                          variant="outlined"
                          placeholder={'Zoek op onderwerp'}
                          sx={{
                              color: '#E1E3E3',
                              "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              borderWidth: '0px',
                              backgroundColor: 'E1E3E3'
                              },
                              width:'100%'
                          }} 
                      />
                      <Select
                        required
                        displayEmpty
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        renderValue={() => 
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <SortByAlphaOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Sorteren   
                                </Typography> 
                            </Box>
                        }
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem onClick={(e) => e.stopPropagation()} value='options' key='options' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                            <Box onClick={(e) => e.stopPropagation()} sx={{display: 'flex', gap: '8px'}}>
                                <Select
                                    required
                                    displayEmpty
                                    IconComponent={ExpandMoreOutlinedIcon}
                                    value={orderColumn.value}
                                    onChange={(e) => {e.stopPropagation(); orderColumn.value = e.target.value as "Functie" | "Mag bekijken" | "Mag beheren" | undefined }}
                                    sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                                >
                                    <MenuItem disabled value={undefined} key={undefined} sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Kies kolom
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Functie' key='Functie' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Functie
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Mag bekijken' key='Mag bekijken' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Mag bekijken
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Mag beheren' key='Mag beheren' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Mag beheren
                                        </Box>
                                    </MenuItem>
                                </Select>
                                <Select
                                    required
                                    displayEmpty
                                    IconComponent={ExpandMoreOutlinedIcon}
                                    value={ordering.value}
                                    onChange={(e) => {e.stopPropagation(); ordering.value = e.target.value as "asc" | "desc" | undefined}}
                                    sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                                >
    
                                    <MenuItem value='asc' key='asc' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Oplopend
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='desc' key='desc' sx={{ '&:hover': { backgroundColor: 'white' } }}>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            Aflopend
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </Box>
                        </MenuItem>

                    </Select>
                  </Box> 

                    {/* Rights overview table */}
                    <Grid container sx={{pl: '28px'}}>
                        <Grid item sm></Grid>
                        <Grid sm={1} item sx={{justifyContent: 'center', display: 'flex'}}>
                            <Typography variant='labelLarge' sx={{color: 'grey'}}>
                                Mag bekijken
                            </Typography>
                        </Grid>
                        <Grid sm={1} item sx={{justifyContent: 'center', display: 'flex'}}>
                            <Typography variant='labelLarge' sx={{color: 'grey'}}>
                                Mag beheren
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper variant='outlined' sx={{display: 'flex', flexDirection: 'column', maxHeight: '50vh', overflowY: 'auto'}}>
                      <Grid container direction={'column'}>
                        {
                            roles.value.map((role, index) => (
                                <>
                                <Grid item container key={index + '-titel'} sx={{pt: '16px', pl: '16px'}}>
                                    <Grid sm item sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                        <Typography variant='labelLarge'>
                                            {role.Recht}
                                        </Typography>
                                    </Grid>
                                    <Grid sm={1} item sx={{justifyItems: 'center', display: 'flex'}}>
                                        <Checkbox key={checkedRoles.value.length} onChange={() => {changeRoles(role.ids[role.subs.indexOf('Bekijken')]) }} checked={checkedRoles.value.indexOf(role.ids[role.subs.indexOf('Bekijken')]) > -1 } size="small" sx={{mx: 'auto'}}/>
                                    </Grid>
                                    <Grid sm={1} item sx={{justifyItems: 'center', display: 'flex'}}>
                                        <Checkbox key={checkedRoles.value.length} onChange={() => {changeRoles(role.ids[role.subs.indexOf('Beheren')]) }} checked={checkedRoles.value.indexOf(role.ids[role.subs.indexOf('Beheren')]) > -1 } size="small" sx={{mx: 'auto'}}/>
                                    </Grid>
                                </Grid>

                                {
                                  index !== roles.value.length - 1 &&
                                  <Divider sx={{mt: '12px'}}/>
                                }
                                
                                </>
                            ))
                        }
                    </Grid>
                </Paper>
                </>
              }
            />
            <Card title={`Gekoppelde medewerkers (${userCount.value})`} children={
                <>
                 <CustomTable 
                    entries={userCount}
                    enableExport={false}
                    dataEndpoint={tableEndpoint}
                    onClickLink='/personeel/medewerkers/'
                    requestBody={{

                    }}
                 />
                </>
              }
            />
        </Box>
      </ThemeProvider>
    );
  }
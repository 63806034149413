import { createTheme } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";
import { createBreakpoints } from "@mui/system";
import { nlNL } from '@mui/material/locale';
import "@fontsource/lato";
import "@fontsource/roboto";

declare module '@mui/material/styles' {
    interface Palette {
        tertiary: Palette['primary'];
        black: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
        black: PaletteOptions['primary'];
    }
}

const palette = createPalette({
    mode: 'light',
    primary: {
        light: '#705842',
        main: '#705842',
        dark: '#705842',
    },
    secondary: {
        light: '#ECE5DE',
        main: '#ECE5DE',
        dark: '#ECE5DE',
    },
    tertiary: {
        light: '#704c5e',
        main: '#704c5e',
        dark: '#704c5e',
    },
    error: {
        light: '#C00000',
        main: '#C00000',
        dark: '#FFB4A8',
    },
    black: {
        light: '#191C1D',
        main: '#191C1D',
        dark: '#191C1D',
    },
    contrastThreshold: 4.5,
  });
  
declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: false; 
        sm: true;
        md: true;
        lg: true;
        xl: false;
    }
}

export const breakpoints = createBreakpoints({
    values: {
        sm: 600,
        md: 840,
        lg: 1536,
    },
});

declare module '@mui/material/styles' {
    interface TypographyVariants {
        displayLarge: React.CSSProperties;
        displayMedium: React.CSSProperties;
        displaySmall: React.CSSProperties;
        headlineLarge: React.CSSProperties;
        headlineMedium: React.CSSProperties;
        headlineSmall: React.CSSProperties;
        titleLarge: React.CSSProperties;
        titleMedium: React.CSSProperties;
        titleSmall: React.CSSProperties;
        labelLarge: React.CSSProperties;
        labelMedium: React.CSSProperties;
        labelSmall: React.CSSProperties;
        bodyLarge: React.CSSProperties;
        bodyMedium: React.CSSProperties;
        bodySmall: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        displayLarge?: React.CSSProperties;
        displayMedium?: React.CSSProperties;
        displaySmall?: React.CSSProperties;
        headlineLarge?: React.CSSProperties;
        headlineMedium?: React.CSSProperties;
        headlineSmall?: React.CSSProperties;
        titleLarge?: React.CSSProperties;
        titleMedium?: React.CSSProperties;
        titleSmall?: React.CSSProperties;
        labelLarge?: React.CSSProperties;
        labelMedium?: React.CSSProperties;
        labelSmall?: React.CSSProperties;
        bodyLarge?: React.CSSProperties;
        bodyMedium?: React.CSSProperties;
        bodySmall?: React.CSSProperties;
    }
}
  
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        // Add the used variants in our design
        displayLarge: true;
        displayMedium: true;
        displaySmall: true;
        headlineLarge: true;
        headlineMedium: true;
        headlineSmall: true;
        titleLarge: true;
        titleMedium: true;
        titleSmall: true;
        labelLarge: true;
        labelMedium: true;
        labelSmall: true;
        bodyLarge: true;
        bodyMedium: true;
        bodySmall: true;

        //Disable defaults from MUI, since our design doesn't use them
        h1: false;
        h2: false;
        h3: false;
        h4: false;
        h5: false;
        h6: false;
        subtitle1: false;
        subtitle2: false;
        body1: false;
        body2: false;
        button: false;
        caption: false;
        overline: false;
    }
}

export const typography = createTypography(palette, {
    displayLarge: {
        fontSize: '57px',
        lineHeight: '64px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    displayMedium: {
        fontSize: '45px',
        lineHeight: '52px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    displaySmall: {
        fontSize: '36px',
        lineHeight: '44px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    headlineLarge: {
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    headlineMedium: {
        fontSize: '28px',
        lineHeight: '36px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    headlineSmall: {
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Lato',
    },
    titleLarge : {
        fontSize: '22px',
        lineHeight: '28px',
        letterSpacing: '0px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    titleMedium : {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },
    titleSmall : {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },
    labelLarge: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: 700,
        fontFamily: 'Roboto',
    },
    labelMedium: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },
    labelSmall: {
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0.5px',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },
    bodyLarge: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    bodyMedium: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    bodySmall: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    button: {
        textTransform: 'none'
    }
});

// This is the theme that matches our designs in figma, this way we can be more efficient in implementing those designs
const ThempTheme = createTheme({
    palette,
    typography,
    breakpoints,
}, nlNL);

export default ThempTheme;
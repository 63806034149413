import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../Theme';

function NotFound() {
  let navigate = useNavigate(); 
  
  const style = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    p: '64px',
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={style}>
        <Typography variant="headlineLarge" sx={{fontSize: '100px'}}>
            404    
        </Typography> 
        <Typography variant="headlineLarge" sx={{my: '32px'}}>
            Deze pagina bestaat niet    
        </Typography> 
        <Button onClick={() => {navigate('/')}} sx={{backgroundColor: '#FFA329', color: '#fff', borderRadius: '20px', m: 'auto' }}>
            <Typography variant="labelLarge" sx={{px: '12px'}}>
                Ga terug   
            </Typography> 
        </Button>
      </Box>
    </ThemeProvider>
  );
}

export default NotFound;
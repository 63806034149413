import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSignals } from "@preact/signals-react/runtime";
import { Signal, signal } from "@preact/signals-react";
import WebshopIcon from "./WebshopIcon";

interface OrderCancelProps {
    open: Signal<boolean>;
    orders: {
        orderNumber: string,
        customerName: string,
        webshop: string
    }[]
}

const OrderCancel: React.FC<OrderCancelProps> = ({ open, orders }) => {
    useSignals()

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        p: 4,
    };
    
    return (
        <Modal
            open={open.value}
            onClose={() => {open.value = false}}
            aria-labelledby="Annuleer order"
        >
            <Box sx={style}>
                <Box sx={{display: 'flex'}}>
                    <IconButton onClick={() => {open.value = false}} sx={{ml: 'auto', height: '40px'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box sx={{px: '16px', pb: '16px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    {
                        orders.length > 1 ?
                            <Typography variant='headlineLarge' sx={{textAlign: 'center'}}>
                                Wil je {orders.length} orders annuleren?
                            </Typography>
                        :
                            <Typography variant='headlineLarge' sx={{textAlign: 'center'}}>
                                Wil je de order annuleren?
                            </Typography>
                    }

                    <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
                        Er wordt automatisch een terugbetaling in gang gezet via Mollie. In de meeste gevallen ontvangt de klant het terugbetaalde bedrag binnen 2 werkdagen op de rekening.
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', maxheight: '400px', overflowY: 'auto'}}>
                        {
                            orders.map((order) => {
                                return (
                                    <Box key={order.webshop + order.orderNumber} sx={{display: 'flex', border: '1px solid #D5D8DC', borderRadius: '8px', gap: '16px', p: '16px'}}>
                                        <WebshopIcon webshop={order.webshop} />
                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>
                                            {order.orderNumber}
                                        </Typography>
                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>
                                            {order.customerName}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    
                    <Box sx={{display: 'flex', gap: '16px', width: '100%', height: '40px'}}>
                        <Button onClick={() => {open.value = false}} sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '200px',
                            '&:hover': {
                            backgroundColor: 'secondary.light',
                            color: '#fff',
                        },}}>
                            <Typography variant="labelLarge" sx={{ml: '4px'}}>
                                Ga terug
                            </Typography>
                        </Button> 
                        <Button sx={{backgroundColor: 'secondary.main', borderRadius: '20px', width: '250px', color: '#fff',
                            '&:hover': {
                            backgroundColor: 'secondary.light',
                            color: '#fff',
                        },}}>
                            <Typography variant="labelLarge">
                                Annuleren en terugbetalen
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
        
    );
};

export default OrderCancel;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ShopCategories {
    DRIVE_THRU = 'Drive-thru',
    ABONNEMENT = 'Abonnement',
    STANDAARD = 'Standaard',
    ZAKELIJK = 'Zakelijk',
    FILIAAL = 'Filiaal',
}

import { createContext, useContext, useState } from 'react';
import { apiClient } from '../functions/Login';
import { ApiError, UserOut } from '../assets';
import { useAuthenticator } from '@aws-amplify/ui-react';

type UserContextType = {
  currentUser: UserOut | null,
  setCurrentUser: React.Dispatch<React.SetStateAction<UserOut | null>>
}

const iUserContextState = {
  currentUser: null,
  setCurrentUser: () => {}
}

const UserContext = createContext<UserContextType>(iUserContextState);

interface Props {
    children?: React.ReactNode
}

export const UserProvider = ({children, ...props}: Props) => {
  const [currentUser, setCurrentUser] = useState<UserOut | null>(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  
  if (currentUser === null) {
    apiClient().authentication.meAuthMeGet().then((r) => {
      setCurrentUser(r)
    }).catch((err: ApiError) => {
      if (err.body.detail === 'Token has expired') {
        signOut()
      }
    })
  }

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

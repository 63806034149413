import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import Split from '../../components/layout/Split';
import ButtonBack from '../../components/buttons/ButtonBack';
import Card from '../../components/layout/Card';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useComputed, useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useEffect } from 'react';
import { apiClient } from '../../functions/Login';
import { AddressEdit, EmployeeEdit, EmployeeOut, GroupBasicOut, UserEdit } from '../../assets';
import Confirm from '../../components/modals/Confirm';

export default function PersonalDetails() {
    const navigate = useNavigate();
    const employee_id = String(useParams().employee_id);

    useSignals();
    // Define signals
    const openDeleteModal: Signal<boolean> = useSignal(false);

    const groups: Signal<GroupBasicOut[]> = useSignal([]);
    const employee: Signal<EmployeeOut | null> = useSignal(null);
    const selectedFunctions: Signal<string[]> = useSignal([]);
    const is_active: Signal<boolean> = useSignal(true)
    
    const first_name: Signal<string> = useSignal('');
    const preposition_name: Signal<string> = useSignal('');
    const last_name: Signal<string> = useSignal('');
    const email: Signal<string> = useSignal('');
    const phone_number: Signal<string> = useSignal('');
    const postal_code: Signal<string> = useSignal('');
    const house_number: Signal<string> = useSignal('');
    const house_number_suffix: Signal<string> = useSignal('');
    const street: Signal<string> = useSignal('');
    const city: Signal<string> = useSignal('');

    const editingAddress = useComputed(() => {
        return postal_code.value.length > 0 || house_number.value.length > 0 || house_number_suffix.value.length > 0 || street.value.length > 0 || city.value.length > 0
    })
    
    const saveEmployee = () => {
        apiClient().userManagement.updateEmployeeUsersEmployeesEmployeeIdPatch(employee_id, {
            employee: {
                phone_number: employee.value?.phone_number
            } as EmployeeEdit,
            user: {
                first_name: first_name.value,
                last_name: last_name.value,
                preposition_name: preposition_name.value,
                email: email.value,
                is_active: is_active.value
            } as UserEdit,
            ...(editingAddress.value && {
                address: {
                    postal_code: postal_code.value,
                    number: +house_number.value,
                    number_suffix: house_number_suffix.value,
                    street: street.value,
                    city: city.value
                } as AddressEdit
            })
        }).then((r) => {
            apiClient().policies.addUserToGroupsPoliciesUsersUserIdGroupsPost(r.user.id!, selectedFunctions.value)

            navigate('/personeel/medewerkers')
        })
       
    }

    useEffect(() => {
        apiClient().userManagement.getEmployeeByIdUsersEmployeesEmployeeIdGet(employee_id).then((r) => {
            employee.value = r;

            first_name.value = r.user.first_name;
            last_name.value = r.user.last_name;
            preposition_name.value = r.user.preposition_name ? r.user.preposition_name : '';
            email.value = r.user.email;
            phone_number.value = r.phone_number ? r.phone_number : '';
            postal_code.value = r.address?.postal_code ? r.address?.postal_code : '';
            house_number.value = r.address?.number ? r.address?.number.toString() : '';
            house_number_suffix.value = r.address?.number_suffix ? r.address?.number_suffix : '';
            street.value = r.address?.street ? r.address?.street : '';
            city.value = r.address?.city ? r.address?.city : '';

            selectedFunctions.value = r.user.groups ? r.user.groups.map(group => group.id) : [];
            is_active.value = r.user.is_active
        })
        apiClient().policies.getAllGroupsPoliciesAllGroupsGet().then((r) => {
            groups.value = r.items
            
        })
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().userManagement.deleteEmployeeUsersEmployeesEmployeeIdDelete(employee_id); navigate('/personeel/medewerkers')}}
            information_text='Door op bevestigen te drukken wordt de gebruiker permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {employee.value?.user.first_name} {employee.value?.user.preposition_name} {employee.value?.user.last_name}  
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Medewerker verwijderen'/>
                <ButtonFull type='submit' form='personalData' onClick={() => {}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <Split 
                left={
                    <>
                        <Card title='Status' children={
                                <>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge'>
                                            Actief
                                        </Typography>
                                        <Switch onChange={() => {is_active.value = !is_active.value}} checked={is_active.value} />
                                    </Box>
                                    {/* <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge'>
                                            Laatste keer ingelogd
                                        </Typography>
                                        <Typography variant='bodyLarge'>
                                            01-01-2023 12:00:00
                                        </Typography>
                                    </Box> */}
                                    
                                </>
                            }
                        />
                        <Card title='Inloggegevens' children={
                                <>  
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge'>
                                            Account email:
                                        </Typography>
                                        <Typography variant='bodyLarge'>
                                            {email.value}
                                        </Typography>
                                    </Box>
                                    <ButtonFull text='Verstuur nieuw wachtwoord' onClick={() => {}} width='100%'/>
                                    <Box sx={{display: 'flex', gap: '8px'}}>
                                        <VerifiedUserOutlinedIcon />
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='bodyMedium'>
                                                Veilige login
                                            </Typography>
                                            <Typography variant='bodySmall'>
                                                verbindingen zijn versleuteld met SSL
                                            </Typography>
                                        </Box>
                                    </Box>
                                    
                                    
                                </>
                            }
                        />
                        <Card title='Functie(s)' children={
                            <>  
                                <FormControl>
                                    <InputLabel id="functies-label">Functie(s)</InputLabel>
                                    <Select
                                        labelId="functies-label"
                                        multiple
                                        value={selectedFunctions.value}
                                        onChange={(e) => {selectedFunctions.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                                        input={<OutlinedInput label="Functie(s)" />}
                                        renderValue={(selected) => groups.value.filter(group => selected.includes(group.id)).map(group => group.name).join(', ')}

                                    >
                                        {groups.value.map((group) => (
                                            <MenuItem key={group.id} value={group.id}>
                                                <Checkbox checked={selectedFunctions.value.indexOf(group.id) > -1} />
                                                <ListItemText primary={group.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        }

                        />
                        {/* <Card title='Dienstverband' children={
                            <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Datum in dienst" />
                                <DatePicker label="Datum uit dienst" />
                            </LocalizationProvider>
                            </>
                        }
                        />   */}
                    </>
                }
                right={
                    <>
                        <Card title='Persoonsgegevens' children={
                            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveEmployee()}} id='personalData' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                                <Typography variant='titleMedium'>
                                    Naam
                                </Typography>
                                <TextField required value={first_name.value} onChange={(e) => {first_name.value = e.currentTarget.value}} label="Voornaam" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <TextField required value={last_name.value} onChange={(e) => {last_name.value = e.currentTarget.value}} label="Achternaam" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <TextField value={preposition_name.value} onChange={(e) => {preposition_name.value = e.currentTarget.value}} label="Tussenvoegsel(s)" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <Typography variant='titleMedium'>
                                    Contactgegevens
                                </Typography>
                                <TextField type='email' required onChange={(e) => {email.value = e.currentTarget.value}} value={email.value} label="Email" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <TextField type='phone' value={phone_number.value} onChange={(e) => {phone_number.value = e.currentTarget.value}} label="Telefoonnummer" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <Typography variant='titleMedium'>
                                    Adres
                                </Typography>
                                <TextField required={editingAddress.value} value={postal_code.value} onChange={(e) => {postal_code.value = e.currentTarget.value}} label="Postcode" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <Split 
                                    left={
                                        <TextField inputProps={{ pattern: '^[0-9]+$'}} required={editingAddress.value} value={house_number.value} onChange={(e) => {if (e.currentTarget.value.match('^[0-9]*$')) house_number.value = e.currentTarget.value}} label="Huisnummer" variant="outlined" InputLabelProps={{ shrink: true }}/> 
                                    }
                                    right={
                                        <TextField value={house_number_suffix.value} onChange={(e) => {house_number_suffix.value = e.currentTarget.value}} label="Toevoeging" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                    }
                                />
                                <TextField required={editingAddress.value} label="Straat" value={street.value} onChange={(e) => {street.value = e.currentTarget.value}} variant="outlined" InputLabelProps={{ shrink: true }}/>
                                <TextField required={editingAddress.value} label="Plaats" value={city.value} onChange={(e) => {city.value = e.currentTarget.value}} variant="outlined" InputLabelProps={{ shrink: true }}/>

                            </form>
                        }
                        />  
                    </>
                }
            />
        </Box>
      </ThemeProvider>
    );
  }
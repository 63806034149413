import { Box, MenuItem, Select, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Card from "../../components/layout/Card";

export default function ConsumerOverview() {

    return(
        <ThemeProvider theme={theme}>
            {/* Filter selects */}
            <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={[]}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <DevicesOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Webshops   
                                </Typography> 
                            </Box>)
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <DevicesOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Webshops 
                            </Typography> 
                        </Box>
                    </MenuItem>
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={[]}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <EditAttributesOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Status   
                                </Typography> 
                            </Box>
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <EditAttributesOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Status   
                            </Typography> 
                        </Box>
                    </MenuItem>
                </Select>
                <Typography onClick={() => {}} sx={{display: 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                    Filters wissen  
                </Typography> 
            </Box>

            {/* Consumer Overview */}
            
            <Card title='' children={
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Box sx={{display: 'none'}}>
                                <InfoOutlinedIcon />
                                <Typography variant="bodyLarge" sx={{ml: '4px'}}>
                                    Let op: je hebt filters toegepast 
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
            
            />
        </ThemeProvider>
    )
}
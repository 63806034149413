import { Box } from "@mui/material";
import { ReactNode } from "react";


interface SplitProps {
    left: ReactNode
    right: ReactNode
    gap?: number
    leftWidth?: number
    horizontal?: boolean
}

const Split: React.FC<SplitProps> = ({left, right, gap = 16, leftWidth = 50, horizontal = true, ...props}) => {
    // Split children in left and right side.

    return (
      <Box sx={{display: 'flex', flexDirection: horizontal ? 'row' : 'column', gap: `${gap}px`, width: '100%', height: '100%'}}>
        {/* Left side */}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: `${gap}px`, width: horizontal ? `${leftWidth}%` : '100%'}}>
            {left}
        </Box>
        {/* Right side */}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: `${gap}px`, width:  horizontal ? `${100 -leftWidth}%` : '100%'}}>
            {right}
        </Box>
      </Box>
    );
  };

export default Split;
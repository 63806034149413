import { Backdrop, Box, Checkbox, Fade, IconButton, MenuItem, Modal, Select, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { batch, Signal } from "@preact/signals-react";
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ButtonFull from "../buttons/ButtonFull";
import ButtonText from "../buttons/ButtonText";
import { AddressIn, Body_signup_employee_invite_auth_signup_employee_invite_post, EmployeeIn, GroupBasicOut, UserIn } from "../../assets";
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import Split from "../layout/Split";
import Spaced from "../layout/Spaced";
import ButtonOutlined from "../buttons/ButtonOutlined";

interface EmployeeAddProps {
    open: Signal<boolean>
}

const EmployeeAdd: React.FC<EmployeeAddProps> = ({open}) => {
    useSignals();

    const activeStep: Signal<number> = useSignal(0)

    const first_name: Signal<string> = useSignal('');
    const prefixes: Signal<string> = useSignal('');
    const last_name: Signal<string> = useSignal('');

    const groups: Signal<GroupBasicOut[]> = useSignal([]);
    const selectedFunctions: Signal<string[]> = useSignal([]);

    const email: Signal<string> = useSignal('');
    const backup_email: Signal<string> = useSignal('');
    const phone_number: Signal<string> = useSignal('');
    const backup_phone_number: Signal<string> = useSignal('');
    const postal_code: Signal<string> = useSignal('');
    const house_number: Signal<string> = useSignal('');
    const number_suffix: Signal<string> = useSignal('');
    const street: Signal<string> = useSignal('');
    const city: Signal<string> = useSignal('');

    useEffect(() => {
        apiClient().policies.getAllGroupsPoliciesAllGroupsGet().then((r) => {
            groups.value = r.items
        })
    }, [])
    
    const editingAddress = useComputed(() => {
        return postal_code.value.length > 0 || house_number.value.length > 0 || house_number.value.length > 0 || street.value.length > 0 || city.value.length > 0
    })

    const inviteUser = () => {
        const request_body = {
            user: {
                first_name: first_name.value,
                preposition_name: prefixes.value,
                last_name: last_name.value,
                email: email.value
            } as UserIn,
            employee: {
                phone_number: phone_number.value,
                emergency_phone_number: backup_phone_number.value,
                emergency_email: backup_email.value,
            } as EmployeeIn,
            address: {
                postal_code: postal_code.value,
                number: house_number.value === '' ?  undefined : +house_number.value,
                number_suffix: number_suffix.value,
                street: street.value,
                city: city.value,
            } as AddressIn,
            groups: groups.value.filter(group => selectedFunctions.value.includes(group.name)).map(group => group.id)
        } as Body_signup_employee_invite_auth_signup_employee_invite_post
        apiClient().authentication.signupEmployeeInviteAuthSignupEmployeeInvitePost(request_body).then((r) => {
            activeStep.value += 1;
        })
    }

    const reset = () => {
        batch(() => {
            activeStep.value = 0;
            first_name.value = '';
            prefixes.value = '';
            last_name.value = '';
            selectedFunctions.value = [];
        })
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    const steps = [
        'Naam',
        'Functie(s)',
        'Contactgegevens',
        'Login account'
    ]
    return (
        <Modal
            aria-labelledby="add-employee-modal"
            aria-describedby="Modal to add new employee"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false; reset()}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineLarge' sx={{mx: 'auto'}}>
                            Medewerker toevoegen
                        </Typography>
                        <Stepper activeStep={activeStep.value} alternativeLabel>
                            {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        {
                            activeStep.value === 0 ?
                                <form id='form' onSubmit={(e) => {e.preventDefault(); activeStep.value += 1}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '100%', alignItems:'center'}}>
                                        <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                            Voor wie ga je een account toevoegen?
                                        </Typography>
                                        <TextField required value={first_name.value} onChange={(e) => {first_name.value = e.currentTarget.value}} sx={{width: '400px', mx: 'auto'}} label='Voornaam' />
                                        <TextField value={prefixes.value} onChange={(e) => {prefixes.value = e.currentTarget.value}} sx={{width: '400px', mx: 'auto'}} label='Tussenvoegel' />
                                        <TextField required value={last_name.value} onChange={(e) => {last_name.value = e.currentTarget.value}} sx={{width: '400px', mx: 'auto'}} label='Achternaam' />
                                        <Box sx={{mx: 'auto', width: '400px', display: 'flex', justifyContent: 'end'}}>
                                            <ButtonFull width='150px' text='Volgende' onClick={() => {}} type='submit'/>
                                        </Box>
                                </form>
                            : activeStep.value === 1 ?
                                <form id='form' onSubmit={(e) => {e.preventDefault(); activeStep.value += 1}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '100%', alignItems:'center'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                        Welke functie(s) gaat {first_name.value} {prefixes.value} {last_name.value} invullen?
                                    </Typography>
                                    <Select
                                        IconComponent={ExpandMoreOutlinedIcon}
                                        displayEmpty
                                        required
                                        multiple
                                        value={selectedFunctions.value}
                                        MenuProps={{ style: {maxHeight: '300px'}}}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                            return (
                                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                                    <BadgeOutlinedIcon />
                                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                        Functie(s)   
                                                    </Typography> 
                                                </Box>)
                                            }
                                
                                            return selected.join(', ');
                                        }}
                                        onChange={(e) => {selectedFunctions.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                                        sx={{width: '100%', maxHeight: '50px', borderRadius: '12px'}}
                                    >
                                        <MenuItem disabled value='' key=''>
                                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                                <BadgeOutlinedIcon />
                                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                    Functie(s)
                                                </Typography> 
                                            </Box>
                                        </MenuItem>
                                        {
                                            groups.value.map((group) =>
                                                <MenuItem value={group.name} key={group.name}>
                                                    <Checkbox checked={selectedFunctions.value.indexOf(group.name) > -1} />
                                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                        {group.name} 
                                                    </Typography> 
                                                </MenuItem>
                                            ) 
                                        }
                                    </Select>
                                    <Box sx={{mx: 'auto', width: '400px', display: 'flex', justifyContent: 'end', gap: '16px'}}>
                                        <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}}/>
                                        <ButtonFull width='150px' text='Volgende' onClick={() => {}} type='submit'/>
                                    </Box>
                                </form>
                            :activeStep.value === 2 ?
                                <form id='form' onSubmit={(e) => {e.preventDefault(); activeStep.value += 1}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '80%', alignItems:'center', margin: 'auto'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                        Vul de contactgegevens en het adres van {first_name.value} {prefixes.value} {last_name.value} in.
                                    </Typography>
                                    <Typography variant='titleMedium'>
                                        Contactgegevens
                                    </Typography>
                                    <TextField type='email' value={email.value} onChange={(e) => {email.value = e.currentTarget.value}} fullWidth required label="Account email" variant="outlined" />
                                    <TextField type='email'  value={backup_email.value} onChange={(e) => {backup_email.value = e.currentTarget.value}} fullWidth label="Backup email" variant="outlined" />
                                    <TextField type='tel' value={phone_number.value} onChange={(e) => {phone_number.value = e.currentTarget.value}} fullWidth label="Telefoonnummer" variant="outlined"/>
                                    <TextField type='tel' value={backup_phone_number.value} onChange={(e) => {backup_phone_number.value = e.currentTarget.value}} fullWidth label="Noodnummer" variant="outlined"/>
                                    <Typography variant='titleMedium'>
                                        Adres
                                    </Typography>
                                    <TextField required={editingAddress.value} value={postal_code.value} onChange={(e) => {postal_code.value = e.currentTarget.value}} fullWidth label="Postcode" variant="outlined" />
                                    <Split 
                                        left={
                                            <TextField required={editingAddress.value} type='tel' value={house_number.value} onChange={(e) => {house_number.value = e.currentTarget.value}} label="Huisnummer" variant="outlined"/> 
                                        }
                                        right={
                                            <TextField required={editingAddress.value} value={number_suffix.value} onChange={(e) => {number_suffix.value = e.currentTarget.value}} label="Toevoeging" variant="outlined"/>
                                        }
                                    />
                                    <TextField required={editingAddress.value} value={street.value} onChange={(e) => {street.value = e.currentTarget.value}} fullWidth label="Straat" variant="outlined"/>
                                    <TextField required={editingAddress.value} value={city.value} onChange={(e) => {city.value = e.currentTarget.value}} fullWidth label="Plaats" variant="outlined"/>
                                    <Box sx={{mx: 'auto', width: '400px', display: 'flex', justifyContent: 'end', gap: '16px'}}>
                                        <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}}/>
                                        <ButtonFull width='150px' text='Volgende' onClick={() => {}} type='submit'/>
                                    </Box>
                                </form>
                            : activeStep.value === 3 ?
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                        Je staat op het punt om <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{first_name.value} {prefixes.value} {last_name.value}</Typography>  uit te nodigen voor jouw online omgeving.
                                    </Typography>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                       De medewerker ontvangt een email met verdere instructies op het onderstaand emailadres.
                                    </Typography>
                                    <Spaced children={
                                        <>
                                            <Typography variant='bodyLarge'>
                                                Account email:
                                            </Typography>
                                            <Typography variant='bodyLarge'>
                                                {email.value}
                                            </Typography>
                                        </>
                                    }    
                                    />
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', width: '100%'}}>
                                        <ButtonFull onClick={inviteUser} text='Medewerker uitnodigen' width='100%'/>
                                        <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}} margin='0'/>
                                    </Box>
                                   
                                </Box>
                            : 
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                        Er is een uitnodiging verstuurd naar <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{email.value}r</Typography>
                                    </Typography>
                                   
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', width: '100%'}}>
                                        <ButtonFull onClick={() => {open.value = false; reset()}} text='Sluiten' width='100%'/>
                                        <ButtonOutlined text='Nog een medewerker toevoegen' onClick={reset} width='100%'/>
                                    </Box>
                                   
                                </Box>
                        }
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default EmployeeAdd;
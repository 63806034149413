import { IconButton, } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface DeleteButtonProps {
    func: () => any
}

const DeleteButton: React.FC<DeleteButtonProps> = ({func}) => {

    
    // Arrow button that navigates to previous page.
    return (
        <>
            <IconButton 
                onClick={func}
            >
                <DeleteOutlineOutlinedIcon sx={{color: '#000'}}/>
            </IconButton>
        </>
        
    );
  };

export default DeleteButton;
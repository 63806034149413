import { Box, IconButton, Tab, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {  useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Signal } from "@preact/signals-react";
import { useSignals, useSignal } from "@preact/signals-react/runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ButtonOutlined from "../buttons/ButtonOutlined";
import { createRef, useEffect } from "react";
import { LocationOverviewOut } from "../../assets";

interface AddressOverviewProps {
    onClose: any
    location: LocationOverviewOut
}

const AddressOverview: React.FC<AddressOverviewProps> = ({ onClose, location }) => {
    const navigate = useNavigate();

    const container = createRef<HTMLDivElement>();

    useSignals();
    const tab: Signal<string> = useSignal('overzicht');
    
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        tab.value = newValue;
    };

    useEffect(() => {
        container.current?.scrollIntoView({behavior: 'smooth'});
    }, [])
 
    return (
        <Box ref={container} sx={{display: 'flex', flexDirection: 'column', height: '95%', mx: '16px', width: '400px', gap: '16px', p: '16px', borderRadius: '16px', backgroundColor: '#fff', zIndex: '10', position: 'absolute'}}>
            {/* Title */}
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant='titleLarge'>
                    {location.name}
                </Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {/* Address */}
                <Typography variant='labelLarge'>
                    {location.company.delivery_address.street} {location.company.delivery_address.number} {location.company.delivery_address.number_suffix ? location.company.delivery_address.number_suffix : ''}, {location.company.delivery_address.postal_code} {location.company.delivery_address.city}
                </Typography>
            {/* Tabs */}
                <TabContext value={tab.value}>
                    <TabList
                        onChange={handleChange}
                        TabIndicatorProps={{style: {background:'#D18C5C'}}}
                        sx={{display: 'flex', justifyContent: 'space-evenly'}}
                    >
                    <Tab sx={{width: '50%'}} label={
                        <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                            Bedrijfsgegevens   
                        </Typography>} value = 'overzicht' 
                    />
                    <Tab sx={{width: '50%'}} label={
                        <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                            Openingstijden  
                        </Typography>} value = 'tijden'
                    />
                    </TabList>

                    <TabPanel value='overzicht' sx={{p: 0, height: '100%'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', px: '16px', height: '100%'}}>
                            <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Beschrijving
                            </Typography>
                            <Typography variant='bodyMedium'>
                                {location.description ? location.description : '-'}
                            </Typography>

                            <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Adressgegevens
                            </Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <InfoOutlinedIcon />
                                <Typography variant='bodyMedium' noWrap>
                                    {
                                        [location.is_delivery ? 'Afleveradres (winkelfiliaal)' : [],
                                        location.is_distribution ? 'Distributie' : [],
                                        location.is_production ? 'Productie' : [],
                                        location.is_temporary ? 'Tijdelijke verkooplocatie (bijv. Drive-Thru)' : []].join(', ')
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <EmailOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    {location.company.email ? location.company.email : '-'}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <LocalPhoneOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    {location.company.phone_number ? location.company.phone_number : '-'}
                                </Typography>
                            </Box>

                            <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Primaire contactpersoon
                            </Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <PersonOutlineOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    {location.company.contact_full_name ? location.company.contact_full_name : '-'}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <PhoneAndroidOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    {location.company.contact_phone_number ? location.company.contact_phone_number : '-'}
                                </Typography>
                            </Box>

                            {/* <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Online bestellen
                            </Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <CreditCardOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    De Nieuwe Bakker Loosdrecht
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <EuroSymbolOutlinedIcon />
                                <Typography variant='bodyMedium'>
                                    Wekelijks gefactureerd
                                </Typography>
                            </Box> */}
                            <Box sx={{mt: 'auto', ml: 'auto'}}>
                                <ButtonOutlined Icon={EditOutlinedIcon} text='Bewerken' onClick={() => {navigate(`/bedrijf/locaties/bewerk/${location.company.id}`)}}/>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value='tijden' sx={{p: 0, height: '100%'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', px: '16px', height: '100%'}}>
                            <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Openingstijden
                            </Typography>
                                {   
                                    location.weekdays!.map((day) =>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='bodyMedium'>
                                                {day.weekday}
                                            </Typography>
                                            <Typography variant='bodyMedium'>
                                            {
                                                    day.location_closed ?
                                                    <>Gesloten</>
                                                    :
                                                    <>{day.starttime.slice(0, -3)}-{day.endtime.slice(0, -3)}</>
                                                }
                                            </Typography>
                                        </Box>
                                    )
                                }
                            <Typography variant='titleMedium' sx={{mt: '16px', fontWeight: '700'}}>
                                Afwijkende openingstijden
                            </Typography>
                            {   
                                    location.special_days!.map((day) =>
                                    
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant='bodyMedium'>
                                                {day.day.toString().padStart(2, '0')}-{day.month.toString().padStart(2, '0')}
                                            </Typography>
                                            <Typography variant='bodyMedium'>
                                                {
                                                    day.location_closed ?
                                                    <>Gesloten</>
                                                    :
                                                    <>{day.starttime.slice(0, -3)}-{day.endtime.slice(0, -3)}</>
                                                }
                                            </Typography>
                                        </Box>
                                    )
                                }

                            <Box sx={{mt: 'auto', ml: 'auto'}}>
                                <ButtonOutlined Icon={EditOutlinedIcon} text='Bewerken' onClick={() => {navigate(`/bedrijf/locaties/bewerk/${location.company.id}`)}}/>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
        </Box>
    );
  };

export default AddressOverview;
export const createSubDomainURL = (url : string, host: string) => {
    const currHost = url.split('.');
    if (currHost.length > 1 && currHost[0].indexOf('backend') === -1) {
        currHost[0] = host;
        const newURL = currHost.join('.')
        return newURL
    } else {
        const sequence = '//';
        const originalURL = url;
        const position = originalURL.indexOf(sequence);
        return originalURL.slice(0, position + sequence.length) + host + '.' + originalURL.slice(position + sequence.length);
    }
}
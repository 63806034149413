/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_get_groups_policies_groups_post } from '../models/Body_get_groups_policies_groups_post';
import type { GroupBasicOutList } from '../models/GroupBasicOutList';
import type { GroupEdit } from '../models/GroupEdit';
import type { GroupIn } from '../models/GroupIn';
import type { GroupOut } from '../models/GroupOut';
import type { Page_FunctieDetailsGebruikerOut_ } from '../models/Page_FunctieDetailsGebruikerOut_';
import type { Page_FunctieOverviewOut_ } from '../models/Page_FunctieOverviewOut_';
import type { PolicyIn } from '../models/PolicyIn';
import type { PolicyOut } from '../models/PolicyOut';
import type { PolicyOutList } from '../models/PolicyOutList';
import type { RoleIn } from '../models/RoleIn';
import type { RoleOut } from '../models/RoleOut';
import type { ToegangsrechtenOverzichtOutList } from '../models/ToegangsrechtenOverzichtOutList';
import type { UserOut } from '../models/UserOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PoliciesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Current Policies
     * @returns string Successful Response
     * @throws ApiError
     */
    public getCurrentPoliciesPoliciesPoliciesGet(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/policies',
        });
    }

    /**
     * Get All Policies
     * @returns PolicyOutList Successful Response
     * @throws ApiError
     */
    public getAllPoliciesPoliciesPoliciesAllGet(): CancelablePromise<PolicyOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/policies/all',
        });
    }

    /**
     * Create Policy
     * @param requestBody
     * @returns PolicyOut Successful Response
     * @throws ApiError
     */
    public createPolicyPoliciesPoliciesCreatePost(
        requestBody: PolicyIn,
    ): CancelablePromise<PolicyOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/policies/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Policy By Id
     * Get policy by id
     * @param policyId
     * @returns PolicyOut Successful Response
     * @throws ApiError
     */
    public getPolicyByIdPoliciesPoliciesPolicyIdGet(
        policyId: string,
    ): CancelablePromise<PolicyOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/policies/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Policy By Id
     * @param policyId
     * @returns void
     * @throws ApiError
     */
    public deletePolicyByIdPoliciesPoliciesPolicyIdDelete(
        policyId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/policies/policies/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Roles
     * @returns ToegangsrechtenOverzichtOutList Successful Response
     * @throws ApiError
     */
    public getAllRolesPoliciesRolesAllGet(): CancelablePromise<ToegangsrechtenOverzichtOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/roles/all',
        });
    }

    /**
     * Create Role
     * @param requestBody
     * @returns RoleOut Successful Response
     * @throws ApiError
     */
    public createRolePoliciesRolesCreatePost(
        requestBody: RoleIn,
    ): CancelablePromise<RoleOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/roles/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Role By Id
     * Get role by id
     * @param roleId
     * @returns RoleOut Successful Response
     * @throws ApiError
     */
    public getRoleByIdPoliciesRolesRoleIdGet(
        roleId: string,
    ): CancelablePromise<RoleOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Role By Id
     * @param roleId
     * @returns void
     * @throws ApiError
     */
    public deleteRoleByIdPoliciesRolesRoleIdDelete(
        roleId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/policies/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Role Policies
     * Get role by id
     * @param roleId
     * @returns PolicyOutList Successful Response
     * @throws ApiError
     */
    public getRolePoliciesPoliciesRolesRoleIdPoliciesGet(
        roleId: string,
    ): CancelablePromise<PolicyOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/roles/{role_id}/policies',
            path: {
                'role_id': roleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Policies To Role
     * @param roleId
     * @param requestBody
     * @returns RoleOut Successful Response
     * @throws ApiError
     */
    public addPoliciesToRolePoliciesRolesRoleIdAddPoliciesPost(
        roleId: string,
        requestBody: Array<string>,
    ): CancelablePromise<RoleOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/roles/{role_id}/add/policies',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Groups
     * Get all groups filtered
     * @param naam
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_FunctieOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getGroupsPoliciesGroupsPost(
        naam?: (string | null),
        sortingField?: 'functie' | 'status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_groups_policies_groups_post,
    ): CancelablePromise<Page_FunctieOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/groups',
            query: {
                'naam': naam,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Groups
     * @returns GroupBasicOutList Successful Response
     * @throws ApiError
     */
    public getAllGroupsPoliciesAllGroupsGet(): CancelablePromise<GroupBasicOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/all_groups',
        });
    }

    /**
     * Create Group
     * @param requestBody
     * @returns GroupOut Successful Response
     * @throws ApiError
     */
    public createGroupPoliciesCreateGroupPost(
        requestBody: GroupIn,
    ): CancelablePromise<GroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/create_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Group By Id
     * Get group by id
     * @param groupId
     * @returns GroupOut Successful Response
     * @throws ApiError
     */
    public getGroupByIdPoliciesGroupsGroupIdGet(
        groupId: string,
    ): CancelablePromise<GroupOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Group By Id
     * Edit group by id
     * @param groupId
     * @param requestBody
     * @returns GroupOut Successful Response
     * @throws ApiError
     */
    public editGroupByIdPoliciesGroupsGroupIdPatch(
        groupId: string,
        requestBody: GroupEdit,
    ): CancelablePromise<GroupOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/policies/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Group By Id
     * Delete group by id
     * @param groupId
     * @returns void
     * @throws ApiError
     */
    public deleteGroupByIdPoliciesGroupsGroupIdDelete(
        groupId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/policies/groups/{group_id}',
            path: {
                'group_id': groupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Roles By Group
     * Get roles by group id
     * @param groupId
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @returns ToegangsrechtenOverzichtOutList Successful Response
     * @throws ApiError
     */
    public getRolesByGroupPoliciesGroupsGroupIdRolesGet(
        groupId: string,
        search?: (string | null),
        sortingField?: 'Functie' | 'Mag bekijken' | 'Mag beheren',
        sortingOrder: 'asc' | 'desc' = 'asc',
    ): CancelablePromise<ToegangsrechtenOverzichtOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/groups/{group_id}/roles',
            path: {
                'group_id': groupId,
            },
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Roles To Group
     * @param groupId
     * @param requestBody
     * @returns GroupOut Successful Response
     * @throws ApiError
     */
    public addRolesToGroupPoliciesGroupsGroupIdRolesPost(
        groupId: string,
        requestBody: Array<string>,
    ): CancelablePromise<GroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/groups/{group_id}/roles',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users By Group
     * Get users by group id
     * @param groupId
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @returns Page_FunctieDetailsGebruikerOut_ Successful Response
     * @throws ApiError
     */
    public getUsersByGroupPoliciesGroupsGroupIdUsersPost(
        groupId: string,
        search?: (string | null),
        sortingField?: 'Naam' | 'Functie',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_FunctieDetailsGebruikerOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/groups/{group_id}/users',
            path: {
                'group_id': groupId,
            },
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Employees To Group
     * @param groupId
     * @param requestBody
     * @returns GroupOut Successful Response
     * @throws ApiError
     */
    public addEmployeesToGroupPoliciesGroupsGroupIdEmployeesPost(
        groupId: string,
        requestBody: Array<string>,
    ): CancelablePromise<GroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/groups/{group_id}/employees',
            path: {
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User To Group
     * @param groupId
     * @param userId
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public addUserToGroupPoliciesGroupsGroupIdAddUserIdGet(
        groupId: string,
        userId: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/groups/{group_id}/add/{user_id}',
            path: {
                'group_id': groupId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove User From Group
     * @param groupId
     * @param userId
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public removeUserFromGroupPoliciesGroupsGroupIdRemoveUserIdGet(
        groupId: string,
        userId: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/policies/groups/{group_id}/remove/{user_id}',
            path: {
                'group_id': groupId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User To Groups
     * Add user to multiple groups
     * @param userId
     * @param requestBody
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public addUserToGroupsPoliciesUsersUserIdGroupsPost(
        userId: string,
        requestBody: Array<string>,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/policies/users/{user_id}/groups',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

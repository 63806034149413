import { Box, Tab, ThemeProvider, Typography } from "@mui/material";
import theme from "../../../Theme";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ButtonBack from "../../../components/buttons/ButtonBack";
import ButtonFull from "../../../components/buttons/ButtonFull";
import ButtonOutlined from "../../../components/buttons/ButtonOutlined";
import DataDetails from "./BusinessDataDetails";
import InvoiceDetails from "./InvoiceDetails";
import AddressDetails from "./AddressDetails";

export default function BusinessDetails() {
    const [tab, setTab] = useState<string>('gegevens');

    const param_tab = String(useParams().active_tab);
    const business_id = String(useParams().business_id);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        setTab(param_tab)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/klanten/zakelijk/${business_id}/${newValue}`);
        }
    };
    
    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', gap: '16px', flexDirection: 'column', mr: '16px'}}>
               {/* Header */}
               <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                    <ButtonBack destination='/klanten/zakelijk' />

                    <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                        Loaf IT   
                    </Typography> 
                    
                    {/* Different action buttons per tab */}
                    {
                        param_tab === 'gegevens' &&
                        <>
                            <ButtonOutlined onClick={() => {}} Icon={DeleteOutlineOutlinedIcon} text='Klant verwijderen'/>
                            <ButtonFull onClick={() => {}} Icon={QuestionAnswerOutlinedIcon} text='Bericht klant'/>
                        </>       
                    }
                    
                    {
                        param_tab === 'facturen' &&
                        <ButtonOutlined onClick={() => {}} Icon={QuestionAnswerOutlinedIcon} text='Bericht klant'/>   
                    }

{
                        param_tab === 'adressen' &&
                        <>
                            <ButtonOutlined onClick={() => {}} Icon={QuestionAnswerOutlinedIcon} text='Bericht klant'/>
                            <ButtonFull onClick={() => {}} Icon={AddOutlinedIcon} text='Afleveradres toevoegen'/>
                        </>       
                    }
                </Box>
                <TabContext value={tab}>
                    <TabList
                        onChange={handleChange}
                        TabIndicatorProps={{style: {background:'#D18C5C'}}}
                    >
                    <Tab icon={<BusinessOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                        <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                            Bedrijfsgegevens   
                        </Typography>} value = 'gegevens' 
                    />
                    <Tab icon={<EuroSymbolOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                        <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                            facturen  
                        </Typography>} value = 'facturen'
                    />
                    <Tab icon={<RoomOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                        <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                            Afleveradressen   
                        </Typography>} value = 'adressen' 
                    />
                    </TabList>

                    <TabPanel value='gegevens' sx={{p: 0}}>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                            <DataDetails />
                        </Box>
                    </TabPanel>
                    <TabPanel value='facturen' sx={{p: 0}}>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                            <InvoiceDetails />
                        </Box>
                    </TabPanel>
                    <TabPanel value='adressen' sx={{p: 0}}>
                        <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                            <AddressDetails />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </ThemeProvider>
    )
}
import { Box, Button, Chip, Divider, Fade, IconButton, MenuItem, Modal, Paper, Select, Slide, ThemeProvider, Typography } from "@mui/material";

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient } from "../../functions/Login";
import { DriveThruOrderFactIn, DriveThruOrderFactStatus, DriveThruOrderOut } from "../../assets";
import TempTheme from "../../TempTheme";

export default function DriveThruOrders() {
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
    const navigate = useNavigate();
    const event_location_id = String(useParams().location_id);

    //fullscreen functionality
    const [isFullscreen, setIsFullscreen] = useState(false);

    document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
            setIsFullscreen(true)
        } else {
            setIsFullscreen(false)
        }
      });

    useEffect(() => {
        if (isFullscreen) {
            document.body.requestFullscreen();
        } 
        if (!isFullscreen && document.fullscreenElement) {
            document.exitFullscreen();
        }
    }, [isFullscreen]);

    const enterFullscreen = () => {
        setIsFullscreen(true)
    };
    const exitFullscreen = () => {
        setIsFullscreen(false)
    };
    
    // Modal functionality
    const style = {
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
      };

    const [open, setOpen] = useState(false);
   
    const closeModal = () => {
        setOpen(false)
    };
    const openModal = () => {
        setOpen(true)
    };

    // desk constants
    const [desk, setDesk] = useState(1);
    const [deskCount, setDeskCount] = useState(1);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderCompleted, setOrderCompleted] = useState(0);
    const [orderCount, setOrderCount] = useState(0);

    // tickets constants
    const [selected, setSelected] = useState<DriveThruOrderOut | null>(null);
    const [firstOrder, setFirstOrder] = useState<DriveThruOrderOut | null>(null);
    const [secondOrder, setSecondOrder] = useState<DriveThruOrderOut | null>(null);
    const [thirdOrder, setThirdOrder] = useState<DriveThruOrderOut | null>(null);
    const [orders, setOrders] = useState<DriveThruOrderOut []>([]);
    
    // handle order completed, moving along the tickets
    const setCompleted = (order_id: string) => {
        const newFact = {
            status: DriveThruOrderFactStatus.AFGESLOTEN,
            distribution_point: desk,
            order_id: order_id
        } as DriveThruOrderFactIn
        apiClient().driveThru.createOrderFactDriveThruOrderFactsPost(newFact).then((r) => {
            const newOrders = orders.filter(order => order.id !== order_id);
            setOrderCount(newOrders.length - 3);
            setOrders(newOrders);

            if (typeof newOrders[0] === 'undefined') {
                setFirstOrder(null);
            } else {
                if ((firstOrder && newOrders[0].id !== firstOrder.id) || !firstOrder) {
                    setFirstOrder(newOrders[0])
                }
            }

            if (typeof newOrders[1] === 'undefined') {
                setSecondOrder(null);
            } else {
                if ((secondOrder && newOrders[1].id !== secondOrder.id) || !secondOrder) {
                    setSecondOrder(newOrders[1])
                }
            }

            if (typeof newOrders[2] === 'undefined') {
                setThirdOrder(null);
            } else {
                if ((thirdOrder && newOrders[2].id !== thirdOrder.id) || !thirdOrder) {
                    setThirdOrder(newOrders[2])
                }
            }

            closeModal();
            
        })
    }


    const deskChange = (newDesk: number) => {
        setFirstOrder(null);
        setSecondOrder(null);
        setThirdOrder(null);

        setDesk(newDesk);
    };

    // keep updating tickets
    useEffect(() => {
        // Get intial values for order view
        apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
            setDeskCount(r.distribution_points);
        });
        apiClient().driveThru.getCountCompletedOrdersDriveThruCountCompletedOrdersEventLocationIdGet(event_location_id).then((r) => {
            setOrderCompleted(r);
        });
        apiClient().driveThru.getCountOrdersDriveThruCountPaidOrdersEventLocationIdGet(event_location_id).then((r) => {
            setOrderTotal(r);
        });
        apiClient().driveThru.getDistributionInfoByIdDriveThruEventLocationIdDistributionDistributionPointGet(event_location_id, desk).then((r) => {
            setOrders(r.items);
            setOrderCount(r.items.length - 3);
            if (typeof r.items[0] !== undefined && (firstOrder ? r.items[0].id !== firstOrder.id : firstOrder === null)) {
                setFirstOrder(r.items[0]);
            }
            if (typeof r.items[1] !== undefined && (secondOrder ? r.items[1].id !== secondOrder.id : secondOrder === null)) {
                setSecondOrder(r.items[1]);
            }
            if (typeof r.items[2] !== undefined && (thirdOrder ? r.items[2].id !== thirdOrder.id : thirdOrder === null)) {
                setThirdOrder(r.items[2]);
            }
        });
        apiClient().driveThru.getCountCompletedOrdersDriveThruCountCompletedOrdersEventLocationIdGet(event_location_id).then((r) => {
            setOrderCompleted(r);
        });

        const reload = setInterval(() => {
            if (!document.hidden) {
                apiClient().driveThru.getDistributionInfoByIdDriveThruEventLocationIdDistributionDistributionPointGet(event_location_id, desk).then((r) => {
                    setOrders(r.items);
                    setOrderCount(r.items.length - 3);
                    if (typeof r.items[0] !== undefined && (firstOrder ? r.items[0].id !== firstOrder.id : firstOrder === null)) {
                        setFirstOrder(r.items[0]);
                    }
                    if (typeof r.items[1] !== undefined && (secondOrder ? r.items[1].id !== secondOrder.id : secondOrder === null)) {
                        setSecondOrder(r.items[1]);
                    }
                    if (typeof r.items[2] !== undefined && (thirdOrder ? r.items[2].id !== thirdOrder.id : thirdOrder === null)) {
                        setThirdOrder(r.items[2]);
                    }
                });
                apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
                    setDeskCount(r.distribution_points);
                });
                apiClient().driveThru.getCountOrdersDriveThruCountPaidOrdersEventLocationIdGet(event_location_id).then((r) => {
                    setOrderTotal(r);
                });
                apiClient().driveThru.getCountCompletedOrdersDriveThruCountCompletedOrdersEventLocationIdGet(event_location_id).then((r) => {
                    setOrderCompleted(r);
                });
            }
        }, 6000);

        return () => clearInterval(reload);

    }, [desk])
    
    return (
        <ThemeProvider theme={TempTheme}>
            {/* Order confirm Popup */}
           <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="Confirm popup"
            aria-describedby="Order confirm popup"
            >
            <Box sx={style}>
                <Box sx={{p: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant='headlineLarge'>
                        Markeren als afgehaald?
                    </Typography>
                    <Typography variant='headlineMedium' sx={{ my: '32px' }}>
                        {selected?.order_client_name}
                    </Typography>
                    <Typography variant='bodyLarge' sx={{ mb: '32px' }}>
                        Bestelnummer: {selected?.order_number.toString()}
                    </Typography>
                    <Box sx={{display: 'flex', gap: '16px'}}>
                        <Button onClick={closeModal} variant="outlined" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Annuleren  
                            </Typography> 
                        </Button>
                        <Button onClick={() => {setCompleted(selected!.id)}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Afgehaald  
                            </Typography> 
                        </Button>
                    </Box>
                </Box>
                
            </Box>
            </Modal>
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '90px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '64px', py: '16px', gap: '16px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={() => {navigate(-1)}} color='secondary' sx={{mr: '16px'}}>
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Typography variant="titleLarge" sx={{color: '#fff'}}>
                            Balie: 
                        </Typography> 
                        <Select
                            variant="outlined"
                            displayEmpty
                            onChange={(e) => {deskChange(e.target.value as number)}}
                            value={desk}
                            sx={{width: '60px', ml: '16px', backgroundColor: '#fff', borderRadius: '16px'}}
                        >
                            {
                                [...Array(deskCount)].map((e, i) =>
                                    <MenuItem value={i+1} key={i+1}>
                                        <Typography variant="labelLarge" sx={{my: 'auto'}}>
                                            {i + 1}
                                        </Typography> 
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="titleLarge" sx={{color: '#fff', mr: '16px'}}>
                            Orders: 
                        </Typography> 
                        <Chip color="secondary" label={
                            <Typography variant="titleLarge">
                                {orderTotal}  
                            </Typography>
                        }/>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="titleLarge" sx={{color: '#fff', mr: '16px'}}>
                            Afgehaald: 
                        </Typography> 
                        <Chip color="secondary" label={
                            <Typography variant="titleLarge">
                                {orderCompleted} 
                            </Typography>
                        }/>
                    </Box>
                    <Button onClick={isFullscreen ? exitFullscreen : enterFullscreen} sx={{mr: '32px', color: '#ECE5DE', borderRadius: '20px', borderColor: '#FFA239', borderWidth: '1px', border: 1, height: '40px'}}>
                        <Typography variant="labelLarge" sx={{px: '12px'}}>
                            {isFullscreen ? <>Verlaat fullscreen</> : <>Fullscreen</> } 
                        </Typography> 
                    </Button>
                </Box>
                {/* order overview */}
                <Box sx={{height: '100%', backgroundColor: '#ECE5DE', display: 'flex', px: '32px', gap: isMobile ? '0' : '64px', alignItems: isMobile ? 'center' : 'none', flexDirection: isMobile ? 'column' : 'row', overflowY: 'auto'}}>
                    <Box sx={{width: isMobile ? '60%' : '35%', display: 'flex', flexDirection: 'column', mt: '64px', position: 'relative'}}>
                        <Typography variant="headlineLarge" sx={{mx: 'auto'}}>
                            Huidige bestelling
                        </Typography>
                        <Paper variant="outlined" sx={{height: '500px', background: 'none', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px', borderStyle: 'dashed', borderWidth: '2px', borderColor: 'primary.main', position: 'absolute', zIndex: '1', width: '99%', top: '41px', justifyContent: 'center'}}>  
                        </Paper>
                        {
                            firstOrder ? 
                            <Slide timeout={1800} in={firstOrder ? true : false} direction={"left"}>
                                <Box sx={{postion: 'absolute', zIndex: '2'}}>
                                    <Fade in={firstOrder ? true : false} timeout={1800}>
                                        <Paper key={firstOrder.id} variant="outlined" sx={{height: '505px', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px'}}>
                                            <Typography variant="headlineLarge" sx={{mx: 'auto', mt: '16px'}}>
                                                {firstOrder.order_client_name}
                                            </Typography>
                                            <Typography variant="bodyLarge" sx={{mx: 'auto', my: '16px'}}>
                                                Bestelnummer: {firstOrder.order_number.toString()} 
                                            </Typography>
                                            <Divider />
                                            <Box key={firstOrder.id} sx={{overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                                                {
                                                    firstOrder.order_products!.map(product => {
                                                        return product.variant_active.variant.product_options?.map((po) => {
                                                            return (
                                                                <Box key={product.id}>
                                                                    <Box sx={{p: '8px', display: 'flex', alignItems: 'center'}}>
                                                                        <Chip color="primary" label={
                                                                            <Typography key={product.id} variant="titleMedium">
                                                                                {product.amount}x
                                                                            </Typography>
                                                                        }/>
                                                                        <Typography sx={{ml: '8px'}} variant="titleLarge">
                                                                            {product.variant_active.variant.sales_product?.sales_product_name} ({po.product_option_name})
                                                                        </Typography>
                                                                    </Box>
                                                                    <Divider />
                                                                </Box>
                                                            )
                                                        })
                                                    })
                                                }
                                                
                                            </Box>
                                            <Button onClick={() => {setSelected(firstOrder); openModal()}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px', m: '32px', mt: 'auto', pt: '8px'}}>
                                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                                    Afgehaald  
                                                </Typography> 
                                            </Button>
                                        </Paper>                         
                                    </Fade>
                                </Box>
                            </Slide>
                            :
                            <></>
                        }
                        
                    </Box>
                    <Box sx={{width: isMobile ? '70%' : '65%', display: 'flex', flexDirection: 'column', mt: isMobile ? '16px' : '64px'}}>
                        <Box sx={{display: 'flex', gap: '16px', alignItems: 'center', position: 'relative'}}>
                            <Typography sx={{mx: 'auto', position: 'relative', right: orderCount > 0  ? '-32px': '0px'}}  variant="headlineLarge" >
                                Wachtend
                            </Typography>
                            <Typography variant="headlineMedium" sx={{color: 'primary.main'}}>
                              {orderCount > 0 ? '+' + orderCount: ''}
                            </Typography> 
                        </Box>
                        <Box sx={{display: 'flex', gap: '16px', justifyContent: 'center'}}>
                            <Box sx={{width: '50%', display: 'flex', position: 'relative'}}>
                                <Paper variant="outlined" sx={{height: '500px', background: 'none', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px', borderStyle: 'dashed', borderWidth: '2px', borderColor: 'primary.main', position: 'absolute', zIndex: '1', width: '99%', top: '1px'}}>  
                                </Paper>
                                {
                                    secondOrder ?
                                    <Slide timeout={1800} in={secondOrder ? true : false} direction={"left"}>
                                        <Box sx={{postion: 'absolute', zIndex: '2', width: '100%'}}>
                                            <Fade in={secondOrder ? true : false} timeout={1800}>
                                        <Paper key={secondOrder.id} variant="outlined" sx={{width: '100%', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px', postion: 'absolute', zIndex: '2', height: '505px'}}>
                                            <Typography variant="headlineLarge" sx={{mx: 'auto', mt: '16px'}}>
                                                {secondOrder.order_client_name}
                                            </Typography>
                                            <Typography variant="bodyLarge" sx={{mx: 'auto', my: '16px'}}>
                                                Bestelnummer: {secondOrder.order_number.toString()} 
                                            </Typography>
                                            <Divider />
                                            <Box key={secondOrder.id} sx={{overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                                                {
                                                    secondOrder.order_products!.map(product => {
                                                        return product.variant_active.variant.product_options?.map((po) => {
                                                            return (
                                                                <Box key={product.id}>
                                                                    <Box sx={{p: '8px', display: 'flex', alignItems: 'center'}}>
                                                                        <Chip color="primary" label={
                                                                            <Typography key={product.id} variant="titleMedium">
                                                                                {product.amount}x
                                                                            </Typography>
                                                                        }/>
                                                                        <Typography sx={{ml: '8px'}} variant="titleLarge">
                                                                            {product.variant_active.variant.sales_product?.sales_product_name} ({po.product_option_name})
                                                                        </Typography>
                                                                    </Box>
                                                                    <Divider />
                                                                </Box>
                                                            )
                                                        })
                                                    })
                                                }
                                            </Box>
                                            <Button onClick={() => {setSelected(secondOrder); openModal()}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px', m: '32px', mt: 'auto'}}>
                                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                                    Afgehaald  
                                                </Typography> 
                                            </Button>
                                        </Paper>
                                        </Fade>
                                        </Box>
                                    </Slide>
                                    : <></>
                                }
                                
                            </Box>
                            
                            <Box sx={{width: '50%', display: 'flex', position: 'relative'}}>
                                <Paper variant="outlined" sx={{height: '500px', background: 'none', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px', borderStyle: 'dashed', borderWidth: '2px', borderColor: 'primary.main', position: 'absolute', zIndex: '1', width: '99%', top: '1px'}}>  
                                </Paper>
                                {
                                    thirdOrder ?
                                    <Slide timeout={1800} in={secondOrder ? true : false} direction={"left"} >
                                        <Box sx={{postion: 'absolute', zIndex: '2', width: '100%'}}>
                                            <Fade in={secondOrder ? true : false} timeout={1800}>
                                        <Paper key={thirdOrder.id} variant="outlined" sx={{width: '100%', display: 'flex', flexDirection: 'column', my: '32px', borderRadius: '16px', postion: 'absolute', zIndex: '2', height: '505px'}}>
                                            <Typography variant="headlineLarge" sx={{mx: 'auto', mt: '16px'}}>
                                                {thirdOrder.order_client_name}
                                            </Typography>
                                            <Typography variant="bodyLarge" sx={{mx: 'auto', my: '16px'}}>
                                                Bestelnummer: {thirdOrder.order_number.toString()} 
                                            </Typography>
                                            <Divider />
                                            <Box key={thirdOrder.id} sx={{overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                                                {
                                                    thirdOrder.order_products!.map(product => {
                                                        return product.variant_active.variant.product_options?.map((po) => {
                                                            return (
                                                                <Box key={product.id}>
                                                                    <Box sx={{p: '8px', display: 'flex', alignItems: 'center'}}>
                                                                        <Chip color="primary" label={
                                                                            <Typography key={product.id} variant="titleMedium">
                                                                                {product.amount}x
                                                                            </Typography>
                                                                        }/>
                                                                        <Typography sx={{ml: '8px'}} variant="titleLarge">
                                                                            {product.variant_active.variant.sales_product?.sales_product_name} ({po.product_option_name})
                                                                        </Typography>
                                                                    </Box>
                                                                    <Divider />
                                                                </Box>
                                                            )
                                                        })
                                                    })
                                                }
                                            </Box>
                                            <Button onClick={() => {setSelected(thirdOrder); openModal()}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px', m: '32px', mt: 'auto'}}>
                                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                                    Afgehaald  
                                                </Typography> 
                                            </Button>
                                        </Paper>
                                        </Fade>
                                        </Box>
                                    </Slide>
                                    : <></>
                                }
                                
                            </Box>
                                
                           
                        </Box>
                        
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}



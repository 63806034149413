import { Box, Checkbox, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import ButtonFull from "../../../components/buttons/ButtonFull";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { apiClient } from "../../../functions/Login";

interface ThirdPageProps {
    setStep: any
    steps: Array<string>
    activeStep: Signal<number>
    email: Signal<string>
    name: Signal<string>
    id: Signal<string>
  }
  
const ThirdPage: React.FC<ThirdPageProps> = ({ setStep, steps, activeStep, email, name, id }) => {
    useSignals();
    const iban: Signal<string> = useSignal('');

    return (
        <Box sx={{display: 'flex', height: '75%', width: '75%'}}>
            {/* Left side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '32px', width: '70%', backgroundColor: '#fff', height: '100%', borderRadius: '16px 0 0 16px'}}>
                <Stepper activeStep={activeStep.value}>
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step sx={{cursor: activeStep.value > index ? 'pointer' : ''}} onClick={(e) => {if (activeStep.value > index) setStep(e, index)}} key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Typography variant='headlineMedium'>
                    Betaalrekening instellen
                </Typography>
                <form id='form' onSubmit={(e) => {e.preventDefault(); apiClient().admin.adminPaymentCreateMandateAdminPaymentCreateMandateTenantCompanyIdPost(id.value, {payment_customer: {name: name.value, email: email.value}, iban: iban.value}).then(() => {activeStep.value += 1})}} style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <TextField value={iban.value} onChange={(e) => {iban.value = e.currentTarget.value}} inputProps={{pattern: '^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$'}} required label='IBAN' sx={{width: '50%'}}/>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '8px'}}>
                        <Checkbox required onChange={() => {}} name="mandate" />
                        <Typography variant="labelMedium" sx={{width: '40%'}}>
                            Door op 'koppel bankrekening' te drukken geeft de klant een incassomachtiging af aan Loaf IT*
                        </Typography>
                    </Box>
                    <ButtonFull type="submit" text='Koppel bankrekening' onClick={() => {}} width='50%'/>
                </form>
            </Box>

            {/* Right side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '64px', width: '30%', backgroundColor: 'primary.main', height: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: '0 16px 16px 0'}}>
                <Typography variant='headlineLarge' sx={{fontWeight: '700', color: '#fff'}}>
                    30 dagen gratis
                </Typography>
                <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Goed nieuws! Je online omgeving krijgt een <Typography variant='bodyLarge' sx={{fontWeight: '700', color: '#fff'}}> 30-daagse gratis proefversie </Typography> van onze software, dat het volgende omvat: </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Volledige toegang tot al onze operationele modules, zonder extra kosten
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Nieuwe verkoopkanalen, zoals brood abonnementen en drive-thru evenementen tegen 6% omzetfee.
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Gratis webshops voor 3e kanaal klanten
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        en nog meer webshops als online filiaal van jouw bakkerij...
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ThirdPage;
import { Box, Button, ButtonGroup, Checkbox, IconButton, Menu, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, ThemeProvider, Typography } from '@mui/material';
import { ReadonlySignal, Signal } from '@preact/signals-react';
import theme from '../../Theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { useComputed, useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import StatusChip from './StatusChip';
import React from 'react';
import WebshopIcon from './WebshopIcon';
import CollapseRow from './CollapseRow';
import { useNavigate } from 'react-router-dom';
import { CombinedOrderStatusEnum, PaymentStatus, ShopCategories } from '../../assets';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { apiClient } from '../../functions/Login';
import OrderCancel from './OrderCancel';
import OrderMail from './OrderMail';


interface DetailsProps {
  filters: ReadonlySignal<boolean>;
  orderDateRange: Signal<(Dayjs | null)[]> ;
  dueDateRange: Signal<(Dayjs | null)[]> ;
  status: Signal<CombinedOrderStatusEnum[]>;
  payment: Signal<Extract<PaymentStatus, PaymentStatus.BETAALD | PaymentStatus.OPEN | PaymentStatus.TERUGBETAALD>[]>;
  locations: Signal<string[]>;
  shopType?: ShopCategories;
}

const Details: React.FC<DetailsProps> = ({ filters, orderDateRange, dueDateRange, status, payment, locations, shopType }) => {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);

    useSignals();
    // Define signals
    const view: Signal<string> = useSignal('orders');
    const searchInput: Signal<string> = useSignal('');
    const selected: Signal<number[]> = useSignal([]);
    const page: Signal<number> = useSignal(0);
    const rowsPerPage: Signal<number> = useSignal(25);
    const anchorEl: Signal<null | HTMLElement> = useSignal(null);
    const rows: Signal<any[]> = useSignal([]);
    const cancelOrders: Signal<{orderNumber: string, customerName: string, webshop: string}[]> = useSignal([]);
    const openCancel: Signal<boolean> = useSignal(false);
    const mailOrders: Signal<{orderNumber: string, customerEmail: string, webshop: string}[]> = useSignal([]);
    const openMail: Signal<boolean> = useSignal(false);

    const navigate = useNavigate();

    const open = useComputed(() => {
        return Boolean(anchorEl.value)
    })

    const currentSelected = useComputed(() => {
       if (anchorEl.value) {
        return anchorEl.value.id 
       }

       return null
    })


    const csvData = useComputed(() => {
        if (visibleRows.value.length > 0) {
            const header = [Object.keys(visibleRows.value[0]).join(',') + '\n'];
            const rows = visibleRows.value.map(it => {
                return Object.values(it).join(',');
            }).join('\n');

            return header + rows
        }
        return null
    })

    const downloadCSV = () => {
        if (csvData.value) {
            const blob = new Blob([csvData.value], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Uitdraai_tabel.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
       
      };

    // Table logic
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.value.indexOf(id);
        let newSelected: number[] = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected.value, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.value.slice(1));
        } else if (selectedIndex === selected.value.length - 1) {
          newSelected = newSelected.concat(selected.value.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.value.slice(0, selectedIndex),
            selected.value.slice(selectedIndex + 1),
          );
        }
        selected.value = newSelected;
      };

      const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          const newSelected = rows.value.map((n, i) => i);
          selected.value = newSelected;
          return;
        }
        selected.value = [];
      };

      const isSelected = (id: number) => selected.value.indexOf(id) !== -1;

      const emptyRows = useComputed(() => {
         return Math.max(0, (1 + page.value) * rowsPerPage.value - rows.value.length);
      })

      const handleChangePage = (event: unknown, newPage: number) => {
        page.value = newPage;
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        rowsPerPage.value = parseInt(event.target.value, 10);
        page.value = 0;
      };
      
      useSignalEffect(() => {
        if (view.value === 'orders') {
            apiClient().shops.getAllShopOrdersShopsOrderDetailsOrdersPost(
                orderDateRange.value[0] == null ? undefined : orderDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
                orderDateRange.value[1] == null ? undefined : orderDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'),
                dueDateRange.value[0] == null ? undefined : dueDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
                dueDateRange.value[1] == null ? undefined : dueDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'), 
                undefined,
                shopType,
                page.value + 1,
                rowsPerPage.value,
                {
                    betaling_status: payment.value,
                    order_status: status.value,
                    locations: locations.value
                } 
            ).then((r) => {
                rows.value = r.items
            }).catch(() => {
                return
            })
        }
        else {
            apiClient().shops.getAllShopItemsShopsOrderDetailsItemsPost(
                orderDateRange.value[0] == null ? undefined : orderDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
                orderDateRange.value[1] == null ? undefined : orderDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'),
                dueDateRange.value[0] == null ? undefined : dueDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
                dueDateRange.value[1] == null ? undefined : dueDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'),
                shopType,
                page.value + 1,
                rowsPerPage.value,
                {
                    betaling_status: payment.value,
                    order_status: status.value,
                    locations: locations.value
                } 
            ).then((r) => {
                rows.value = r.items
            }).catch(() => {
                return
            })
        }
      });

    const visibleRows = useComputed(() => {
    if (view.peek() === 'orders') {
        return rows.value.filter(row => row.order_number.toUpperCase().includes(searchInput.value.toUpperCase()) || row.order_client_name.toUpperCase().includes(searchInput.value.toUpperCase())).slice(
            page.value * rowsPerPage.value,
            page.value * rowsPerPage.value + rowsPerPage.value,
            )
    } else {
        return rows.value.filter(row => row.item_variant.some((variant: any) => variant.variant_number.toUpperCase() === searchInput.value.toUpperCase()) || row.item_name.toUpperCase().includes(searchInput.value.toUpperCase()) || row.item_number.toUpperCase().includes(searchInput.value.toUpperCase())).slice(
            page.value * rowsPerPage.value,
            page.value * rowsPerPage.value + rowsPerPage.value,
            )
    }
    });

    return (
        <ThemeProvider theme={theme}>
            {/* Cancel order modal */}
            <OrderCancel 
                open={openCancel}
                orders={cancelOrders.value}
            />

            <OrderMail 
                open={openMail}
                orders={mailOrders.value}
            />
            
            {/* Title */}
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="titleLarge" sx={{fontWeight: 700}}>
                    Order details  
                </Typography>
                <Box sx={{display: filters.value ? 'flex' : 'none'}}>
                    <InfoOutlinedIcon />
                    <Typography variant="bodyLarge" sx={{ml: '4px'}}>
                    Let op: je hebt filters toegepast 
                    </Typography>
                </Box>
            </Box>

            {/* Filtering */}
            <Box sx={{display: 'flex', mb: '32px', justifyContent: 'space-between', alignItems: 'center'}}>
                <ButtonGroup  aria-label="View selection">
                    <Button onClick={() => {if (view.value !== 'orders') rows.value = []; view.value = 'orders'; selected.value = [];  searchInput.value = '';}} sx={{color: '#cecece', border: 1, borderRadius: '8px', width: '180px', height: '40px',
                        backgroundColor: view.value === 'orders' ? '#D18C5C' : '#fff',
                        '&:hover': {
                            backgroundColor: view.value === 'orders' ? '#D18C5C' : '#D18C5C33',
                            borderColor: view.value === 'orders' ? '#D18C5C' : '#D18C5C33'
                        },}}>
                        <Typography variant="labelLarge" sx={{ color: '#000'}}>
                        Orders
                        </Typography>
                    </Button> 
                    <Button onClick={() => {if (view.value !== 'artikelen') rows.value = []; view.value = 'artikelen'; selected.value = []; searchInput.value = '';}} sx={{color: '#cecece', border: 1, borderRadius: '8px', width: '180px', height: '40px',
                        backgroundColor: view.value === 'artikelen' ? '#D18C5C' : '#fff',
                        '&:hover': {
                            backgroundColor: view.value === 'artikelen' ? '#D18C5C' : '#D18C5C33',
                            borderColor: view.value === 'artikelen' ? '#D18C5C' : '#D18C5C33'
                        },}}>
                        <Typography variant="labelLarge" sx={{ color: '#000'}}>
                        Artikelen
                        </Typography>
                    </Button> 
                </ButtonGroup>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                        }}
                        id="search-bar"
                        onChange={(e) => {
                            searchInput.value = e.target.value;
                        } }
                        value={searchInput.value}
                        variant="outlined"
                        placeholder={view.value === 'orders' ? "Zoek ordernummer of klantnaam" : 'Zoek artikelnaam of artikelcode'}
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3'
                            },
                            width:'350px'
                        }} 
                    />
                    <Select
                        required
                        displayEmpty
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <SortByAlphaOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                    Sorteren  
                                </Typography> 
                            </Box>
                        </MenuItem>

                    </Select>
                    <Select
                        required
                        displayEmpty
                        open={false}
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        onClick={(e) => {downloadCSV()}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <FileDownloadOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                    Exporteren  
                                </Typography> 
                            </Box>
                        </MenuItem>

                    </Select>
                </Box>
            </Box>  
            {/* Table */}
            { view.value === 'orders' ?
            <Box sx={{position: 'relative'}}>
                <Table aria-label="Order table" sx={{}}>
                <TableHead sx={{"& .MuiTableCell-head": {color: "rgba(0,0,0,0.6)"}}}>
                        <TableRow sx={{'& .MuiTableCell-root': {
                                        
                                    }}}>
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    indeterminate={selected.value.length > 0 && selected.value.length < rows.value.length}
                                    checked={rows.value.length > 0 && selected.value.length === rows.value.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                    'aria-label': 'select all desserts',
                                    }}
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding='none' width={'2%'} align='left'>
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Ordernummer
                            </TableCell>
                            <TableCell width={'15%'} align='left'>
                                Klant
                            </TableCell>
                            <TableCell align='left'>
                                Orderdatum
                            </TableCell>
                            <TableCell align='left'>
                                Afleverdatum
                            </TableCell>
                            <TableCell width={'5%'} align='left'>
                                Bedrag
                            </TableCell>
                            <TableCell width={'5%'} align='left'>
                                Betaling
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Status
                            </TableCell>
                            <TableCell width={'5%'} align='right'>
                                <IconButton
                                    id='header'
                                    aria-controls={open ? 'header-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => {anchorEl.value = e.currentTarget}}
                                >
                                    <MoreVertOutlinedIcon />
                                </IconButton> 
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            visibleRows.value.map((row, i) => 
                            <TableRow 
                                hover
                                onClick={() => {navigate(`/order/details/${row.order_number}`, { state: { webshop: row.shop_type } })}}
                                key={i}
                                sx={{mb: '16px', cursor: 'pointer' }}>
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        color="secondary"
                                        checked={isSelected(i)}
                                        onClick={(event) => {event.stopPropagation(); handleClick(event, i)}}
                                    />
                                </TableCell>
                                <TableCell padding='none' width={'2%'} align='left'>
                                    <WebshopIcon webshop={row.shop_type}/>
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.order_number}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'15%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.order_client_name}
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant="labelLarge">
                                        {dayjs.utc(row.order_datetime).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant="labelLarge">
                                        {row.delivery_datetime ? dayjs.utc(row.delivery_datetime).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm') : '-'}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'5%'} align='left'>
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <EuroSymbolOutlinedIcon />
                                        <Typography variant='labelLarge'>{row.revenue.toFixed(2)}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell width={'5%'} align='left'>
                                    {row.payment_status === 'Open' ? 
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <CircleOutlinedIcon sx={{color: '#EAAE3E'}}/>
                                        <Typography variant='labelLarge'>Open</Typography>
                                    </Box>
                                    : row.payment_status === 'Betaald' ?
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <CheckCircleOutlineOutlinedIcon sx={{color: '#00B002'}}/>
                                        <Typography variant='labelLarge'>Betaald</Typography>
                                    </Box>
                                    :
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <CancelOutlinedIcon sx={{color: '#C00000'}}/>
                                        <Typography variant='labelLarge'>Refund</Typography>
                                    </Box>
                                    }
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <StatusChip status={row.status}/>
                                </TableCell>
                                <TableCell width={'5%'} align='right'>
                                    <IconButton
                                        key={i}
                                        id={row.order_number}
                                        aria-controls={open ? 'row-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => {e.stopPropagation(); anchorEl.value = e.currentTarget}}
                                    >
                                        <MoreVertOutlinedIcon />
                                    </IconButton> 
                                </TableCell>
                            </TableRow>
                            )
                        }
                        {emptyRows.value > 0 && (
                            <TableRow
                            style={{
                                height: (56) * emptyRows.value,
                            }}
                            >
                            <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage='Orders per pagina'
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={visibleRows.value.length}
                    rowsPerPage={rowsPerPage.value }
                    page={page.value > 0 ? page.value : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Typography variant='labelLarge' sx={{position: 'absolute', bottom: '20px', display: selected.value.length > 0 ? 'flex' : 'none'}}>
                    {selected.value.length + " Geselecteerd"}
                </Typography>
            </Box>
            : 
            <Box sx={{position: 'relative'}}>
                <Table aria-label="artikel tabel" sx={{}}>
                <TableHead sx={{"& .MuiTableCell-head": {color: "rgba(0,0,0,0.6)"}}}>
                        <TableRow sx={{'& .MuiTableCell-root': {
                                        
                                    }}}>
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    indeterminate={selected.value.length > 0 && selected.value.length < rows.value.length}
                                    checked={rows.value.length > 0 && selected.value.length === rows.value.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{
                                    'aria-label': 'select all desserts',
                                    }}
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding='none' width={'2%'} align='left'>
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Artikelnummer
                            </TableCell>
                            <TableCell width={'15%'} align='left'>
                                Artikelnaam
                            </TableCell>
                            <TableCell align='left'>
                                Besteld
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Bedrag
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Geleverd
                            </TableCell>
                            <TableCell width={'10%'} align='left'>
                                Nog te leveren
                            </TableCell>
                            <TableCell width={'5%'} align='right'>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            visibleRows.value.map((row, i) => 
                            < CollapseRow
                                key={i}
                                expandComponent={row.item_variant? row.item_variant : []}
                            >
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        color="secondary"
                                        checked={isSelected(i)}
                                        onClick={(event) => handleClick(event, i)}
                                    />
                                </TableCell>
                                <TableCell padding='none' width={'2%'} align='left'>
                                    <WebshopIcon webshop={'DRIVE-THRU'}/>
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.item_number}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'15%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.item_name}
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant="labelLarge">
                                        {row.quantity}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                        <EuroSymbolOutlinedIcon />
                                        <Typography variant='labelLarge'>{row.revenue.toFixed(2)}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.quantity_delivered}
                                    </Typography>
                                </TableCell>
                                <TableCell width={'10%'} align='left'>
                                    <Typography variant="labelLarge">
                                        {row.quantity_open}
                                    </Typography>
                                </TableCell>
                            </CollapseRow>
                            )
                        }
                        {emptyRows.value > 0 && (
                            <TableRow
                            style={{
                                height: (56) * emptyRows.value,
                            }}
                            >
                            <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage='Artikelen per pagina'
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={visibleRows.value.length}
                    rowsPerPage={rowsPerPage.value}
                    page={page.value > 0 ? page.value : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Typography variant='labelLarge' sx={{position: 'absolute', bottom: '20px', display: selected.value.length > 0 ? 'flex' : 'none'}}>
                    {selected.value.length + " Geselecteerd"}
                </Typography>
            </Box>
            }

            {/* Row Menu */}
            <Menu
                id="row-menu"
                anchorEl={anchorEl.value}
                open={open.value}
                onClose={() => {anchorEl.value = null}}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {let webshop = visibleRows.value.find(row => row.order_number === currentSelected.value).shop_type; currentSelected.value ? navigate(`/order/details/${currentSelected.value}`, { state: { webshop: webshop } }) : anchorEl.value = null}} sx={{ gap: '4px', display: anchorEl.value ? anchorEl.value.id === 'header' ? 'none' : 'flex' : 'none'}}>
                    <ReceiptOutlinedIcon />
                    <Typography variant='labelLarge'>
                        Bekijk order details
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => {let row = visibleRows.value.find(row => row.order_number === currentSelected.value); cancelOrders.value = [{orderNumber: row.order_number, customerName: row.order_client_name, webshop: row.shop_type}]; openCancel.value = true; anchorEl.value = null}} sx={{display: 'flex', gap: '4px', mt: '4px'}}>
                    <CancelOutlinedIcon />
                    <Typography variant='labelLarge'>
                        Annuleer order
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => {let row = visibleRows.value.find(row => row.order_number === currentSelected.value); mailOrders.value = [{orderNumber: row.order_number, customerEmail: row.order_client_email, webshop: row.shop_type}]; openMail.value = true; anchorEl.value = null}} sx={{display: 'flex', gap: '4px', mt: '4px'}}>
                    <MailOutlineOutlinedIcon />
                    <Typography variant='labelLarge'>
                        Bevestiging versturen
                    </Typography>
                </MenuItem>
                {/* <MenuItem sx={{display: 'flex', gap: '4px', mt: '4px'}}>
                    <QuestionAnswerOutlinedIcon />
                    <Typography variant='labelLarge'>
                        Bericht klant
                    </Typography>
                </MenuItem> */}
            </Menu>

        </ThemeProvider>
    );
};

export default Details;
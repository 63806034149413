import { Box, Checkbox, CircularProgress, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import ButtonFull from "../../../components/buttons/ButtonFull";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { apiClient } from "../../../functions/Login";
import { Body_create_tenant_auth_tenant_post } from "../../../assets";
import { truncateSync } from "fs";

interface FourthPageProps {
    setStep: any
    steps: Array<string>
    activeStep: Signal<number>
    first_name: Signal<string>;
    prefixes: Signal<string>;
    last_name: Signal<string>;
    id: Signal<string>;
    email: Signal<string>; 
    password: Signal<string>; 
  }
  
const FourthPage: React.FC<FourthPageProps> = ({ setStep, steps, activeStep, first_name, prefixes, last_name, email, password, id }) => {
    useSignals();
    const loading: Signal<boolean> = useSignal(false);

    const createTenant = () => {
        loading.value = true;

        const body = {
            user: {
                first_name: first_name.value,
                preposition_name: prefixes.value,
                last_name: last_name.value,
                email: email.value,
                password: password.value
            },
            tenant: {
                tenant_company_id: id.value
            }
        } as Body_create_tenant_auth_tenant_post
        apiClient().authentication.createTenantAuthTenantPost(body).then((r) => {      
            loading.value = false
            if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                window.open(`https://www.${r.host}.loaf-it.dev/bedrijf/gegevens`)
            } else {
                window.open(`https://www.${r.host}.loaf-it.com/bedrijf/gegevens`)
            }
        })
    }

    return (
        <Box sx={{display: 'flex', height: '75%', width: '75%'}}>
            {/* Left side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '32px', width: '70%', backgroundColor: '#fff', height: '100%', borderRadius: '16px 0 0 16px'}}>
                <Stepper activeStep={activeStep.value}>
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step sx={{cursor: activeStep.value > index ? 'pointer' : ''}} onClick={(e) => {if (activeStep.value > index) setStep(e, index)}} key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', gap: '32px'}}>
                    <Typography variant='headlineMedium'>
                        Gefeliciteerd! Je staat op het punt om jouw winstgevende online omgeving in te stellen.
                    </Typography>
                    <Typography variant='bodyLarge'>
                        Je kunt nu aan de slag met het instellen van jouw omgeving. Bekijk onze instructievideo’s voor extra ondersteuning bij het instellen.
                    </Typography>
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <ButtonFull width='50%' disabled={loading.value} text='Start met instellen' onClick={createTenant}/>
                        <CircularProgress sx={{display: loading.value ? 'flex' : 'none'}}/>
                    </Box>
                   
                </Box>
            </Box>

            {/* Right side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '64px', width: '30%', backgroundColor: 'primary.main', height: '100%', alignItems: 'center', justifyContent: 'center', borderRadius: '0 16px 16px 0'}}>
                <Typography variant='headlineLarge' sx={{fontWeight: '700', color: '#fff'}}>
                    30 dagen gratis
                </Typography>
                <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Goed nieuws! Je online omgeving krijgt een <Typography variant='bodyLarge' sx={{fontWeight: '700', color: '#fff'}}> 30-daagse gratis proefversie </Typography> van onze software, dat het volgende omvat: </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Volledige toegang tot al onze operationele modules, zonder extra kosten
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Nieuwe verkoopkanalen, zoals brood abonnementen en drive-thru evenementen tegen 6% omzetfee.
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        Gratis webshops voor 3e kanaal klanten
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                        en nog meer webshops als online filiaal van jouw bakkerij...
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default FourthPage;
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Allergens } from './Allergens';
import type { ShopCategories } from './ShopCategories';
import type { Weekdays } from './Weekdays';

export type Body_get_all_products_items_products_post = {
    product_type?: Body_get_all_products_items_products_post.product_type;
    group_ids?: Array<string>;
    webshops?: Array<ShopCategories>;
    bezorgdagen?: Array<Weekdays>;
    status?: Body_get_all_products_items_products_post.status;
    variant_ids?: Array<string>;
    allergens?: Array<Allergens>;
};

export namespace Body_get_all_products_items_products_post {

    export enum product_type {
        LOS_ARTIKEL = 'Los Artikel',
        SAMENGESTELD_ARTIKEL = 'Samengesteld artikel',
    }

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}


import { Button, CircularProgress, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ButtonFullProps {
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    loading?: boolean
    width?: string
    text: string
    onClick: any
    type?: 'button' | 'reset' | 'submit'
    form?: string
    disabled? : boolean
}

const ButtonFull: React.FC<ButtonFullProps> = ({Icon, width, text, onClick, form = 'form', type = 'button', disabled = false, loading = false, ...props}) => {
    // ButtonFull used in most designs, so this way we can limit the amount redundency

    return (
      <Button form={form} disabled={disabled} type={type} onClick={onClick} sx={{backgroundColor: 'secondary.main', borderRadius: '20px', width: width ? width : 'fit-content', color: '#fff', px: '16px', height: '40px', alignContent: 'center',
          '&:hover': {
          backgroundColor: 'secondary.light',
          color: '#fff',
      },}}>
          {
            Icon && <Icon />
          }    
          {
            loading ? 
            <CircularProgress size={30} sx={{color: 'white'}}/>
            :
            <Typography variant="labelLarge" sx={{ml: Icon ? '4px': 0, alignItems: 'center'}}>
              {text}
            </Typography>
          }
      </Button>
    );
  };

export default ButtonFull;
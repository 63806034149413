import {Box, ThemeProvider, Typography } from '@mui/material';
import theme from '../Theme';

function Home() {
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{p: '32px', display: 'flex', flexDirection: 'column'}}>
        <Typography variant="headlineLarge">
          Home    
        </Typography> 
      </Box>
    </ThemeProvider>
  );
}

export default Home;
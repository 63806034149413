import { Box, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";


interface CardProps {
    title?: string
    button?: ReactNode
    maxHeight?: string
    orientation?: 'column' | 'row'
    background?: boolean
    children?: ReactNode
}

const Card: React.FC<CardProps> = ({title, button, maxHeight, orientation = 'column', background = true, children, ...props}) => {
    // Card used in most designs, so this way we can limit the amount redundency

    return (
      <Box sx={{display: 'flex', flexDirection: 'column', height: 'auto', maxHeight: maxHeight ? maxHeight : 'fit-content', gap: '16px', p: '32px', borderRadius: '16px', ...(background && {backgroundColor: '#fff'})}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '8px'}}>
          <Typography variant="titleLarge" sx={{mr: 'auto'}}>
              {title}
          </Typography>
          {button}
        </Box>
       
        
        {
          title && 
          <Divider />
        }

        <Box sx={{display: 'flex', gap: '16px', flexDirection: orientation}}>
          {children}
        </Box>
        
      </Box>
    );
  };

export default Card;
import { Box, Checkbox, Divider, IconButton, MenuItem, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { batch, Signal } from "@preact/signals-react";
import { createRef, useEffect } from "react";
import theme from "../../Theme";
import AddressCard from "../../components/Address/AddressCard";
import ButtonOutlined from "../../components/buttons/ButtonOutlined";
import AddressOverview from "../../components/Address/AddressOverview";
import { apiClient } from "../../functions/Login";
import { Body_get_all_subsidiaries_users_subsidiaries_post, LocationNaamOut, LocationOverviewOut } from "../../assets";

export default function CompanyBranches() {
    const addressContainer = createRef<HTMLDivElement>();

    useSignals()
    const searchInput: Signal<string> = useSignal('');
    const selectedAddress: Signal<string> = useSignal('');

    const categories: Signal<string[]> = useSignal([]);
    const selectedCategories: Signal<string[]> = useSignal([]);
    const debiteuren: Signal<LocationNaamOut[]> = useSignal([]);
    const selectedDebiteuren: Signal<string[]> = useSignal([]);
    const status: Signal<string> = useSignal('');

    const locations: Signal<LocationOverviewOut[]> = useSignal([]);

    const filtering = useComputed(() => {
        return (status.value !== '' || selectedCategories.value.length > 0 || searchInput.value.length > 0 || selectedDebiteuren.value.length > 0)
    })

    const clearFilters = () => {
        batch(() => {
            status.value = '';
            searchInput.value = '';
            selectedCategories.value = [];
            selectedDebiteuren.value = [];
        })
    }

    useEffect(() => {
        apiClient().userManagement.getAllLocationCategoriesUsersLocationCategoriesGet().then((r) => {
            categories.value = r.items.map(item => item.name);
        })
        apiClient().userManagement.getDebiteurenUsersDebiteurenGet().then((r) => {
            debiteuren.value = r.items
        })
    }, [])

    // For search endpoint
    useEffect(() => {
        const body = {
            categories: selectedCategories.value,
            debiteur_ids: selectedDebiteuren.value,
            status: status.value.length > 0 ? status.value : null
        } as Body_get_all_subsidiaries_users_subsidiaries_post

        apiClient().userManagement.getAllSubsidiariesUsersSubsidiariesPost(
            searchInput.value,
            1,
            25,
            body
        ).then((r) => {
            if (!r.items.map(v => v.id).includes(selectedAddress.value))
                selectedAddress.value = '';
            locations.value = r.items
        })
    }, [searchInput.value, status.value, selectedCategories.value, selectedDebiteuren.value])

    const closeOverview = () => {
        selectedAddress.value = '';
    }

    const backToTop = () => {
        addressContainer.current?.scrollTo({top: 0, behavior: 'smooth'});
    }

    const isOverflowing = useComputed(() => {
        if (addressContainer.current) {
            return addressContainer.current?.scrollHeight > addressContainer.current?.clientHeight
        } else {
            return false
        }
        
    })

    const addressUrl = useComputed(()  => {
        if (selectedAddress.value !== '') {
            const address_info = locations.value.find(location => location.id === selectedAddress.value)?.company.delivery_address;
            if (address_info)
                return address_info.street + '%20' + address_info.number + (address_info.number_suffix ? address_info.number_suffix : '') + '%20' + address_info.city

            return ''
        } else {
            return ''
        }
    })

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {/* Filter selects */}
                <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                        }}
                        id="search-bar"
                        onChange={(e) => {
                            searchInput.value = e.target.value;
                        } }
                        value={searchInput.value}
                        variant="outlined"
                        placeholder={'Zoek locatienaam of adres'}
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3',
                            maxHeight: '50px'
                            },
                            width:'350px',
                            maxHeight: '50px'
                        }} 
                    />
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={selectedCategories.value}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <StorefrontOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Categorie   
                                    </Typography> 
                                </Box>)
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {selectedCategories.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                        sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <StorefrontOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Categorie 
                                </Typography> 
                            </Box>
                        </MenuItem>
                        {
                            categories.value.map((category) => 
                                <MenuItem value={category} key={category}>
                                    <Checkbox checked={selectedCategories.value.indexOf(category) > -1} />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        {category}   
                                    </Typography> 
                                </MenuItem>
                            )
                        }
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={selectedDebiteuren.value}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <CreditCardOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Debiteur   
                                    </Typography> 
                                </Box>)
                            }
                
                            return debiteuren.value.filter(d => selected.includes(d.id)).map(d => d.name).join(', ');
                        }}
                        onChange={(e) => {selectedDebiteuren.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                        sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <CreditCardOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Debiteur 
                                </Typography> 
                            </Box>
                        </MenuItem>
                        {
                            debiteuren.value.map((debiteur) => 
                                <MenuItem value={debiteur.id} key={debiteur.id}>
                                    <Checkbox checked={selectedDebiteuren.value.indexOf(debiteur.id) > -1} />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        {debiteur.name}   
                                    </Typography> 
                                </MenuItem>
                            )
                        }
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={status.value}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <EditAttributesOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Status   
                                    </Typography> 
                                </Box>
                                )
                            }
                
                            return <Typography variant='labelLarge'>{selected}</Typography>;
                        }}
                        onChange={(e) => {status.value = e.target.value}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value={''} key={''}>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <EditAttributesOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Status   
                                </Typography> 
                            </Box>
                        </MenuItem>
                        <MenuItem value={'Actief'} key={'Actief'}>   
                            <Typography variant="labelLarge">
                                Actief   
                            </Typography> 
                        </MenuItem>
                        <MenuItem value={'Inactief'} key={'Inactief'}>   
                            <Typography variant="labelLarge">
                                Inactief   
                            </Typography> 
                        </MenuItem>
                    </Select>
                    <Typography onClick={clearFilters} sx={{display: filtering.value ?  'flex': 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                        Filters wissen  
                    </Typography> 
                </Box>
                    
                <Box sx={{display: 'flex', backgroundColor: '#fff', borderRadius: '16px', mb: '32px'}}>
                    {/* Addresses */}
                    <Box ref={addressContainer} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '800px', overflowY: 'auto'}}>
                        {
                            locations.value.map((location, index) => (
                                <AddressCard address={location.company.delivery_address.street + ' ' + location.company.delivery_address.number + (location.company.delivery_address.number_suffix ? location.company.delivery_address.number_suffix : '')} name={location.name} phone_number={(location.company.phone_number ? location.company.phone_number : '-')} onClick={() => {selectedAddress.value = location.id}} key={location.id}/>
                            ))
                        }
                        {
                            locations.value.length < 1 &&
                            <Typography variant='bodyLarge' sx={{mx: 'auto', my: 'auto'}}>
                                Geen locaties gevonden
                            </Typography>
                        }
                        <Box sx={{display: isOverflowing.value ? 'flex' : 'none', my: '8px', justifyContent: 'center'}}>
                            <ButtonOutlined Icon={VerticalAlignTopOutlinedIcon} text='Terug naar boven' onClick={backToTop}/>
                        </Box>
                        
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    {/* Map */}
                    <Box sx={{display: 'flex', flexGrow: 2, position: 'relative', alignItems: 'center'}}>
                        {
                            selectedAddress.value !== '' ?
                            <>
                                <AddressOverview key={selectedAddress.value} location={locations.value.find(l => l.id === selectedAddress.value)!} onClose={closeOverview}/>
                                <iframe
                                    title="vestiging locatie"
                                    height={'800px'}
                                    width={'100%'}
                                    style={{borderRadius: '0 12px 12px 0', position: 'relative', zIndex: '1', outline: 'none', border: '0'}}
                                    loading="lazy"
                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                        &q=${addressUrl.value}}`}
                                >
                                </iframe>
                            </>
                           :
                           <Typography variant='titleLarge' sx={{width: '100%', height: '800px', textAlign: 'center', alignContent: 'center'}}>
                                Selecteer een locatie.
                           </Typography>

                        }
                        
                    </Box>
                </Box>


            </Box>
        </ThemeProvider>
    )
}
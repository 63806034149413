import { TableCell } from "@mui/material";
import StatusChip from "../orders/StatusChip";
import WebshopIcon from "../orders/WebshopIcon";
import PaymentStatusChip from "./PaymentStatusChip";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface CustomTableCellProps {
    value: string
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({value}) => {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);

    let cellValue: any;

    let betaalStatus = [
        'Betaald', 'Open', 'Refund'
    ]

    let webshops = [
        'Abbonnement', 'Drive-thru', 'Standaard', 'Zakelijk', 'Filiaal'
    ]
    
    let status = [
        'Besteld',  'Orderbevestiging', 'In behandeling',
        'Voorraadcontrole', 'Gescand', 'Orderpicking', 'Gereed voor levering',
        'Onderweg', 'Geleverd', 'Facturatie', 'Afgesloten', 'Geannuleerd', 'Actief' ,'Inactief'
    ] 

    // Check if the cell value is special and needs a different representation. Above is the list of keywords 
    // per type of representation.
    if (status.includes(value)) {
        cellValue = <StatusChip status={value} />
    } else if (webshops.includes(value)) {
        cellValue = <WebshopIcon webshop={value} />
    } else if (betaalStatus.includes(value)) {
        cellValue = <PaymentStatusChip status={value} />
    } else if (dayjs(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        cellValue = dayjs.utc(value).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm')
    } else if (value === null || value.length === 0) {
        cellValue = '-'
    } else if (Array.isArray(value)) {
        cellValue = value.join(', ')
    } else {
        cellValue = value
    }

    return (
      <TableCell width={'20px'}>
        { cellValue }
      </TableCell>
    );
  };

export default CustomTableCell;
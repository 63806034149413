/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Allergens {
    GLUTEN = 'gluten',
    SCHAALDIEREN = 'schaaldieren',
    EI = 'ei',
    VIS = 'vis',
    PINDA = 'pinda',
    SOJA = 'soja',
    MELK = 'melk',
    NOTEN = 'noten',
    SELDERIJ = 'selderij',
    MOSTERD = 'mosterd',
    SESAMZAAD = 'sesamzaad',
    ZWAVELDIOXIDE = 'zwaveldioxide',
    LUPINE = 'lupine',
    WEEKDIEREN = 'weekdieren',
}

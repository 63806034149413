import { Box, Button, Checkbox, Chip, Divider, ListItemIcon, Menu, MenuItem, Modal, Paper, Select, SelectChangeEvent, ThemeProvider, Typography } from "@mui/material";
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import theme from "../../Theme";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiClient } from "../../functions/Login";
import { Body_get_all_shops_filtered_shops_shops_filtered_post, ShopOut, ShopOutCropped, ShopOutCroppedList, ShopOutList, ShopSubCategoryOut } from "../../assets";
import 'dayjs/locale/nl';
import { ConfigProvider, DatePicker } from "antd";
import locale from 'antd/es/date-picker/locale/nl_NL';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";

export default function DriveThruOverview() {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(minMax)

    // Signals
    useSignals()
    const DateRange: Signal<(Dayjs | null)[]> = useSignal([null]);

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const menuId = anchorEl ? anchorEl.id : undefined;
    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const [selectedDriveThru, setSelectedDriveThru] = useState<string>('');
    const deleteDriveThru = () => {
        handleClose();
        apiClient().shops.deleteShopByIdShopsShopsShopIdDelete(selectedDriveThru).then((r) => {
            setDriveThrus(DriveThrus.filter(drivethru => drivethru.id !== selectedDriveThru));
            setSelectedDriveThru('');
        })
    }

    const { RangePicker } = DatePicker;

    //Modal constants
    const [open, setOpen] = useState(false);
    const handleOpen = () => {setOpen(true);}
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
      };

    //category constants
    const handleCategoryChange = (event: SelectChangeEvent<typeof selectedCategories>) => {
        const {
          target: { value },
        } = event;
        setSelectedCategories(
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    const [categories, setCategories] = useState<ShopSubCategoryOut[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    
    //drive thru constants
    const [liveDriveThrus, setLiveDriveThrus] = useState<ShopOutCropped[]>([]);
    const [DriveThrus, setDriveThrus] = useState<ShopOutCropped[]>([]);

    useEffect(() => {
       // all drive-thru categories
       apiClient().shops.getAllShopSubCategoriesShopsShopSubCategoriesGet().then((r) => {
        let sub_categories:ShopSubCategoryOut[] = [];
        r.items.forEach((sub_category) => {
            sub_categories.push(sub_category)
        });
        setCategories(sub_categories);
     })
    }, [])

    const setShops = (shopList: ShopOutCroppedList) => {
        let liveShops:ShopOutCropped[] = [];
        let shops:ShopOutCropped[] = [];
        shopList.items.forEach((shop) => {
            let latestEndDate: Dayjs | null = null;
            if (typeof shop.drive_thru_event_locations !== 'undefined' && shop.drive_thru_event_locations && shop.drive_thru_event_locations.length > 0) {
                latestEndDate = dayjs.utc(shop.drive_thru_event_locations[0].event_datetime_end);
                shop.drive_thru_event_locations.forEach((location) => {
                    latestEndDate = dayjs.max(latestEndDate!, dayjs.utc(location.event_datetime_end));
                })
            }

            if (dayjs.utc(shop.shop_opening_datetime).isBefore(dayjs()) && (latestEndDate ? latestEndDate.isAfter(dayjs()) : true)) {
                liveShops.push(shop);
            } else {
                shops.push(shop);
            }
        });
        setLiveDriveThrus(liveShops);
        setDriveThrus(shops);
    }

    // all fetches from endpoints
    useEffect(() => {
        let body = {
            ...((selectedCategories.length !== 0) && {subcategories: selectedCategories}),
            ...((DateRange.value[0] !== null) && {date_time_from: DateRange.value[0]!.utc().format('YYYY-MM-DD HH:mm:ss')}),
            ...((DateRange.value[0] !== null) && {date_time_to: DateRange.value[1]!.utc().format('YYYY-MM-DD HH:mm:ss')}),
        } as Body_get_all_shops_filtered_shops_shops_filtered_post
        apiClient().shops.getAllShopsFilteredShopsShopsFilteredPost(Object.keys(body).length !== 0 ? body : undefined).then((r) => {
            setShops(r)
        })
        
        const reload = setInterval(() => {
            //all drive-thrus
            if (!document.hidden) {
                let body = {
                    ...((selectedCategories.length !== 0) && {subcategories: selectedCategories}),
                    ...((DateRange.value[0] !== null) && {date_time_from: DateRange.value[0]!.utc().format('YYYY-MM-DD HH:mm:ss')}),
                    ...((DateRange.value[0] !== null) && {date_time_to: DateRange.value[1]!.utc().format('YYYY-MM-DD HH:mm:ss')}),
                } as Body_get_all_shops_filtered_shops_shops_filtered_post
                apiClient().shops.getAllShopsFilteredShopsShopsFilteredPost(Object.keys(body).length !== 0 ? body : undefined).then((r) => {
                    setShops(r)
                })
            }
        }, 20000);

        return () => clearInterval(reload);
    }, [selectedCategories, DateRange.value])

    const getAddress = (shop: ShopOutCropped) => {
        let locations = shop.drive_thru_event_locations
        if (locations && locations.length > 0) {
            return locations[0].location.company.delivery_address!.street! 
            + " " 
            + locations[0].location.company.delivery_address!.number.toString()
            + ","
            + locations[0].location.company.delivery_address!.city
        }
        return ''
    } 

    return (
        <>
        <ThemeProvider theme={theme}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Delete event"
                aria-describedby="Delete event popup"
                >
                <Box sx={style}>
                    <Box sx={{p: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant='headlineLarge'>
                            {DriveThrus.find(drivethru => drivethru.id === selectedDriveThru)?.shop_name} verwijderen
                        </Typography>
                        <Typography variant='bodyLarge' sx={{ mb: '32px' }}>
                            Weet je zeker dat je dit evenement wilt verwijderen?
                        </Typography>
                        <Box sx={{display: 'flex', gap: '16px'}}>
                            <Button onClick={handleClose} variant="outlined" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Annuleren  
                                </Typography> 
                            </Button>
                            <Button onClick={() => {deleteDriveThru()}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Verwijderen  
                                </Typography> 
                            </Button>
                        </Box>
                    </Box>
                    
                </Box>
            </Modal>
            
            {/* Filters with buttons */}
            <Box sx={{display: 'flex', justifyContent: 'start', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Select
                    displayEmpty
                    multiple
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    renderValue={ (selected: string[]) => {
                        if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <CategoryOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                        Categorie   
                                    </Typography> 
                                </Box>
                            )
                        } else {
                            return (
                                selected.join(', ')
                            )
                        }
                    }
                    }
                    sx={{width: '240px', borderRadius: '12px'}}
                >
                    {
                        categories.map((sub_category) => 
                            <MenuItem key={sub_category.id} value={sub_category.shop_sub_category_name}>
                                <Checkbox checked={selectedCategories.indexOf(sub_category.shop_sub_category_name) > -1} />
                                <Typography variant='labelLarge'>{sub_category.shop_sub_category_name}</Typography>
                            </MenuItem>
                        )
                    }
                </Select>
                <ConfigProvider
                    theme={{
                        components: {
                        DatePicker: {
                            activeBorderColor: '#28555e',
                            cellRangeBorderColor: '#28555e',
                            hoverBorderColor: '#28555e',
                            colorPrimary: '#28555e',
                            colorBorder: '#c4c4c4',
                            borderRadius: 12,
                            colorTextPlaceholder: '#000',
                            activeShadow: 'none',
                            presetsWidth: 300
                        },
                        },
                    }}
                >
                    <RangePicker onChange={(e) => {e ? DateRange.value = [e[0], e[1]] : DateRange.value = [null]}} placeholder={['Datum van', 'Datum tot']} locale={locale} />
                </ConfigProvider>
                {/* <Select
                    displayEmpty
                    renderValue={ () =>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <EditAttributesOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                Status   
                            </Typography> 
                        </Box>
                    }
                    sx={{width: '240px', borderRadius: '12px'}}
                >
                </Select> */}
            </Box>
            {/* Shops */}
            <Box sx={{display: 'flex', flexDirection: 'column', p: '4px'}}>
                <Typography variant='titleLarge' sx={{my: '32px'}}>
                    Nu live
                </Typography>
                {/* Active drive-thru */}
                {
                    liveDriveThrus.map((shop) => 
                        <Paper key={shop.id} sx={{width: '100%', my: '16px', display: 'flex', borderRadius: '12px', height: '200px'}} variant="outlined">
                            <Box sx={{width: '15%'}}>
                                <iframe
                                    title="Event locatie"
                                    height={'100%'}
                                    width={'100%'}
                                    style={{borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', border: 0}}
                                    loading="lazy"
                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                        &q=${getAddress(shop)}`}
                                    >
                                </iframe>
                            </Box>
                            <Box sx={{width: '25%', p: '16px', display: 'flex', flexDirection: 'column'}}>
                                <Chip size="small" sx={{backgroundColor: "#FFBE42", width: '80px'}} label={
                                    <Typography variant="labelLarge" sx={{}}>
                                        Nu bezig   
                                    </Typography> 
                                }/>
                                <Typography variant="titleLarge" sx={{mt: 'auto'}}>
                                   {shop.shop_name}  
                                </Typography> 
                                <Typography variant="labelLarge" sx={{mt: '8px'}}>
                                    {dayjs.utc(shop.shop_opening_datetime).local().format('DD/MM/YYYY[,] HH:mm')} - {dayjs.utc(shop.shop_closing_datetime).local().format('DD/MM/YYYY[,] HH:mm')}
                                </Typography> 
                            </Box>
                            <Box sx={{width: '60%', p: '16px', display: 'flex', alignItems: 'end', gap: '8px'}}>
                                
                                <Button sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', ml: 'auto', minWidth: 'fit-content',
                                    '&:hover': {
                                    backgroundColor: 'secondary.light',
                                    color: '#fff',
                                },}}
                                    id={shop.id}
                                    endIcon={<KeyboardArrowDownOutlinedIcon />}
                                    onClick={openMenu}
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    aria-expanded={menuId !== undefined}
                                >
                                    <Typography variant="labelLarge" sx={{pl: '4px'}}>
                                        Meer acties
                                    </Typography>
                                </Button> 
                                <Menu
                                    anchorEl={anchorEl}
                                    id={shop.id}
                                    open={menuId === shop.id}
                                    onClose={closeMenu}
                                    onClick={closeMenu}
                                    PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                        },
                                        '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                        },
                                    },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={() => {window.open(shop.webshop_url, '_blank')}}>
                                    <ListItemIcon>
                                        <DevicesOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Bekijk webshop
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem disabled onClick={closeMenu}>
                                    <ListItemIcon>
                                        <ReceiptOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Bekijk bestellingen
                                    </MenuItem>
                                </Menu>
                                <Button onClick={() => {window.open(window.location.origin + `/drive-thru/locaties/${shop.id}`, '_blank')}} sx={{backgroundColor: 'secondary.main', borderRadius: '20px',  minWidth: 'fit-content', color: '#fff',
                                    '&:hover': {
                                    backgroundColor: 'secondary.light',
                                    color: '#fff',
                                },}}>
                                    <LaunchOutlinedIcon sx={{mr: '4px'}}/>
                                    <Typography variant="labelLarge">
                                        Open drive-thru applicatie   
                                    </Typography>
                                </Button>
                            </Box>
                        </Paper>
                    )
                }
                <Typography variant='titleLarge' sx={{my: '32px'}}>
                    Event overzicht
                </Typography>
                <Box sx={{display: 'flex', flexWrap: "wrap", mb: '150px'}}>
                    {
                        DriveThrus.map((drivethru) =>
                            <Box key={drivethru.id} sx={{width: '20%', minWidth: 'fit-content', height: '400px', my: '8px'}}>
                                <Paper variant="outlined" sx={{height: '100%', borderRadius: '12px', mr: '8px', display: 'flex', flexDirection: 'column'}}>
                                    <iframe
                                        title="Event locatie"
                                        height={'50%'}
                                        width={'100%'}
                                        style={{borderTopLeftRadius: '12px', borderTopRightRadius: '12px', border: 0}}
                                        loading="lazy"
                                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                            &q=${getAddress(drivethru)}`}
                                        >
                                    </iframe>
                                    <Chip size="small" sx={{color: '#fff', backgroundColor: "#006E2A", width: '80px', top: '-50%', ml: 'auto', position: 'relative', mr: '8px', mt: '8px'}} label={
                                    <Typography variant="labelLarge" sx={{}}>
                                        Actief  
                                    </Typography> 
                                    }/>
                                    <Typography variant="titleLarge" sx={{mb: '8px', mt: '-16px', mx: '8px'}}>
                                        {drivethru.shop_name} 
                                    </Typography> 
                                    <Typography variant="labelLarge" sx={{ml: '16px', mx: '8px'}}>
                                        {dayjs.utc(drivethru.shop_opening_datetime).local().format('DD/MM/YYYY[,] HH:mm')} - {dayjs.utc(drivethru.shop_closing_datetime).local().format('DD/MM/YYYY[,] HH:mm')}  
                                    </Typography> 
                                    <Box sx={{display: 'flex', mt: 'auto', p: '16px', gap: '8px'}}>
                                        <Button onClick={() => {navigate(`/drive-thru/bewerk/${drivethru.id}`)}}  sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', ml: 'auto', minWidth: 'fit-content',
                                            '&:hover': {
                                            backgroundColor: 'secondary.light',
                                            color: '#fff',
                                        },}}>
                                            <EditOutlinedIcon sx={{mr: '4px'}}/>
                                            <Typography variant="labelLarge">
                                                Bewerken
                                            </Typography>
                                        </Button> 
                                        <Button onClick={openMenu} sx={{backgroundColor: 'secondary.main', borderRadius: '20px', color: '#fff', minWidth: 'fit-content',
                                            '&:hover': {
                                            backgroundColor: 'secondary.light',
                                            color: '#fff',
                                        },}}
                                            id={drivethru.id}
                                            endIcon={<KeyboardArrowDownOutlinedIcon />}
                                            aria-controls={menuId}
                                            aria-haspopup="true"
                                            aria-expanded={menuId !== undefined}
                                        >
                                            <Typography variant="labelLarge" sx={{pl: '4px'}}>
                                                Meer acties
                                            </Typography>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id={drivethru.id}
                                            open={menuId === drivethru.id}
                                            onClose={closeMenu}
                                            onClick={closeMenu}
                                            PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                                },
                                                '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                                },
                                            },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem onClick={() => {window.open(drivethru.webshop_url + "/preview/" + drivethru.preview_key, '_blank')}}>
                                            <ListItemIcon>
                                                <DevicesOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Preview webshop
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem disabled onClick={closeMenu}>
                                            <ListItemIcon>
                                                <ReceiptOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Bekijk bestellingen
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => {setSelectedDriveThru(drivethru.id); handleOpen();}}>
                                            <ListItemIcon>
                                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Verwijder event
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Paper>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </ThemeProvider>
        </>
        
    );
}


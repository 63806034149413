import {  AlertColor, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, MenuItem, Modal, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Skeleton, TextField, ThemeProvider, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import theme from "../../Theme";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from 'dayjs';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useEffect, useState } from "react";
import { DatePicker, DateTimePicker, DateTimeValidationError } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import { ApiError, DriveThruEventDayIn, DriveThruEventDayOut, DriveThruEventLocationIn, DriveThruEventLocationVehicleIn, EventVehicles, LocationOut, ShopCategories, ShopIn, ShopSubCategoryIn, ShopSubCategoryOut } from "../../assets";
import { apiClient } from "../../functions/Login";
import Alerter from "../../components/Alerter";
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import ThempTheme from "../../TempTheme";

export default function DriveThruEdit() {
    // general constants
    const navigate = useNavigate();
    const id = String(useParams().id);
    const [alertLength, setAlertLength] = useState(3000);

    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(minMax);

    // webshop URL constants
    const urlPrefix = window.location.origin + '/drive-thru/'
    const copyURL = () => {
        if (urlExtension !== '') {
            navigator.clipboard.writeText(urlPrefix + urlExtension);
            setSeverity('info');
            setText('Link gekopieerd.');
            setOpen(true);
        } else {
            setSeverity('error');
            setText('Vul eerst de URL aan.');
            setOpen(true);
        }
        
    };

    // opening dates view constants
    const [selected, setSelected] = useState('weekdag');
    const openingstijdenSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSelected(e.target.value)
    };
 
    // Alerter constants
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>('info');
    const [text, setText] = useState('')
     
    // Save Event with checkpoints
    const setShop = () => {
        if (eventName !== '' && selectedCategory && urlExtension !== '' && !urlExists && (startWebshop || openNow) && endWebshop) {
            return {
                shop_name: eventName,
                shop_category: ShopCategories.DRIVE_THRU,
                sub_category_id: selectedCategory,
                webshop_url: urlPrefix + urlExtension,
                is_live: false,
                shop_opening_datetime: openNow ? dayjs().utc().format('YYYY-MM-DD HH:mm:ss') : startWebshop?.utc().format('YYYY-MM-DD HH:mm:ss'),
                shop_closing_datetime: endWebshop.utc().format('YYYY-MM-DD HH:mm:ss')
            } as ShopIn
        }
        else {
            setSeverity('warning');
            setText('Vul eerst alle gegevens in evenement en webshop in.');
            setOpen(true);
        }
    }

    const setEvenLocation = (shop_id = '') => {
        if (selectedLocation !== '' && startEvent && endEvent && timeslotDuration && desks) {
            return {
                event_datetime_start: startEvent.utc().format('YYYY-MM-DD HH:mm:ss'),
                event_datetime_end: endEvent.utc().format('YYYY-MM-DD HH:mm:ss'),
                distribution_points: desks,
                timeslot_duration_minutes: timeslotDuration.hour() * 60 + timeslotDuration.minute(),
                shop_id: shop_id !== '' ? shop_id : id,
                location_id: selectedLocation
            } as DriveThruEventLocationIn
        } else {
            return
        }
    }
    const saveVehicles = (id: string) => {
        eventVehicles.forEach((vehicle) => {
            const newVehicle = {
                drive_thru_event_location_id: id,
                drive_thru_event_vehicle: vehicle.vehicle,
                max_vehicles_timeslot: vehicle.amount
            } as DriveThruEventLocationVehicleIn
            if(vehicle.id !== '') {
                apiClient().driveThru.editEventLocationVehicleDriveThruEventLocationVehiclesEventLocationVehicleIdPatch(vehicle.id, newVehicle)
            } else if (vehicle.vehicle !== ''){
                apiClient().driveThru.createEventLocationVehicleDriveThruEventLocationVehiclesPost(newVehicle)
            }
        })
    }

    const saveEventDays = (id: string) => {
        apiClient().driveThru.bulkDeleteEventDaysByEventLocationDriveThruEventDaysBulkEventLocationIdDelete(id).then(() => {
            const days: DriveThruEventDayIn[] = [];
            if (selected === 'weekdag') {
                var currDate = startEvent!;
                while (currDate.isBefore(endEvent!.hour(23).minute(59)) || currDate.isSame(endEvent!)) {
                    const corrWeekday = weekdays.find(weekday => weekday &&  weekday.weekday === weekMapper[currDate.day()])!

                    const newEventDay = {
                        event_date_end_datetime: currDate.utc().hour(corrWeekday.endTime.utc().hour()).minute(corrWeekday.endTime.utc().minute()).format('YYYY-MM-DD HH:mm:ss'),
                        event_date_start_datetime: currDate.utc().hour(corrWeekday.startTime.utc().hour()).minute(corrWeekday.startTime.utc().minute()).format('YYYY-MM-DD HH:mm:ss'),
                        timeslot_duration_minutes: timeslotDuration!.hour() * 60 + timeslotDuration!.minute(),
                        event_location_id: id,
                        is_open: corrWeekday.selected
                    } as DriveThruEventDayIn
                    
                    days.push(newEventDay);
                    currDate = currDate.add(1, 'day');
                }
            } else {
                dates.forEach((day) => {
                    const newEventDay = {
                        event_date_end_datetime: day.endTime.utc().format('YYYY-MM-DD HH:mm:ss'),
                        event_date_start_datetime: day.startTime.utc().format('YYYY-MM-DD HH:mm:ss'),
                        timeslot_duration_minutes: timeslotDuration!.hour() * 60 + timeslotDuration!.minute(),
                        event_location_id: id,
                        is_open: day.selected
                    } as DriveThruEventDayIn
                    days.push(newEventDay);
                });
            }

            apiClient().driveThru.bulkCreateEventDaysDriveThruEventDaysBulkPost(days).then(() => {
                apiClient().driveThru.generateTimeslotsByEventLocationIdDriveThruTimeslotsEventLocationIdGeneratePatch(id);
            })
        })

       
    }
    const saveEvent = () => {
        //save main event data
        let shop = setShop();
        // check if we can save a basic drive-thru shop
        if (shop) {
            // check if we are editing or creating an event
            if (id !== 'undefined') {
                // editing shop and event
                apiClient().shops.editShopByIdShopsShopsShopIdPatch(id, shop).then((r) => {
                    let eventLocation = setEvenLocation();
                    if (eventLocationId !== '' && eventLocation) {
                        apiClient().driveThru.editEventLocationDriveThruEventLocationsEventLocationIdPatch(eventLocationId, eventLocation).then((r) => {
                           saveVehicles(r.id);
                           saveEventDays(r.id);
                        })
                    } else {
                        apiClient().driveThru.createEventLocationDriveThruEventLocationsPost(eventLocation as DriveThruEventLocationIn).then((r) => {
                            saveVehicles(r.id);
                            saveEventDays(r.id);
                        })
                    }
                })
            } else {
                // creating shop and event
                apiClient().shops.createShopShopsShopsPost(shop).then((r) => {
                    let eventLocation = setEvenLocation(r.id);
                    apiClient().driveThru.createEventLocationDriveThruEventLocationsPost(eventLocation as DriveThruEventLocationIn).then((r) => {
                        saveVehicles(r.id);
                        saveEventDays(r.id);
                    })
                    
                    
                })
            }
            setTimeout(() => {navigate('/webshops')}, 1000)
        }
       
    }

    // category constants
    const [categories, setCategories] = useState<ShopSubCategoryOut[]>([]);
    const [newCategory, setNewCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const createNewSubCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        closeModal();
        let subcat = {} as ShopSubCategoryIn;
        subcat.shop_sub_category_name = newCategory;
        apiClient().shops.createShopSubCategoryShopsShopSubCategoriesPost(subcat).then((r) => {
            setSelectedCategory(r.id);
            queryCategories();
            setNewCategory('');
        });

    }
    const queryCategories = () => {
        apiClient().shops.getAllShopSubCategoriesShopsShopSubCategoriesGet().then((r) => {
            let sub_categories:ShopSubCategoryOut[] = [];
            r.items.forEach((sub_category) => {
                sub_categories.push(sub_category);
            })
            setCategories(sub_categories);
        })
    }
    const handleCategoryChange = (event: SelectChangeEvent<string | null>) => {
        if(event.target.value !== undefined) {
            setSelectedCategory(event.target.value as string);
        }
    };
      
    // url constant
    const [urlExtension, setUrlExtension] = useState('');
    const [urlExists, setUrlExists] = useState(false);
    const checkUrl = (extension: string) => {
        apiClient().shops.checkWebshopUrlShopsShopsWebshopUrlPost({webshop_url: urlPrefix + extension}).then(() => {
            setUrlExists(false);
        }).catch((error: ApiError) => {
            if (error.status === 409){
                setUrlExists(true);
            }
        })
        setUrlExtension(extension);
    }   

    // event constants
    const [editing, setEditing] = useState(false);
    const [eventName, setEventName] = useState<string | null>();
    const [eventTitle, setEventTitle] = useState<string | null>();
    const [openNow, setOpenNow] = useState<boolean>(false);

    // date constants
    const [startWebshop, setStartWebshop] = useState<Dayjs | null>(null);
    const [endWebshop, setEndWebshop] = useState<Dayjs | null>(null);

    const [startEvent, setStartEvent] = useState<Dayjs | null>(null);
    const [endEvent, setEndEvent] = useState<Dayjs | null>(null);
    const [error, setError] = useState<DateTimeValidationError | null>(null);

    // locations constants
    const [eventLocationId, setEventLocationId] = useState('');
    const [locations, setLocations] = useState<LocationOut[]>([]);
    const [selectedLocation, setSelectedLocation] = useState('');

    const queryLocations = () => {
        apiClient().userManagement.getAllLocationsUsersLocationsGet().then((r) => {
            let locations:LocationOut[] = []
            r.items.forEach((location) => {
                locations.push(location);
            })
            setLocations(locations);
        });
    };

    const addressToUrl = () => {
        let location = locations.find((location) => location.id === selectedLocation)
        let space = '%20';
        let address = location?.company.delivery_address.street.replaceAll(' ', space);
        return address + space + location?.company.delivery_address.number.toString() + ',' + location?.company.delivery_address.city.toString();
    }

    // desks constants
    const [desks, setDesks] = useState(1);
    const handleDeskChange = (amount: number) => {
        if (amount === 0) {
            amount = 1;
        };
        setDesks(amount);
    };

    //timeslots constants
    const [timeslotDuration, setTimeslotDuration] = useState<Dayjs | null>();

    //vehicles constants 
    type eventVehicle = {
        id: string;
        vehicle: string;
        amount: number;
    };
    const [eventVehicles, setEventVehicles] = useState<eventVehicle[]>([{
        id: '', vehicle: '', amount: 1
    }]);
    const addVehicle = () => {
        setEventVehicles([...eventVehicles, {
            id: '', vehicle: '', amount: 1
        }])
    }
    const removeVehicle = (index: number) => {
        if (eventVehicles[index].id !== '') {
            apiClient().driveThru.deleteEventLocationVehicleByIdDriveThruEventLocationVehiclesEventLocationVehicleIdDelete(eventVehicles[index].id).then((r) => {
                setEventVehicles(eventVehicles.filter((vehicle, i) => i !== index));
            })
        } else {
            setEventVehicles(eventVehicles.filter((vehicle, i) => i !== index));
        }
        
    }
    const handleVehiclesChange = (vehicle: string, i: number) => {
        const newEventVehicles = eventVehicles.map((v, index) => {
            if (index === i) {
                return {...v, vehicle: vehicle};
            }
            return v;
        })
        setEventVehicles(newEventVehicles);
    }
    const handleAmountChange = (amount: number, i: number) => {
        if (amount === 0) {
            amount = 1;
        }
        const newEventVehicles = eventVehicles.map((v, index) => {
            if (index === i) {
                return {...v, amount: amount};
            }
            return v;
        })
        setEventVehicles(newEventVehicles);
    }

    

    //opening times constants
    type weeks = {
        [key: number]: string;
    }
    type weekdayObject = {
        selected: boolean;
        weekday: string;
        startTime: Dayjs;
        endTime: Dayjs;
    }
    type dateObject = {
        selected: boolean;
        date: Dayjs;
        startTime: Dayjs;
        endTime: Dayjs;
    }
    const weekMapper: weeks = {
        0: 'Zondag',
        1: 'Maandag',
        2: 'Dinsdag',
        3: 'Woensdag',
        4: 'Donderdag',
        5: 'Vrijdag',
        6: 'Zaterdag',
    }

    const [weekdays, setWeekdays] = useState<weekdayObject[]>([]);
    const [dates, setDates] = useState<dateObject[]>([]);
    const [eventDays, setEventDays] = useState<DriveThruEventDayOut[]>([])

    useEffect(() => {
        if (timeslotDuration && weekdays && dates) {
            let works = true;
            if (selected === 'weekdays') {
                weekdays.filter(w => w.selected).forEach(w => {
                    let totalTime = w.endTime.valueOf() - w.startTime.valueOf();
                    let timeslotMs = ((timeslotDuration.hour() * 60) + timeslotDuration.minute()) * 60 * 1000;
                    works = totalTime % timeslotMs === 0;
                })
            } else {
                dates.filter(d => d.selected).forEach(d => {
                    let totalTime = d.endTime.valueOf() - d.startTime.valueOf();
                    let timeslotMs = ((timeslotDuration.hour() * 60) + timeslotDuration.minute()) * 60 * 1000;
                    works = totalTime % timeslotMs === 0;
                })
            }

            if (!works) {
                setSeverity('warning');
                setText('Let op, door de duur van je tijdslot eindigt het laatste tijdslot eerder dan je opgegeven sluitingstijd. Pas de duur van je tijdslot of je sluitingstijd aan. Je kunt ook niets doen, dan houden we de eindtijd van het laatste tijdslot aan.')
                setAlertLength(10000);
                setOpen(true);
            }
        }
        
    }, [selected, timeslotDuration, weekdays, dates])

    useEffect(() => {
        if (startEvent && endEvent) {
                let currDate = startEvent;
                let newWeekdays:weekdayObject[] = [];
                let newDates:dateObject[] = [];
                while (currDate.isBefore(endEvent, 'day') || currDate.isSame(endEvent, 'day')) {
                    if (weekdays.length === 0) {
                        newDates.push({
                            selected: true,
                            date: currDate,
                            startTime: currDate.hour(9).minute(0),
                            endTime: currDate.hour(18).minute(0)
                        } as dateObject)
                        newWeekdays.some(weekday => weekday.weekday === weekMapper[currDate.day()]) ? newWeekdays = newWeekdays : newWeekdays[currDate.day() === 0 ? 7 : currDate.day()] = ({
                            selected: true,
                            weekday: weekMapper[currDate.day()],
                            startTime: currDate.hour(9).minute(0),
                            endTime: currDate.hour(18).minute(0)
                        } as weekdayObject)
                    }
                    else {
                        dates.some(date => date.date.startOf('day').isSame(currDate.startOf('day'))) ? newDates.push(
                            dates.find(date => date.date.startOf('day').isSame(currDate.startOf('day'))) as dateObject
                        ) : 
                        newDates.push({
                            selected: true,
                            date: currDate,
                            startTime: currDate.hour(9).minute(0),
                            endTime: currDate.hour(18).minute(0)
                        } as dateObject)

                        newWeekdays.some(weekday => weekday.weekday === weekMapper[currDate.day()]) ? newWeekdays = newWeekdays 
                        : 
                        newWeekdays[currDate.day() === 0 ? 7 : currDate.day()] =({
                            selected: true,
                            weekday: weekMapper[currDate.day()],
                            startTime: currDate.hour(9).minute(0),
                            endTime: currDate.hour(18).minute(0)
                        } as weekdayObject)
                    }
                    currDate = currDate.add(1, 'day');
                }
                
                setWeekdays(newWeekdays);
                setDates(newDates);
        }
    }, [startEvent, endEvent])

    const handleSelectedDate = (i: number) => {
        if (selected === 'weekdag') {
            const newWeekdays = weekdays.map((w, index) => {
                if (index === i) {
                    return {...w, selected: !w.selected};
                }
                return w;
            })
            setWeekdays(newWeekdays);
        } else {
            const newDates = dates.map((d, index) => {
                if (index === i) {
                    return {...d, selected: !d.selected};
                }
                return d;
            })
            setDates(newDates);
        }
    }
    const handleTimeDate = (i: number, start: boolean, time: Dayjs | null) => {
        if (selected === 'weekdag') {
            const newWeekdays = weekdays.map((w, index) => {
                if (index === i) {
                    if (start) {
                        return {...w, startTime: time as Dayjs}
                    } else {
                        // if (w.endTime.isSame(endEvent, 'day')) {
                        //     setEndEvent(prev => prev!.hour(time!.hour()).minute(time!.minute()));
                        // }
                        return {...w, endTime: time as Dayjs}
                    }
                }
                return w;
            })
            setWeekdays(newWeekdays);
        } else {
            const newDates = dates.map((d, index) => {
                if (index === i) {
                    if (start) {
                        return {...d, startTime: time as Dayjs}
                    } else {
                        // if (d.endTime.isSame(endEvent, 'day')) {
                        //     setEndEvent(endEvent!.hour(time!.hour()).minute(time!.minute()))
                        // }
                        return {...d, endTime: time as Dayjs}
                    }
                }
                return d;
            })
            setDates(newDates);
        }
    }
    // check for new event or edit event

    useEffect(() => {
        // get everytime
        queryCategories();
        queryLocations();

        // only get information when we are editing
        if(id !== "undefined") {
            setEditing(true);
            apiClient().shops.getShopByIdShopsShopsShopIdGet(id).then((r) => {
                setEventName(r.shop_name);
                setEventTitle(r.shop_name);
                setSelectedCategory(r.sub_category_id)
                setUrlExtension(r.webshop_url.substring(urlPrefix.length));
                setStartWebshop(dayjs.utc(r.shop_opening_datetime).tz('Europe/Amsterdam'))
                setEndWebshop(dayjs.utc(r.shop_closing_datetime).tz('Europe/Amsterdam'))

                apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsShopShopIdGet(id).then((r) => {
                    setDesks(r.distribution_points)
                    setStartEvent(dayjs.utc(r.event_datetime_start))
                    setEndEvent(dayjs.utc(r.event_datetime_end))
                    let duration = null;
                    if (r.timeslot_duration_minutes <= 59) {
                        duration = dayjs().hour(0).minute(r.timeslot_duration_minutes)
                    } else {
                        let djs = dayjs().hour(Math.floor(r.timeslot_duration_minutes / 60)).minute(0);
                        let mod = r.timeslot_duration_minutes % 60;
                        if (mod === 0) {
                            duration = djs
                        } else {
                            duration = djs.minute(mod)
                        }
                    }
            
                    setTimeslotDuration(duration)
                    setSelectedLocation(r.location.id!)
                    setEventLocationId(r.id)

                    apiClient().driveThru.getAllEventLocationVehiclesByEventLocationIdDriveThruEventLocationsEventLocationIdEventLocationVehiclesGet(r.id).then((r) => {
                        const vehicles: eventVehicle[] = []
                        r.items.forEach((vehicle) => {
                            vehicles.push({
                                id: vehicle.id,
                                vehicle: vehicle.drive_thru_event_vehicle,
                                amount: vehicle.max_vehicles_timeslot
                            } as eventVehicle)
                        })
                        if (vehicles.length !== 0) {
                            setEventVehicles(vehicles)
                        }
                        
                    })
                    apiClient().driveThru.getAllEventDaysByEventLocationIdDriveThruEventLocationsEventLocationIdEventDaysGet(r.id).then((r) => {
                        const days: DriveThruEventDayOut[] = [];
                        var newWeekdays:weekdayObject[] = [];
                        const newDates:dateObject[] = [];
                        if (r.items.length > 0) {
                            r.items.forEach((day) => {
                                days.push(day);
                                newDates.push({
                                    selected: day.is_open,
                                    date: dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam'),
                                    startTime: dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam'),
                                    endTime: dayjs.utc(day.event_date_end_datetime).tz('Europe/Amsterdam')
                                } as dateObject)
                                newWeekdays.some(weekday => weekday.weekday === weekMapper[dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam').day()]) ? newWeekdays = newWeekdays : newWeekdays.push({
                                    selected: day.is_open,
                                    weekday: weekMapper[dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam').day()],
                                    startTime: dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam'),
                                    endTime: dayjs.utc(day.event_date_end_datetime).tz('Europe/Amsterdam')
                                } as weekdayObject)
                            });
                            setEventDays(days);
                            setWeekdays(newWeekdays);
                            setDates(newDates);
                        }
                        
                    })
                })

                
            }).catch((error) => {
                navigate('/pagina-niet-gevonden');
                console.log(error);
            });
        } else {
            setEditing(false);
        }
    }, [])

    // Modal functionality
    const style = {
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
      };

    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setOpenModal(false)
    };
    const modalOpen = () => {
        setOpenModal(true)
    };

    return (
        <>
        <Alerter
            open={open}
            setOpen={setOpen}
            severity={severity}
            text={text}
            length={alertLength}
        />
        <ThemeProvider theme={theme}>
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="Create new subcategory"
            aria-describedby="Create new subcategory popup"
            >
            <Box sx={style}>
                <Box sx={{height: '64px', display: 'flex'}}>
                    <IconButton onClick={closeModal} sx={{ml: 'auto', height: '40px'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', p: '16px', gap: '16px', alignItems: 'center'}}>
                    <Typography variant="titleLarge">
                        Maak nieuwe drive-thru categorie aan:
                    </Typography>
                    <form onSubmit={(e) => {createNewSubCategory(e)}} style={{display: 'flex', flexDirection: 'column', gap: '16px', width: '80%'}}>
                        <TextField required onChange={(e) => {setNewCategory(e.target.value)}} label="Nieuwe categorie" variant="outlined" sx={{width: '100%', mx: 'auto', mt: '16px'}}/>
                        <Button type="submit" sx={{mb: '32px', backgroundColor: 'secondary.main', color: '#fff', borderRadius: '20px', height: '40px', width: '100%' }}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Categorie aanmaken    
                            </Typography> 
                        </Button>
                    </form>
                </Box>
                
            </Box>
            </Modal>
            {/* Header with buttons */}
            <Box sx={{display: 'flex', height: '100%', flexDirection: 'column'}}>
                <Box sx={{py: '32px', px: '32px', display: 'flex', justifyContent: 'space-between'}}>
                    {
                        editing ? 
                        <Typography variant="headlineLarge">
                            {eventTitle} bewerken   
                        </Typography> :
                        <Typography variant="headlineLarge">
                        Drive-Thru event toevoegen   
                        </Typography>
                    }   
                    <Typography onClick={() => {navigate('/webshops')}} sx={{cursor: 'pointer',alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                        Annuleren 
                    </Typography> 
                    <Button sx={{display: editing ? 'inline' : 'none',mx: '8px', color: 'secondary.main', borderRadius: '20px', borderColor: 'secondary.main', borderWidth: '1px', border: 1}}>
                        <Typography variant="labelLarge" sx={{px: '12px'}}>
                        Preview event webshop   
                        </Typography> 
                    </Button>
                    <Button onClick={() => {saveEvent()}} disabled={error || urlExists ? true : false} sx={{ml: '8px', backgroundColor: 'secondary.main', color: '#fff', borderRadius: '20px' }}>
                        <Typography variant="labelLarge" sx={{px: '12px', color: '#fff'}}>
                            Event opslaan    
                        </Typography> 
                    </Button>
                </Box>
                <Divider />
                <Box sx={{p: '32px', display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%'}}>
                    {/* Algemeen */}
                    <FormControl>
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', flexDirection: 'column', width: '50%'}}>
                                <Typography variant="titleLarge">
                                    Evenement   
                                </Typography> 
                                {
                                    eventTitle || !editing ? 
                                    <TextField defaultValue={eventName ? eventName : ''} onBlur={(e) => {setEventName(e.target.value)}} required label="Naam evenement" variant="outlined" sx={{width: '100%', mt: '16px' }}/>
                                    :
                                    <Skeleton variant="text" width='100%' height={60} />
                                }
                                <Typography variant="bodySmall" sx={{my: '16px', fontStyle: 'italic'}}>
                                    De naam van het evenement zal ook worden weergeven op je webshop.
                                </Typography>  
                                <Select
                                    displayEmpty
                                    required
                                    onChange={(e) => {handleCategoryChange(e)}}
                                    value={selectedCategory}
                                    defaultValue=''
                                    sx={{width: '100%', mt: '16px'}}
                                >
                                    <MenuItem value='' disabled>
                                        <Typography variant="labelLarge" sx={{my: 'auto'}}>
                                            Categorieën   
                                        </Typography> 
                                    </MenuItem>
                                    {
                                        categories.map((category) => 
                                        <MenuItem value={category.id} key={category.id}>
                                            <Typography variant='labelLarge'>{category.shop_sub_category_name}</Typography>
                                        </MenuItem>
                                        )
                                    }
                                    <MenuItem sx={{display: 'flex', alignItems: 'center', '&.Mui-selected': {backgroundColor: '#fff'}}}>
                            
                                        <IconButton onClick={() => {modalOpen()}} sx={{ml: 'auto', width: '40px', height: '40px'}}>
                                            <AddOutlinedIcon />
                                        </IconButton>
                                    </MenuItem>

                                
                                </Select>
                            </Paper>
                            <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', flexDirection: 'column', width: '50%', ml: '16px'}}>
                                <Typography variant="titleLarge">
                                    Webshop  
                                </Typography>
                                {
                                    eventTitle || !editing ?
                                    <Box>
                                        
                                        <TextField 
                                        required
                                        label="Webshop URL" 
                                        defaultValue={urlExtension}
                                        error={urlExists}
                                        variant="outlined" 
                                        onBlur={(e) => {checkUrl(e.target.value)}}
                                        sx={{width: '100%', mt: '16px' }} 
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={{mr: 0}} position="start">{urlPrefix}</InputAdornment>,
                                            endAdornment: <InputAdornment sx={{mr: 0}} position="end">
                                                <IconButton
                                                aria-label="Copy url"
                                                onClick={copyURL}
                                                edge="end"
                                                >
                                                <ContentCopyOutlinedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    
                                        />
                                        <FormHelperText error={true} sx={{mx: 'auto', visibility: urlExists ? 'visible' : 'hidden'}}>
                                            <Typography variant="labelMedium">
                                                Deze URL bestaat al.
                                            </Typography>
                                        </FormHelperText>
                                        <Typography variant="bodyMedium" sx={{my: '16px'}}>
                                         Vul eerst de URL aan met een naam naar keuze. Kopieer de URL daarna en voeg deze toe aan jouw eigen website. Op die manier weten je klanten jouw drive-thru te vinden!
                                        </Typography>  
                                    </Box>
                                    :
                                    <Skeleton variant="text" width='100%' height={60} />
                                }
                                
                               
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                            
                                            <DateTimePicker disabled={openNow} onError={(newError: DateTimeValidationError | null) => setError(newError)} value={startWebshop ?? null} maxDateTime={endWebshop ? endWebshop : undefined} onChange={(date: Dayjs | null) => {setStartWebshop(date)}} disablePast format="DD/MM/YYYY HH:mm" ampm={false} sx={{width: '50%', mr: '16px', mt: '16px'}} label="Openingsdatum webshop" />
                                            <DateTimePicker onError={(newError: DateTimeValidationError | null) => setError(newError)} value={endWebshop ?? null} maxDateTime={endEvent ? endEvent.hour(23) : undefined} minDateTime={startWebshop ? startWebshop : undefined} onChange={(date: Dayjs | null) => {setEndWebshop(date)}} disablePast format="DD/MM/YYYY HH:mm" ampm={false} sx={{width: '50%', mt: '16px'}} label="Sluitingsdatum webshop" />
                                        </Box>
                                    </LocalizationProvider>
                                </Box>
                                <FormControlLabel sx={{ mr: '8px'}} control={<Checkbox onChange={() => {setOpenNow(!openNow)}} checked={openNow} />} label="Open nu" />
                            </Paper>
                        </Box>
                    </FormControl>
                    {/* Locaties */}
                    <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', flexDirection: 'column', my: '16px'}}>
                        <Typography variant="titleLarge">
                            Locatie   
                        </Typography>
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <Box sx={{width: '65%', display: 'flex', flexDirection: 'column'}}>
                                <Select
                                    displayEmpty
                                    onChange={(e) => {setSelectedLocation(e.target.value)}}
                                    value={selectedLocation}
                                    sx={{width: '100%', my: '16px'}}
                                >
                                    <MenuItem disabled value='' key=''>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            <PlaceOutlinedIcon />
                                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '8px'}}>
                                                Afhaal locatie   
                                            </Typography> 
                                        </Box>
                                    </MenuItem>
                                    {
                                        locations.map((location) => 
                                        <MenuItem value={location.id} key={location.id}>
                                            <Typography variant="labelLarge">
                                                {location.name}
                                            </Typography>
                                        </MenuItem>
                                        )
                                    }
                                </Select>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{width: '100%', display: 'flex'}}>
                                    <DatePicker onError={(newError: DateTimeValidationError | null) => setError(newError)} value={startEvent ?? null} minDate={startWebshop ? startWebshop : undefined} maxDate={endEvent ? endEvent : undefined} onAccept={(date: Dayjs | null) => {setStartEvent(date)}} disablePast format="DD/MM/YYYY" sx={{width: '50%', mr: '16px', mb: '16px'}} label="Startdatum evenement"/>
                                    <DatePicker onError={(newError: DateTimeValidationError | null) => setError(newError)} value={endEvent ?? null} minDate={(startEvent ? (endWebshop ? dayjs.max(startEvent, endWebshop)! : startEvent) : (endWebshop ? endWebshop : undefined))} maxDate={startEvent ? startEvent.add(1, 'month') : undefined} onAccept={(date: Dayjs | null) => {setEndEvent(date!)}} disablePast format="DD/MM/YYYY" sx={{width: '50%', mb: '16px'}} label="Einddatum evenement" />
                                    </Box>
                                    <TimePicker minutesStep={1} value={timeslotDuration ?? null} onChange={(time: Dayjs | null) => {setTimeslotDuration(time)}} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Duur tijdslot" />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{width: '35%', ml: '16px', pt: '16px', display: 'flex'}}>
                                {
                                    selectedLocation ?
                                    <iframe
                                    title="Event locatie"
                                    height={'100%'}
                                    width={'100%'}
                                    style={{borderRadius: '12px', border: 0}}
                                    loading="lazy"
                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                        &q=${addressToUrl()}`}
                                    >
                                    </iframe>
                                    :
                                    <Typography variant="titleSmall" sx={{mx: 'auto', alignSelf: 'center'}}>
                                        Selecteer een afhaal locatie
                                    </Typography>
                                }
                                
                            </Box>
                        </Box> 
                        <Box sx={{display: 'flex'}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                                    <Typography variant="labelLarge" sx={{my: '32px', fontWeight: 'bold'}}>
                                        Openingstijden
                                    </Typography>
                                    <Box sx={{display: 'flex', flexDirection:'row', alignItems: 'center'}}>
                                        <FormLabel sx={{mr: '8px'}}>Aangegeven per:</FormLabel>
                                        <RadioGroup
                                            row
                                            defaultValue={selected}
                                            name="openingstijden-select"
                                            onChange={(event) => {openingstijdenSelect(event)}}
                                        >
                                            <FormControlLabel value="weekdag" control={<Radio />} label="Weekdag" />
                                            <FormControlLabel value="datum" control={<Radio />} label="Datum" />
                                        </RadioGroup>
                                    </Box>
                                    <Box sx={{height: '55vh', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                                        {
                                            startEvent && endEvent ?
                                                selected === 'weekdag' ? 
                                                    weekdays.map((day, i) => 
                                                        <Box key={i} sx={{width: '100%', display: 'flex', my: '8px'}}>
                                                            <FormControlLabel checked={day.selected} onChange={() => {handleSelectedDate(i)}} control={<Checkbox />} label={day.weekday} />
                                                            <LocalizationProvider key={day.weekday} dateAdapter={AdapterDayjs}>
                                                                <TimePicker maxTime={day.endTime} onChange={(time: Dayjs | null) => {handleTimeDate(i, true, time)}} sx={{ml: 'auto', width: '33%'}} value={day.startTime} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open van" />
                                                                <TimePicker minTime={day.startTime} onChange={(time: Dayjs | null) => {handleTimeDate(i, false, time)}} sx={{ml: '16px',width: '33%'}} value={day.endTime} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open tot" />
                                                            </LocalizationProvider>
                                                        </Box> 
                                                    ) :
                                                    dates.map((date, i) => 
                                                        <Box key={i} sx={{width: '100%', display: 'flex', my: '8px'}}>
                                                            <FormControlLabel checked={date.selected} onChange={() => {handleSelectedDate(i)}} control={<Checkbox />} label={date.date.format('DD-MM-YYYY')} />
                                                            <LocalizationProvider key={date.date.toString()} dateAdapter={AdapterDayjs}>
                                                                <TimePicker maxTime={date.endTime} onChange={(time: Dayjs | null) => {handleTimeDate(i, true, time)}} sx={{ml: 'auto', width: '33%'}} value={date.startTime} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open van" />
                                                                <TimePicker minTime={date.startTime} onChange={(time: Dayjs | null) => {handleTimeDate(i, false, time)}} sx={{ml: '16px',width: '33%'}} value={date.endTime} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open tot" />
                                                            </LocalizationProvider>
                                                        </Box> 
                                                    )
                                            :
                                                <Typography variant="bodyLarge" sx={{my: 'auto', mx: 'auto'}}>
                                                    Selecteer een start- en einddatum voor het evenement.
                                                </Typography>
                                        }
                                        
                                    </Box>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', ml: '32px', overflowY: 'auto', overflowX: 'clip', maxHeight: '80vh'}}>
                                    <Typography variant="labelLarge" sx={{my: '32px', fontWeight: 'bold'}}>
                                        Aantal uitgiftepunten
                                    </Typography>
                                    <TextField
                                        id="outlined-number"
                                        label="Aantal uitgiftenpunten"
                                        type="Number"
                                        value={desks}
                                        onChange={(e) => {handleDeskChange(+e.target.value)}}
                                        InputProps={{ inputProps: { min: 1 } }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{my: '16px'}}
                                    /> 
                                    <Typography sx={{fontWeight: 'bold'}} variant="labelLarge">
                                        Voertuigen
                                    </Typography>
                                    <Box sx={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                                        {eventVehicles.map((e, i) => 
                                            <Box key={e.id} sx={{display: 'flex', width: '100%', gap: '16px'}}>
                                                    <Select
                                                        displayEmpty
                                                        onChange={(e) => {handleVehiclesChange(e.target.value, i)}}
                                                        value={eventVehicles[i].vehicle}
                                                        sx={{width: '100%', my: '16px'}}
                                                    >
                                                        <MenuItem disabled value='' key=''>
                                                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '8px'}}>
                                                                Voertuig 
                                                            </Typography> 
                                                        </MenuItem>
                                                        {
                                                        Object.values(EventVehicles).map((key, index) => 
                                                            <MenuItem disabled={eventVehicles.some(vehicle => vehicle.vehicle === key) ? true : false} value={key} key={key}>
                                                                <Typography variant="labelLarge">
                                                                    {key}
                                                                </Typography>
                                                            </MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                    <TextField
                                                    id="outlined-number"
                                                    label="Aantal per tijdslot"
                                                    type="Number"
                                                    value={(eventVehicles[i].amount)}
                                                    onChange={(e) => {handleAmountChange(+e.target.value, i)}}
                                                    inputProps={{ min: 1}}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{my: '16px', width: '100%'}}
                                                /> 
                                                {eventVehicles.length > 1 ? 
                                                    <IconButton onClick={() => {removeVehicle(i)}} sx={{my: 'auto', width: '40px', height: '40px'}}>
                                                        <ClearOutlinedIcon />
                                                    </IconButton> :
                                                    <></>
                                                 }
                                            </Box>
                                            
                                        )}
                                        {eventVehicles.length < Object.keys(EventVehicles).length ? 
                                        <IconButton onClick={addVehicle} sx={{ml: 'auto', width: '40px', height: '40px', mb: '8px', backgroundColor: 'secondary.main', color: 'white'}}>
                                            <AddOutlinedIcon />
                                        </IconButton> :
                                        <></>
                                    }
                                    </Box>
                                    {/* <Typography sx={{fontWeight: 'bold'}} variant="labelLarge">
                                        Berekende capaciteit 
                                    </Typography> 
                                    <Typography sx={{my: '16px'}} variant="bodyMedium">
                                        Van [{startEvent ? startEvent.format('DD-MM-YYYY') : 'X'}] tot en met [{endEvent ? endEvent.format('DD-MM-YYYY') : 'X'}] kunnen er dagelijks tussen [09:00] en [18:00] uur [X] [Voertuig], [X] [Voertuig] en [X] [Voertuig] per [00:30] uur bediend worden. Dit vanuit [X] uitgiftepunt(en) op locatie [X]. 
                                    </Typography> 
                                    <Typography variant="bodyMedium">
                                        Totaal zijn dit [X] [Voertuigen], [X] [Voertuigen] en [X] [Voertuigen] per dag, die door jou vrij te verdelen zijn over de [X] uitgiftepunt(en). Bij maximale capaciteit zijn dit [X] klanten per dag.
                                    </Typography> */}
                                </Box>
                            </Box>
                            {/* TODO
                            <Button sx={{my: '16px', ml: 'auto', color: '#FFA329', borderRadius: '20px', borderColor: '#FFA239', borderWidth: '1px', border: 1, width: '250px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Nog een locatie toevoegen
                                </Typography> 
                            </Button> */}
                    </Paper>
                    {/* Artikelen */}
                    <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', flexDirection: 'column', mb: '1px'}}>
                        <Typography variant="titleLarge">
                            Artikelen   
                        </Typography> 
                        <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                            endAdornment: (
                            <Box component="div" sx={{ display: 'flex' }}>
                                <IconButton color='inherit'>
                                <TuneOutlinedIcon />
                                </IconButton>
                            </Box>

                            ),
                        }}
                        id="search-bar"
                        onInput={(e) => {
                            console.log("Zoek de zeep");
                        } }
                        variant="outlined"
                        placeholder="Zoek..."
                        size="small"
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3'
                            },
                            width:'521px',
                            my: '16px'
                        }} />
                        <Box sx={{display: 'flex', height: '64px', p: '8px', width: '100%'}}>
                            <img src="" alt="img" style={{height: '56px', width: '56px'}}/>
                            <Box sx={{display: 'flex', flexDirection: 'column', ml: '8px'}}>
                                <Typography variant="bodyLarge">
                                    Oliebollen   
                                </Typography> 
                                <Typography variant="bodyMedium">
                                    Per 5 | Per 10  
                                </Typography> 
                            </Box>
                            <IconButton sx={{ml: 'auto'}}>
                                <ArrowForwardIosOutlinedIcon />
                            </IconButton>
                        </Box>
                        <Divider />
                        <Box sx={{display: 'flex', height: '64px', p: '8px', width: '100%'}}>
                            <img src="" alt="img" style={{height: '56px', width: '56px'}}/>
                            <Box sx={{display: 'flex', flexDirection: 'column', ml: '8px'}}>
                                <Typography variant="bodyLarge">
                                    Oliebollen   
                                </Typography> 
                                <Typography variant="bodyMedium">
                                    Per 5 | Per 10  
                                </Typography> 
                            </Box>
                            <IconButton sx={{ml: 'auto'}}>
                                <ArrowForwardIosOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
        </>
        
    );
}


import { Backdrop, Box, Fade, IconButton, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {ReactComponent as MollieConnect} from "../../../images/MollieConnect.svg"
import { apiClient } from "../../../functions/Login";
import ButtonText from "../../buttons/ButtonText";
import MollieExtra from "./MollieExtra";

interface MollieProps {
    open: Signal<boolean>
    company_id: string | null | undefined
}

const Mollie: React.FC<MollieProps> = ({open, company_id}) => {
    useSignals();

    const openMollieExtra: Signal<boolean> = useSignal(false);

    const connectMollie = () => {
        apiClient().payment.createPaymentOrganizationPaymentPaymentOrganizationsPost({company_id: company_id!}).then((r) => {
            apiClient().payment.getPaymentOrganizationAuthUrlPaymentPaymentOrganizationsPaymentOrganizationIdAuthUrlGet(r.id).then((r) => {
                window.location.href = r
            })
        })
    }
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    const webshops = [
        'Standaard webshop',
        'Brood abonnement webshop',
        'Drive-thru webshop(s)',
        'Zakelijke webshop(s)',
        'Filiaal webshop(s)'
    ]
    return (
        <Modal
            aria-labelledby="connect-mollie-modal"
            aria-describedby="Modal to connect mollie"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <MollieExtra open={openMollieExtra}/>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false;}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineMedium'>
                            Je staat op het punt jouw Mollie account te koppelen
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Wanneer we jouw Mollie account koppelen maken we direct vijf Mollie profielen voor je aan. Dit brengt geen kosten met zich mee en helpt jou om inzicht te krijgen in de ontvangen betalingen per webshop categorie. 
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant='bodyLarge'>
                                De profielen die we voor je aanmaken zijn:
                            </Typography>
                            <Typography variant='bodyLarge' component={'ul'} sx={{listStylePosition: 'inside'}}>
                                {
                                    webshops.map((webshop) =>
                                        <Typography variant='bodyLarge' component={'li'}>
                                            {webshop}
                                        </Typography>
                                    )
                                   
                                }
                            </Typography>
                        </Box>
                        <Typography variant='bodyLarge'>
                            Mollie doet er tot wel 10 werkdagen over om de profielen te verifiëren. Om te voorkomen dat jij in de toekomst opnieuw moet wachten op de profielverificatie, maken wij deze op voorhand voor iedere webshopcategorie aan. Ook wanneer je misschien nog geen gebruik maakt van deze webshops.
                        </Typography>

                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center'}}>
                            <MollieConnect style={{cursor: 'pointer', mx: 'auto'}} onClick={connectMollie}/>
                            <ButtonText text='Nog geen mollie account?' onClick={() => {openMollieExtra.value = true}} margin='0' />
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default Mollie;
import {Box, SvgIconTypeMap, ThemeProvider, Typography } from '@mui/material';
import theme from '../../Theme';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import { OverridableComponent } from '@mui/material/OverridableComponent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface OrderHistoryTabProps {
  date?: string;
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const OrderHistoryTab: React.FC<OrderHistoryTabProps> = ({ date, title, Icon }) => {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);

    return (
        <ThemeProvider theme={theme}>
           <Box sx={{display: 'flex', gap: '16px'}}>
               <Box sx={{display: 'flex', minWidth: '55px', maxWidth: '55px', height: '55px', backgroundColor: '#00668933', borderRadius: '50px', justifyContent: 'center', alignItems: 'center'}}>
                    <Icon preserveAspectRatio="none" sx={{color: '#006689', width: '60%', height: '60%'}}/>
               </Box>
               {
                    date ? 
                    <Box sx={{display: 'flex', width: '100%'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant='bodyMedium' sx={{fontWeight: '700'}}>
                                {title}
                            </Typography>
                            <Typography variant='bodyMedium'>
                                {dayjs.utc(date).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm')}
                            </Typography>
                        </Box>
                        <CheckCircleOutlineOutlinedIcon sx={{color: '#00C853', ml: 'auto'}}/>

                    </Box>
                    :
                    <Box sx={{display: 'flex', width: '100%'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant='bodyMedium' sx={{fontWeight: '700'}}>
                                {title}
                            </Typography>
                            <Typography variant='bodyMedium'>
                                -
                            </Typography>
                        </Box>
                        <CircleOutlinedIcon sx={{ml: 'auto'}}/>
                    </Box>
                }
           </Box>
        </ThemeProvider>
    );
};

export default OrderHistoryTab;
import { Box, IconButton, ThemeProvider, Typography } from "@mui/material";
import theme from "../../../Theme";
import Card from "../../../components/layout/Card";
import Spaced from "../../../components/layout/Spaced";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ButtonText from "../../../components/buttons/ButtonText";
import ButtonOutlined from "../../../components/buttons/ButtonOutlined";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ButtonFull from "../../../components/buttons/ButtonFull";
import AddOutlined from "@mui/icons-material/AddOutlined";
import Split from "../../../components/layout/Split";

export default function DataDetails() {

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Card title='Debiteur' button={<ButtonText text='Wijzig debiteur gegevens' onClick={() => {}}/>} children={
                    <>
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <BusinessOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Bedrijfsnaam:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    Loaf IT
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <WorkOutlineOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        KVK-nummer:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    12345678
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <AccountBalanceOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        btw-nummer:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    NL123456789BO1
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <EuroSymbolOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Facturatie emailadres:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    facturatie@loaf-it.com
                                </Typography>
                            </>
                        }
                    />
                    </>    
                }
                />
                <Card title='Contactgegevens' button={<ButtonOutlined text='Wijzig contactgegevens' onClick={() => {}} Icon={EditOutlinedIcon} />} children={
                    <>
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <EmailOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Emailadres:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    info@loaf-it.com
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <LocalPhoneOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Telefoonnummer algemeen:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    030 303 1234
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <PersonOutlineOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Primaire contactpersoon:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    Bakker Piet
                                </Typography>
                            </>
                        }
                    />
                    <Spaced maxWidth="50%" children={
                            <>
                                <Box sx={{fontWeight: '700', display: 'flex', gap: '4px'}}>
                                    <PhoneAndroidOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        Telefoonnummer contactpersoon:
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    +316 123 456 78
                                </Typography>
                            </>
                        }
                    />
                    </>    
                }
                />
                <Card title='Hoofdvestiging' button={<IconButton onClick={() => {}}><EditOutlinedIcon/></IconButton>} children={
                    <Split left={
                        <Box sx={{display: 'flex', gap: '8px'}}>
                        <RoomOutlinedIcon />
                        <Spaced orientation="column" children={
                            <>
                            <Typography variant="bodyLarge">
                                Nieuwe Schaft 25
                            </Typography>
                            <Typography variant="bodyLarge">
                                3991 AS
                            </Typography>
                            <Typography variant="bodyLarge">
                                Houten
                            </Typography>
                            </>
                        }
                        />
                        </Box>
                    } 
                    right={
                        <iframe
                            title="Hoofdvestiging locatie"
                            height={'250px'}
                            width={'100%'}
                            style={{borderRadius: '12px', border: 0}}
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                &q=Nieuwe%20Schaft%2025}`}
                        >
                        </iframe>
                    }
                    />   
                }
                />
                <Card title='Bestel accounts' button={
                    <>
                        <ButtonOutlined Icon={OpenInNewOutlinedIcon} text="Bekijk webshop" onClick={() => {}}/>
                        <ButtonFull Icon={AddOutlined} text='Bestelaccount toevoegen' onClick={() => {}} />
                    </>
                } children={
                    <>
                    </>    
                }
                />
            </Box>
        </ThemeProvider>
    )
}
import { Box, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import Card from "../../components/layout/Card";
import StatsOverview from "../../components/StatsOverview";
import ButtonBack from "../../components/buttons/ButtonBack";
import ButtonFull from "../../components/buttons/ButtonFull";

export default function ConsumerDetails() {

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', mr: '16px'}}>
                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                    <ButtonBack />

                    <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                        Jeroen Groot    
                    </Typography> 

                    <ButtonFull onClick={() => {}} Icon={QuestionAnswerOutlinedIcon} text='Bericht klant'/>
                </Box>

                <Card title='Algemene gegevens' orientation='row' children={
                    <>
                        <StatsOverview title='Orders' value={3} Icon={ReceiptOutlinedIcon}  />
                        <StatsOverview title='Omzet' value={'84.00'} iconOnValue={true} Icon={EuroSymbolOutlinedIcon}  />
                        <StatsOverview title='Laatste order' value={'20-03-2024'} Icon={AccessTimeOutlinedIcon}  />
                    </>    
                }
                />
                <Card title='Orders' children={
                    <>
                    </>    
                }
                />
                <Card title='Facturen' children={
                    <>
                    </>    
                }
                />
            </Box>
        </ThemeProvider>
    )
}
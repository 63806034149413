import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import WebshopIcon from "./WebshopIcon";
import { apiClient } from "../../functions/Login";

interface OrderMailProps {
    open: Signal<boolean>;
    orders: {
        orderNumber: string,
        customerEmail: string,
        webshop: string
    }[]
}

const OrderMail: React.FC<OrderMailProps> = ({ open, orders }) => {
    useSignals()

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        p: 4,
    };

    const sendMails = () => {
        orders.forEach((order) => {
            apiClient().mailing.sendDriveThruQrEmailMailingSendDriveThruQrEmailOrderEmailPost(order.customerEmail).then((r) => {
                open.value = false;
            })
        })
    }

    return (
        <Modal
            open={open.value}
            onClose={() => {open.value = false}}
            aria-labelledby="Annuleer order"
        >
            <Box sx={style}>
                <Box sx={{display: 'flex'}}>
                    <IconButton onClick={() => {open.value = false}} sx={{ml: 'auto', height: '40px'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box sx={{px: '16px', pb: '16px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    {
                        orders.length > 1 ?
                            <Typography variant='headlineLarge' sx={{textAlign: 'center'}}>
                                Wil je {orders.length} bestelbevestigingen opnieuw versturen?
                            </Typography>
                            
                        :
                            <Typography variant='headlineLarge' sx={{textAlign: 'center'}}>
                                Wil je de bestelbevestiging opnieuw versturen?
                            </Typography>
                    }

                    <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
                        De bevestiging wordt opnieuw verzonden, controleer voor je op verzenden drukt goed of het emailadres correct is.
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', maxheight: '400px', overflowY: 'auto'}}>
                        {
                            orders.map((order) => {
                                return (
                                    <Box key={order.webshop + order.orderNumber} sx={{display: 'flex', border: '1px solid #D5D8DC', borderRadius: '8px', gap: '16px', p: '16px'}}>
                                        <WebshopIcon webshop={order.webshop} />
                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>
                                            {order.customerEmail}
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    
                    <Box sx={{display: 'flex', gap: '16px', width: '100%', height: '40px'}}>
                        <Button onClick={() => {open.value = false}} sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '200px',
                            '&:hover': {
                            backgroundColor: 'secondary.light',
                            color: '#fff',
                        },}}>
                            <Typography variant="labelLarge" sx={{ml: '4px'}}>
                                Ga terug
                            </Typography>
                        </Button> 
                        <Button onClick={sendMails} sx={{backgroundColor: 'secondary.main', borderRadius: '20px', width: '250px', color: '#fff',
                            '&:hover': {
                            backgroundColor: 'secondary.light',
                            color: '#fff',
                        },}}>
                            <Typography variant="labelLarge">
                                Versturen
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
        
    );
};

export default OrderMail;
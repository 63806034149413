import { Box, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';


interface USPCardSimpleProps {
    title: string
    text: any
}

const USPCardSimple: React.FC<USPCardSimpleProps> = ({title, text}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <CheckCircleOutlineOutlinedIcon />
          <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>
            {title}
          </Typography>
        </Box>
        <Typography variant='bodyLarge' sx={{ml: '32px'}}>
          {text}
        </Typography>
      </Box>
    );
  };

export default USPCardSimple; 
import { Box, Typography } from "@mui/material";
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

interface WebshopIconProps {
    webshop: string;
}

const WebshopIcon: React.FC<WebshopIconProps> = ({ webshop }) => {
    let icon = <StorefrontOutlinedIcon />
    switch (webshop) {
        case 'Abbonnement':
            icon = <EventRepeatOutlinedIcon />;
            break;
        case 'Drive-thru':
            icon = <DirectionsCarFilledOutlinedIcon />;
            break;
        case 'Standaard':
            icon = <ShoppingCartOutlinedIcon />;
            break;
        case 'Zakelijk':
            icon = <BusinessOutlinedIcon />;
            break;
        case 'Filiaal':
            icon = <StorefrontOutlinedIcon />;
            break;
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            {icon}
        </Box>
        
    );
};

export default WebshopIcon;
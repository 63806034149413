import { lazy, PropsWithChildren, useEffect, useState } from "react";
import { Authenticator as AmplifyAuthenticator, ThemeProvider as AmplifyThemeProvider, Theme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { apiClient } from "../functions/Login";
import { Box, Divider, ThemeProvider, Typography } from "@mui/material";
import theme from "../Theme";
import Logo from '../Logo_Geel_transparant.png'
import ButtonText from "./buttons/ButtonText";
import { I18n } from "aws-amplify/utils";

export const Authenticator: React.FC<PropsWithChildren> = ({ children }) => {
    I18n.setLanguage('nl');
    I18n.putVocabulariesForLanguage('nl', {
        'Sign in': 'Inloggen', 
        'Signing in': 'Inloggen', 
        'Forgot your password?': 'Wachtwoord vergeten?',
        'Back to Sign In': 'Terug naar inloggen',
        'Send code': 'Verstuur',
        'Reset Password': 'Wachtwoord vergeten?',
        'Change Password': 'Verander je wachtwoord',
        'Password must have at least 8 characters': 'Wachtwoord moet minstens 8 tekens bevatten',
        'Your passwords must match': 'Wachtwoord komt niet overeen'
      });
      
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        apiClient().authentication.getIdpInfoAuthIdpGet().then((r) => {
            Amplify.configure({
              Auth: {
                Cognito: {
                  userPoolId: r.user_pool_id,
                  userPoolClientId: r.app_client_id
                },
              },
            });

            setLoading(false);
          })
    }, [])
   
    const amplifyTheme: Theme = {
        name: 'Loaf IT',
        tokens: {
            components: {
                authenticator: {
                    router: {
                        borderWidth: {value: '0'}, 
                        boxShadow: {value: '0'},
                    },
                    form: {
                        padding: {value: '0px 64px'},
                    }
                },
                button: {
                    primary: {
                        backgroundColor: { value: '#28555E' },
                    },
                    borderRadius: {value: '20px'},
                    
                }
            }
        }
    }
    return (
        <>
        {
            !isLoading && 
            <AmplifyThemeProvider theme={amplifyTheme}>
                <AmplifyAuthenticator
                    hideSignUp
                    variation='modal'
                    components={{
                        Header: () => (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                px: '64px',
                                pt: '64px',
                                gap: '16px',
                                backgroundColor: '#fff',
                                borderRadius: '12px 12px 0 0',
                            }}>
                                <ThemeProvider theme={theme}>
                                    <img src={Logo} alt="Loaf IT Logo" height='90px' width='200px' style={{margin: '24px auto'}} />
                                </ThemeProvider>
                                
                            </Box>
                        ),
                        Footer: () => (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                px: '64px',
                                pb: '64px',
                                gap: '16px',
                                backgroundColor: '#fff',
                                borderRadius: '0 0 12px 12px',
                            }}>
                                <ThemeProvider theme={theme}>
                                    <Divider variant='middle' flexItem sx={{mt: '16px'}}/>
                                    <Typography variant="titleLarge">
                                        Nieuw bij Loaf IT?
                                    </Typography>
                                    <ButtonText text='Start jouw 30 dagen gratis proefperiode' onClick={() => {}} margin='0' underline={true} />
                                </ThemeProvider>
                                
                            </Box>
                        ),
                        SignIn: {
                            Header: () => (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    textAlign: 'center',
                                    p: '24px',
                                    gap: '16px',
                                    backgroundColor: '#fff',
                                    borderRadius: '12px 12px 0 0',
                                }}>
                                    <ThemeProvider theme={theme}>
                                        <Typography variant="headlineMedium">
                                            Welkom terug!
                                        </Typography>
                                        <Typography variant="bodyLarge">
                                            Vul je accountgegevens in en ga verder met jouw winstgevende online omgeving.
                                        </Typography>
                                    </ThemeProvider>
                                    
                                </Box>
                            )
                        }
                    }}
                    formFields={{
                        signIn: {
                            username: {
                                label: 'Email',
                                placeholder: 'Voer je emailadres in',
                                isRequired: true
                            },
                            password: {
                                label: 'Wachtwoord',
                                placeholder: 'Voer je wachtwoord in',
                                isRequired: true
                            },
                        },
                        forgotPassword: {
                            username: {
                                label: 'Vul je e-mailadres in en ontvang binnen enkele minuten een -email om je wachtwoord te herstellen.',
                                placeholder: 'Voer je emailadres in'
                            }
                        },
                        forceNewPassword: {
                            password: {
                                label: 'Wachtwoord',
                                placeholder: 'Voer je wachtwoord in',
                                isRequired: true
                            },
                            confirm_password: {
                                label: 'Bevestig wachtwoord',
                                placeholder: 'Voer je wachtwoord nogmaals in',
                                isRequired: true
                            },
                        }
                    }}
                    
                >
                    {children}
                </AmplifyAuthenticator>
            </AmplifyThemeProvider>
        }
        </>
    )
};
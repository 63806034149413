/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ArtikelOverviewOut = {
    id: string;
    img_url?: (Array<string> | null);
    Artikelnaam: string;
    Varianten?: (Array<string> | null);
    Basisprijs_incl_btw: number;
    Artikelcode: string;
    Artikelgroepen?: (Array<string> | null);
    Status: ArtikelOverviewOut.Status;
};

export namespace ArtikelOverviewOut {

    export enum Status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}


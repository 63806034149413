import { Box, Checkbox, Divider, Grid, IconButton, MenuItem, Paper, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Signal } from "@preact/signals-react";
import { useNavigate } from "react-router-dom";


export default function RightsOverview() {

    useSignals();
    // Define signals
    const searchInput: Signal<string> = useSignal('');
    const rights: Signal<string[]> = useSignal(['Artikelen', 'Bedrijfsgegevens']);
    const roles: Signal<string[]> = useSignal(['role1', 'role2', 'role3', 'role4', 'role5', 'role6']);
    
    return(
        <ThemeProvider theme={theme}>
            {/* Filter selects */}
            <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={[]}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <BadgeOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Functie   
                                </Typography> 
                            </Box>)
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <BadgeOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Functie 
                            </Typography> 
                        </Box>
                    </MenuItem>
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={[]}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <AdminPanelSettingsOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Toegangsrechten   
                                </Typography> 
                            </Box>
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <AdminPanelSettingsOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Toegangsrechten   
                            </Typography> 
                        </Box>
                    </MenuItem>
                </Select>
                <Typography onClick={() => {}} sx={{display: 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                    Filters wissen  
                </Typography> 
            </Box>

            {/* Personal Overview */}
            
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                {/* Title */}
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{display: 'none'}}>
                        <InfoOutlinedIcon />
                        <Typography variant="bodyLarge" sx={{ml: '4px'}}>
                            Let op: je hebt filters toegepast 
                        </Typography>
                    </Box>
                </Box>

                {/* Filtering */}
                <Box sx={{display: 'flex', mb: '32px', justifyContent: 'end', alignItems: 'center', gap: '8px'}}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                        }}
                        id="search-bar"
                        onChange={(e) => {
                            searchInput.value = e.target.value;
                        } }
                        value={searchInput.value}
                        variant="outlined"
                        placeholder={'Zoek naam'}
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3'
                            },
                            width:'100%'
                        }} 
                    />
                    <Select
                        required
                        displayEmpty
                        inputProps={{
                            IconComponent: () => null
                        }}
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', borderRadius: '12px', '& .MuiSelect-root': {pr: '0px'}}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <SortByAlphaOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                    Sorteren  
                                </Typography> 
                            </Box>
                        </MenuItem>

                    </Select>
                </Box> 

                {/* Rights overview table */}
                <Grid container sx={{pl: '28px'}}>
                        <Grid item sm></Grid>
                        {
                            roles.value.map((roles, index) => (
                                    <Grid sm={1} item key={index}>
                                        <Typography variant='labelLarge' sx={{color: 'grey'}}>
                                           {roles}
                                        </Typography>
                                    </Grid>
                            ))
                        }
                    </Grid>
                <Paper variant='outlined' sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid container direction={'column'}>
                        {
                            rights.value.map((rights, index) => (
                                <>
                                <Grid item container key={index + '-titel'} sx={{pt: '16px', pl: '16px'}}>
                                    <Grid sm item sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                        <Typography variant='labelLarge'>
                                            {rights}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container key={index + '-bekijken'} sx={{pl: '16px'}}>
                                    <Grid sm item alignContent={'center'}>
                                        <Typography variant='labelMedium' sx={{ml: '12px'}}>
                                            Bekijken
                                        </Typography>
                                    </Grid>
                                    {
                                        roles.value.map((roles, index) => (
                                            <>
                                                <Grid sm={1} item>
                                                    <Checkbox size="small" sx={{mx: 'auto'}}/>
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                                <Grid item container key={index + '-beheren'} sx={{pl: '16px'}}>
                                    <Grid sm item alignContent={'center'}>
                                        <Typography variant='labelMedium' sx={{ml: '12px'}}>
                                            Beheren
                                        </Typography>
                                    </Grid>
                                    {
                                        roles.value.map((roles, index) => (
                                            <>
                                                <Grid sm={1} item >
                                                    <Checkbox size="small" sx={{mx: 'auto'}}/>
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                                <Divider sx={{mt: '12px'}}/>
                                </>
                            ))
                        }
                    </Grid>

                    <Box sx={{ml: 'auto', display: 'flex', gap: '2px'}}>
                        <IconButton>
                            <ChevronLeftOutlinedIcon style={{color: 'black'}}/>
                        </IconButton>
                        <IconButton>
                            <ChevronRightOutlinedIcon style={{color: 'black'}}/>
                        </IconButton>
                    </Box>
                </Paper>
            </Box>

        </ThemeProvider>
    )
}
import theme from '../../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useParams } from 'react-router-dom';
import ButtonFull from '../../../components/buttons/ButtonFull';
import ButtonOutlined from '../../../components/buttons/ButtonOutlined';
import Split from '../../../components/layout/Split';
import ButtonBack from '../../../components/buttons/ButtonBack';
import Card from '../../../components/layout/Card';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

export default function AddressEdit() {
    const address_id = String(useParams().address_id);


    return (
      <ThemeProvider theme={theme}>
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    Pastoor Ohllaan      
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {}} text='Afleveradres verwijderen'/>
                <ButtonFull onClick={() => {}} text='Afleveradres opslaan'/>
            </Box>

            {/* Body */}
            <Split 
                left={
                    <>
                        <Card title='Afleveradres' children={
                            <>
                                <Typography variant='titleMedium'>
                                    Naam locatie
                                </Typography>
                                <TextField label="Naam locatie" variant="outlined" />
                                <TextField label="Beschrijving" variant="outlined" />
                                <Typography variant='titleMedium'>
                                    Afleveradres
                                </Typography>
                                <TextField label="Postcode" variant="outlined" />
                                <Split 
                                    left={
                                        <TextField label="Huisnummer" variant="outlined" /> 
                                    }
                                    right={
                                        <TextField label="Toevoeging" variant="outlined" />
                                    }
                                />
                                <TextField label="Straat" variant="outlined" />
                                <TextField label="Plaats" variant="outlined" />

                            </>
                        }
                        />  

                        <Card title='Webshop' children={
                                <>  
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Deze afleverlocatie gaat online bestellen" />
                                    <FormControl fullWidth>
                                        <InputLabel id="factuur-label">Factuurperiode</InputLabel>
                                        <Select
                                        labelId="factuur-label"
                                        id="factuur-select"
                                        value={'wekelijks'}
                                        label="Factuurperiode"
                                        onChange={() => {}}
                                        >
                                        <MenuItem value={'wekelijks'}>Wekelijks</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField label="Email voor bestelaccount" variant="outlined" />
                                    <ButtonFull text='Verstuur nieuw wachtwoord' onClick={() => {}} width='100%'/>
                                    <Box sx={{display: 'flex', gap: '8px'}}>
                                        <VerifiedUserOutlinedIcon />
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                            <Typography variant='bodyMedium'>
                                                Veilige login
                                            </Typography>
                                            <Typography variant='bodySmall'>
                                                verbindingen zijn versleuteld met SSL
                                            </Typography>
                                        </Box>
                                    </Box>         
                                </>
                            }
                        />

                        <Card title='Facturatie' children={
                            <>
                                <Typography variant='titleMedium'>
                                    Debiteur
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="debiteur-label">Kies een hoofddebiteur</InputLabel>
                                    <Select
                                    labelId="debiteur-label"
                                    id="debiteur-select"
                                    value={1}
                                    label="Kies een hoofddebiteur"
                                    onChange={() => {}}
                                    >
                                    <MenuItem value={1}>De Nieuwe Bakker Loosdrecht</MenuItem>
                                    </Select>
                                </FormControl>
                                <Divider>OF</Divider>
                                
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Dit afleveradres is de hoofddebiteur" />
                                <TextField label="KVK-nummer" variant="outlined" />
                                <TextField label="Facturatie mailadres" variant="outlined" />
                                <TextField label="Postcode" variant="outlined" />
                                <Split 
                                    left={
                                        <TextField label="Huisnummer" variant="outlined" /> 
                                    }
                                    right={
                                        <TextField label="Toevoeging" variant="outlined" />
                                    }
                                />
                                <TextField label="Straat" variant="outlined" />
                                <TextField label="Plaats" variant="outlined" />

                            </>
                        }
                        />  
                    </>
                }
                right={
                    <>
                      <Card title='Status' children={
                                <>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge'>
                                            Actief
                                        </Typography>
                                        <Switch defaultChecked />
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge'>
                                            Laatste keer ingelogd
                                        </Typography>
                                        <Typography variant='bodyLarge'>
                                            01-01-2023 12:00:00
                                        </Typography>
                                    </Box>
                                    
                                </>
                            }
                        /> 

                        <Card title='Contactgegevens' children={
                            <>
                                <Typography variant='titleMedium'>
                                    Contactgegevens locatie
                                </Typography>
                                <TextField label="Email" variant="outlined" />
                                <TextField label="Telefoonnummer locatie" variant="outlined" />
                                <Typography variant='titleMedium'>
                                    Primaire contactpersoon
                                </Typography>
                                <TextField label="Naam contactpersoon" variant="outlined" />
                                <TextField label="Telefoonnummer contactpersoon" variant="outlined" />

                            </>
                        }
                        />  

                        <Card title='Aflevertijden' children={
                            <>
                                <Typography variant='bodyLarge'>
                                    Deselecteer de dagen wanneer je  niet bij de klant kan leveren.
                                </Typography>
                                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                    Normale aflevertijden
                                </Typography>
                                <Box sx={{display: 'flex', gap: '8px'}}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Maandag" sx={{mr: 'auto'}}/>
                                    <LocalizationProvider key={1} dateAdapter={AdapterDayjs}>
                                        <TimePicker onChange={(time: Dayjs | null) => {}} sx={{width: '160px'}} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Leverbaar van" />
                                        <TimePicker onChange={(time: Dayjs | null) => {}} sx={{width: '160px'}} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Leverbaar tot" />
                                    </LocalizationProvider>
                                </Box>

                                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>
                                    Afwijkende aflevertijden
                                </Typography>
                                <Box sx={{display: 'flex', gap: '8px'}}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="25 December" sx={{mr: 'auto'}}/>
                                    <LocalizationProvider key={1} dateAdapter={AdapterDayjs}>
                                        <TimePicker onChange={(time: Dayjs | null) => {}} sx={{width: '160px'}} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Leverbaar van" />
                                        <TimePicker onChange={(time: Dayjs | null) => {}} sx={{width: '160px'}} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Leverbaar tot" />
                                    </LocalizationProvider>
                                    <IconButton onClick={() => {}}>
                                        <DeleteOutlineOutlinedIcon/>
                                    </IconButton>
                                </Box>
                                <ButtonFull text='Afwijkende leveringstijden toevoegen' onClick={() => {}} width='100%'/>
                            </>
                        }
                        />      
                    </>
                }
            />
        </Box>
      </ThemeProvider>
    );
  }
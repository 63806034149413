import { Box } from "@mui/material";
import { ReactNode } from "react";


interface SpacedProps {
    orientation?: 'column' | 'row'
    maxWidth?: string
    children?: ReactNode
}

const Spaced: React.FC<SpacedProps> = ({maxWidth, orientation = 'row', children}) => {
    // Card used in most designs, so this way we can limit the amount redundency

    return (
      <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px' , flexDirection: orientation, ...(maxWidth && {maxWidth: maxWidth})}}>
        {children}
      </Box>
    );
  };

export default Spaced;
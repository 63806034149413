import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotMobile() {
    const navigate = useNavigate();

    return (
        <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'secondary.main', justifyContent: 'center'}}>
            <Typography variant="headlineLarge" sx={{mx: 'auto'}}>
                Schakel over naar een mobiel of tablet (in portret modus) voor deze functionaliteit
            </Typography>
            <Button onClick={() => {navigate(-1)}} sx={{backgroundColor: '#FFA329', color: '#fff', borderRadius: '20px', width: '150px', mx: 'auto', my: '16px'}}>
                <Typography variant="labelLarge" sx={{px: '12px'}}>
                    Of ga terug   
                </Typography> 
            </Button>
        </Box>
    )
}
import { Box, IconButton, MenuItem, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import theme from "../../../Theme";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import AddressCard from "../../../components/Address/AddressCard";
import { createRef } from "react";
import ButtonOutlined from "../../../components/buttons/ButtonOutlined";
import AddressOverview from "../../../components/Address/AddressOverview";

export default function AddressDetails() {
    const addressContainer = createRef<HTMLDivElement>();

    useSignals()
    const searchInput: Signal<string> = useSignal('');
    const selectedAddress: Signal<number> = useSignal(-1);

    const closeOverview = () => {
        selectedAddress.value = -1;
    }

    const testData = [
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
        {
            'phone_number': 'asdad',
            'name': 'blabla',
            'address': 'blabla 12'
        },
    ]

    const backToTop = () => {
        addressContainer.current?.scrollTo({top: 0, behavior: 'smooth'});
    }

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {/* Filter selects */}
                <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                            <IconButton color='inherit' sx={{ mr: 1 }}>
                                <SearchOutlinedIcon />
                            </IconButton>
                            ),
                        }}
                        id="search-bar"
                        onChange={(e) => {
                            searchInput.value = e.target.value;
                        } }
                        value={searchInput.value}
                        variant="outlined"
                        placeholder={'Zoek locatienaam of adres'}
                        sx={{
                            color: '#E1E3E3',
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderWidth: '0px',
                            backgroundColor: 'E1E3E3',
                            maxHeight: '50px'
                            },
                            width:'350px',
                            maxHeight: '50px'
                        }} 
                    />
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <CreditCardOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Debiteur   
                                    </Typography> 
                                </Box>)
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <CreditCardOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Debiteur 
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        multiple
                        value={[]}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return (
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <EditAttributesOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                        Status   
                                    </Typography> 
                                </Box>
                                )
                            }
                
                            return selected.join(', ');
                        }}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value={''} key={''}>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <EditAttributesOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Status   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    
                    </Select>
                    <Typography onClick={() => {}} sx={{display: 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                        Filters wissen  
                    </Typography> 
                </Box>
                    
                <Box sx={{display: 'flex', backgroundColor: '#fff', borderRadius: '16px', mb: '32px'}}>
                    {/* Addresses */}
                    <Box ref={addressContainer} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: '800px', overflowY: 'auto'}}>
                        {
                            testData.map((address, index) => (
                                <AddressCard address={address.address} name={address.name} phone_number={address.phone_number} onClick={() => {selectedAddress.value = index}} key={index}/>
                            ))
                        }
                        <Box sx={{display: 'flex', m: '8px', justifyContent: 'center'}}>
                            <ButtonOutlined Icon={VerticalAlignTopOutlinedIcon} text='Terug naar boven' onClick={backToTop}/>
                        </Box>
                        
                    </Box>
                    {/* Map */}
                    <Box sx={{display: 'flex', flexGrow: 2, position: 'relative', alignItems: 'center'}}>
                        {
                            // selectedAddress.value !== -1 &&
                            // // <AddressOverview onClose={closeOverview}/>
                        }
                        <iframe
                            title="vestiging locatie"
                            height={'800px'}
                            width={'100%'}
                            style={{borderRadius: '0 12px 12px 0', border: 0, position: 'relative', zIndex: '1'}}
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                &q=Nieuwe%20Schaft%2025}`}
                        >
                        </iframe>
                    </Box>
                </Box>


            </Box>
        </ThemeProvider>
    )
}
import { Typography } from "@mui/material";


interface ButtonTextProps {
    onClick: () => any
    text: string
    margin?: string
    underline?: boolean
    color?: string
}

const ButtonText: React.FC<ButtonTextProps> = ({onClick, text, margin = 'auto', underline = false, color = 'secondary.main'}) => {

    return (
        <Typography onClick={onClick} sx={{textAlign: 'center', display: 'flex', cursor: 'pointer', color: color, borderRadius: '20px', ml: margin, textDecoration: underline ? 'underline' : 'none', width: 'fit-content'}} variant="labelLarge">
            {text} 
        </Typography> 
    );
  };

export default ButtonText;
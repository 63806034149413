/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LocationCategory } from './LocationCategory';

export type Body_get_all_subsidiaries_users_subsidiaries_post = {
    categories?: (Array<LocationCategory> | null);
    debiteur_ids?: (Array<string> | null);
    status?: Body_get_all_subsidiaries_users_subsidiaries_post.status;
};

export namespace Body_get_all_subsidiaries_users_subsidiaries_post {

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}


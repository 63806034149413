/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MailLoggerIn } from '../models/MailLoggerIn';
import type { MailLoggerOut } from '../models/MailLoggerOut';
import type { MailLoggerOutList } from '../models/MailLoggerOutList';
import type { MailTemplateIn } from '../models/MailTemplateIn';
import type { MailTemplateOut } from '../models/MailTemplateOut';
import type { MailTemplateOutList } from '../models/MailTemplateOutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MailingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Mail Templates
     * Get all mail templates
     * @returns MailTemplateOutList Successful Response
     * @throws ApiError
     */
    public getAllMailTemplatesMailingMailTemplatesGet(): CancelablePromise<MailTemplateOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mailing/mail_templates',
        });
    }

    /**
     * Create Mail Template
     * Create mail template
     * @param requestBody
     * @returns MailTemplateOut Successful Response
     * @throws ApiError
     */
    public createMailTemplateMailingMailTemplatesPost(
        requestBody: MailTemplateIn,
    ): CancelablePromise<MailTemplateOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/mail_templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mail Template By Id
     * Get mail template by id
     * @param mailTemplateId
     * @returns MailTemplateOut Successful Response
     * @throws ApiError
     */
    public getMailTemplateByIdMailingMailTemplatesMailTemplateIdGet(
        mailTemplateId: string,
    ): CancelablePromise<MailTemplateOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mailing/mail_templates/{mail_template_id}',
            path: {
                'mail_template_id': mailTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Mail Template By Id
     * Delete mail template by id
     * @param mailTemplateId
     * @param inclSource
     * @returns void
     * @throws ApiError
     */
    public deleteMailTemplateByIdMailingMailTemplatesMailTemplateIdDelete(
        mailTemplateId: string,
        inclSource: boolean = true,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/mailing/mail_templates/{mail_template_id}',
            path: {
                'mail_template_id': mailTemplateId,
            },
            query: {
                'incl_source': inclSource,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mail Template Source By Name
     * Get mail template source (from ses) by name
     * @param mailTemplateName
     * @returns MailTemplateOut Successful Response
     * @throws ApiError
     */
    public getMailTemplateSourceByNameMailingMailTemplatesSourceMailTemplateNameGet(
        mailTemplateName: string,
    ): CancelablePromise<MailTemplateOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mailing/mail_templates/source/{mail_template_name}',
            path: {
                'mail_template_name': mailTemplateName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Mail Template Source By Name
     * Delete mail template source (from ses) by name
     * @param mailTemplateName
     * @returns void
     * @throws ApiError
     */
    public deleteMailTemplateSourceByNameMailingMailTemplatesSourceMailTemplateNameDelete(
        mailTemplateName: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/mailing/mail_templates/source/{mail_template_name}',
            path: {
                'mail_template_name': mailTemplateName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Mails
     * Get all mails
     * @returns MailLoggerOutList Successful Response
     * @throws ApiError
     */
    public getAllMailsMailingMailsGet(): CancelablePromise<MailLoggerOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mailing/mails',
        });
    }

    /**
     * Get Mail By Id
     * Get mail by id
     * @param mailLoggerId
     * @returns MailLoggerOut Successful Response
     * @throws ApiError
     */
    public getMailByIdMailingMailsMailLoggerIdGet(
        mailLoggerId: string,
    ): CancelablePromise<MailLoggerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/mailing/mails/{mail_logger_id}',
            path: {
                'mail_logger_id': mailLoggerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Mail By Id
     * Delete mail by id
     * @param mailLoggerId
     * @returns void
     * @throws ApiError
     */
    public deleteMailByIdMailingMailsMailLoggerIdDelete(
        mailLoggerId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/mailing/mails/{mail_logger_id}',
            path: {
                'mail_logger_id': mailLoggerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Templated Email
     * Send mail with a predefined template
     * @param requestBody
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendTemplatedEmailMailingSendTemplatedEmailPost(
        requestBody: MailLoggerIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/send_templated_email',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Drive Thru Qr Email
     * Send drive thru qr email
     * @param orderEmail
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendDriveThruQrEmailMailingSendDriveThruQrEmailOrderEmailPost(
        orderEmail: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/send_drive_thru_qr_email/{order_email}',
            path: {
                'order_email': orderEmail,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Verification Email
     * Send verification email
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendVerificationEmailMailingSendVerificationEmailPost(
        email: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/send_verification_email',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify Email Code
     * Verify email code
     * @param email
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public verifyEmailCodeMailingVerifyEmailCodePost(
        email: string,
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/verify_email_code',
            query: {
                'email': email,
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import ButtonFull from "../buttons/ButtonFull";

interface ConfirmFormProps {
    open: Signal<boolean>
    width: string
}

const ConfirmForm: React.FC<ConfirmFormProps> = ({open, width}) => {
const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '32px',
        alignItems: 'center'
      };


    return (
        <Modal
            aria-labelledby="confirm-form-modal"
            aria-describedby="Modal to confirm form"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <Typography variant='headlineLarge'sx={{textAlign: 'center'}}>
                        Bedankt voor je aanmelding!
                    </Typography>
                    <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
                        We nemen zo spoedig mogelijk contact met je op.
                    </Typography>
                    <Box sx={{display: 'flex', width: '100%', gap: '8px', justifyContent: 'center'}}>
                        <ButtonFull text='Sluiten' onClick={() => {open.value = false}} width='50%'/>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default ConfirmForm;
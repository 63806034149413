import { Box, Chip, Divider, Typography } from "@mui/material";
import Split from "../layout/Split";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';


interface AddressCardProps {
    phone_number: string
    name: string
    address: string
    is_active?: boolean
    onClick: any
}

const AddressCard: React.FC<AddressCardProps> = ({name, address, phone_number, is_active = true, onClick, ...props}) => {
    return (
        <>
        <Box onClick={onClick} sx={{display: 'flex', p: '16px', backgroundColor: '#fff', width: '100%', cursor: 'pointer', '&:hover': {filter: 'brightness(90%)'}}}>
            <Split left={
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant='titleMedium'>
                        {name}
                    </Typography>
                    <Typography variant='bodyMedium'>
                        {address}
                    </Typography>
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <LocalPhoneOutlinedIcon />
                        <Typography variant='bodyMedium'>
                            {phone_number}
                        </Typography>
                    </Box>
                </Box>
                }  
                right={
                    <>
                    {
                        is_active ?
                            <Chip size="small" sx={{color: '#fff', backgroundColor: "#006E2A", width: '80px', ml: 'auto', position: 'relative' }} label={
                                <Typography variant="labelLarge" sx={{}}>
                                    Actief  
                                </Typography> 
                            }/>
                        :
                            <Chip size="small" sx={{color: '#fff', backgroundColor: "#c00000", width: '80px',  ml: 'auto', position: 'relative'}} label={
                                <Typography variant="labelLarge" sx={{}}>
                                    Inactief  
                                </Typography> 
                            }/>
                    }
                    </>
                }
            />
        </Box>
        <Divider/>
        </>
    );
  };

export default AddressCard;
import { Box, Button, Paper,ThemeProvider, Typography } from "@mui/material";

import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient } from "../../functions/Login";
import { DriveThruEventLocationOut } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruLocations() {
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
    const navigate = useNavigate();
    const id = String(useParams().shop_id);
    
    const [locations, setLocations] = useState<DriveThruEventLocationOut[]>([]);
    useEffect(() => {
        apiClient().shops.getShopByIdShopsShopsShopIdGet(id).then((r) => {
            setLocations(r.drive_thru_event_locations!);
        })
    }, [])
    return (
        <ThemeProvider theme={ThempTheme}>
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '100px', backgroundColor: 'primary.main', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '64px', py: '16px'}}>
                </Box>
                {/* locations overview */}
                <Box sx={{height: '100%', backgroundColor: 'secondary.main', display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                    <Typography variant='headlineLarge' sx={{ my: '64px', mx:'auto' }}>
                        {locations.length === 1 ? 'Kies je activiteit' : 'Kies je locatie en activiteit'}
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: isMobile ? 'nowrap': 'wrap', gap: '16px', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row', p: isMobile ? '16px' : '0px', mb: '16px'}}>
                        {
                            locations.map(location => {
                                return (
                                    <Paper key={location.id} variant='outlined' sx={{flexBasis: '40%', display: 'flex', flexDirection: 'column', borderRadius: '16px', p: '32px'}}>
                                        <iframe
                                            title="Event locatie"
                                            height={'120px'}
                                            width={'150px'}
                                            style={{borderRadius: '12px', border: 0, margin: '16px auto'}}
                                            loading="lazy"
                                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                                &q=${(location.location.company.delivery_address.street + ' ' + location.location.company.delivery_address.number).replaceAll(' ', '%20') + ',' + location.location.company.delivery_address.city}`}
                                            >
                                        </iframe>
                                        <Typography variant='titleLarge' sx={{ my: '16px', mx:'auto'}}>
                                            {location.location.company.delivery_address.street + ' ' + location.location.company.delivery_address.number + (location.location.company.delivery_address.number_suffix || '')} 
                                            {', ' + location.location.company.delivery_address.city}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: '16px', justifyContent: 'center', flexDirection: isMobile ? 'column' : 'row'}}>
                                            <Button onClick={() => {navigate(`/drive-thru/opties/${id}/${location.id}`)}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                                                <QrCode2OutlinedIcon />
                                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                                    Ik ga scannen 
                                                </Typography> 
                                            </Button>
                                            <Button onClick={() => {navigate(`/drive-thru/orders/${location.id}`)}} variant="contained" color="primary" sx={{ height: '40px', borderRadius: '20px'}}>
                                                <ShoppingBagOutlinedIcon />
                                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                                    Ik ga bestellingen uitgeven 
                                                </Typography> 
                                            </Button>
                                        </Box>
                                    </Paper>
                                )
                            })
                        }
                       
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

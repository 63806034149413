import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box,Tab, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PersonalOverview from './PersonalOverview';
import FunctionOverview from './FunctionOverview';
import RightsOverview from './RightsOverview';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import EmployeeAdd from '../../components/modals/EmployeeAdd';
import GroupAdd from '../../components/modals/GroupAdd';

export default function Staff() {
    useSignals();
    const openAddEmployee: Signal<boolean> = useSignal(false);
    const openAddGroup: Signal<boolean> = useSignal(false);

    const [tab, setTab] = useState<string>('medewerkers');

    const param_tab = String(useParams().active_tab);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        setTab(param_tab)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/personeel/${newValue}`);
        }
    };
    
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{backgroundColor: '#f2f4f4'}}>
            {/* Header with buttons */}
            <Box sx={{margin: '16px', display: 'flex', justifyContent: 'space-between', mr: '32px', gap: '8px'}}>
                {
                    tab === 'medewerkers' ?
                        <>
                            <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Personeel  
                            </Typography> 
                            {/* <ButtonOutlined Icon={FileUploadOutlinedIcon} text='Importeer medewerkers' onClick={() => {}}/> */}
                            <ButtonFull Icon={AddOutlinedIcon} text='Medewerker toevoegen' onClick={() => {openAddEmployee.value = true}}/>
                        </>
                    :
                    tab === 'functies' ?
                        <>
                            <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Functies  
                            </Typography> 
                            {/* <ButtonOutlined Icon={FileUploadOutlinedIcon} text='Importeer functies' onClick={() => {}}/> */}
                            <ButtonFull Icon={AddOutlinedIcon} text='Functie toevoegen' onClick={() => {openAddGroup.value = true}}/>
                        </>
                    :
                        <>
                            <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                                Toegangsrechten  
                            </Typography> 
                            <ButtonFull Icon={AddOutlinedIcon} text='Functie toevoegen' onClick={() => {}}/>
                        </>
                }
            </Box>
            <TabContext value={tab}>
                <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'#D18C5C'}}}
                >
                <Tab icon={<PersonOutlineIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Medewerkers   
                    </Typography>} value = 'medewerkers' 
                />
                <Tab icon={<BadgeOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Functies  
                    </Typography>} value = 'functies'
                />
                {/* <Tab icon={<AdminPanelSettingsOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Toegangsrechten   
                    </Typography>} value = 'toegangsrechten' 
                /> */}
                </TabList>

                <TabPanel value='medewerkers' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <EmployeeAdd open={openAddEmployee}/>
                        <PersonalOverview />
                    </Box>
                </TabPanel>
                <TabPanel value='functies' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <GroupAdd open={openAddGroup}/>
                        <FunctionOverview />
                    </Box>
                </TabPanel>
                <TabPanel value='toegangsrechten' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <RightsOverview />
                    </Box>
                </TabPanel>
            </TabContext>
            </Box>
      </ThemeProvider>
    );
  }
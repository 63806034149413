import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";


interface FadeImageProps {
    mainImage: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    secondImage?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    left?: boolean
    height?: string
}

const FadeImage: React.FC<FadeImageProps> = ({mainImage, secondImage, left = false, height = '100%'}) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();
  useEffect(() => {
    const ref = domRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(ref!);
    return () => observer.unobserve(ref!);
  }, []);

  return (
    <Box ref={domRef} sx={{transform: isVisible ? 'none' : `translateX(${left ? '-' : ''}100px)`, willChange: 'opacity visibility', visibility: isVisible ? 'visible' : 'hidden', transition: 'opacity 0.6s ease-out, transform 1.2s ease-out', display: 'flex', width: '100%', height: height, position: 'relative', opacity: isVisible ? '1' : '0', alignItems: 'center'}}>
      <>{mainImage}</>
      <>{secondImage ? secondImage : ''}</>
    </Box>
  );
};

export default FadeImage; 
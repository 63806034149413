import { Box, Paper, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";


interface StatsOverviewProps {
    title: string
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    iconOnValue?: boolean
    value: any
}

const StatsOverview: React.FC<StatsOverviewProps> = ({title, Icon, iconOnValue = false, value}) => {
    return (
      <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', gap: '10px', flexGrow: '1'}}>
        <Box sx={{display: 'flex', height: '70px', width: '70px', borderRadius: '8px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
            <Icon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                {
                  iconOnValue &&
                  <Icon preserveAspectRatio='none' sx={{mt: '2px'}} />
                }
                <Typography variant='headlineLarge'>
                    {value}
                </Typography>
            </Box>
            <Typography variant='bodyLarge'>
                {title}
            </Typography>
        </Box>
      </Paper>
    );
  };

export default StatsOverview;
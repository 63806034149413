import { Alert, Box, Button, IconButton, Snackbar, ThemeProvider, Typography } from "@mui/material";

import {QrScanner} from '@yudiel/react-qr-scanner';
import { useMediaQuery } from 'react-responsive'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import theme from "../../Theme";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotMobile from "../../components/NotMobile";
import { apiClient } from "../../functions/Login";
import { ApiError, DriveThruOrderFactIn, DriveThruOrderFactStatus } from "../../assets";
import ThempTheme from "../../TempTheme";

export default function DriveThruForward() {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const order = String(useParams().order_id)
    const [selected, setSelected] = useState(-1);
    const [snackBarText, setSnackbarText] = useState('');
    const isMobileorTablet = useMediaQuery({ query: '(max-width: 1224px)' })
    const shop_id = String(useParams().shop_id)
    const event_location_id = String(useParams().location_id);
    const [deskCount, setDeskCount] = useState(0);

    const openSnackbar = () => {
        setSnackbarOpen(true);
    };
    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    const customerToDesk = () => {
        if (selected === -1) {
            setSnackbarText('Selecteer eerst een balie.');
            openSnackbar();
            return
        }
        const newOrderFact = {
            status: DriveThruOrderFactStatus.GESCAND,
            distribution_point: selected + 1,
            order_id: order
        } as DriveThruOrderFactIn

        apiClient().driveThru.createOrderFactDriveThruOrderFactsPost(newOrderFact).then(() => {
            navigate(`/drive-thru/opties/${shop_id}/${event_location_id}/${selected + 1}`);
        }).catch((error: ApiError) => {
            setSnackbarText('Er lijkt iets mis te gaan, scan de QR-code opnieuw.');
            openSnackbar();
            setTimeout(() => {
                navigate(`/drive-thru/scanner/${shop_id}/${event_location_id}`)
            }, 2000) 
        })
    };

    useEffect(() => {
        apiClient().driveThru.getEventLocationByIdDriveThruEventLocationsEventLocationIdGet(event_location_id).then((r) => {
            setDeskCount(r.distribution_points);
        });
        
    }, [])

    return (
        <ThemeProvider theme={ThempTheme}>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={closeSnackbar} severity={'error'} sx={{ width: '100%' }}>
                   {snackBarText}
                </Alert>
            </Snackbar>
            {isMobileorTablet ?  
            <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '90px', backgroundColor: '#705842', display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: '16px', py: '16px'}}></Box>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'secondary.main', height: '100%', p: '32px'}}>
                    <Typography variant="headlineLarge" sx={{mx: 'auto'}}>
                        Verwijs naar balie
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', my: '24px'}}>
                        {   
                            [...Array(deskCount)].map((e, i) =>
                                <Box key={i} sx={{flexBasis: '50%', justifyContent: (i + 1) % 2 ===  0 ? 'start' : 'end', display: 'flex'}}>
                                    <Button variant="contained" onClick={() => setSelected(i)} sx={{border: selected === i ? 3 : 0, display: 'flex', alignItems: 'center', backgroundColor: 'primary.main', width: '80px', height: '80px', borderRadius: '12px', m: '24px'}}>
                                        <Typography variant="headlineLarge" sx={{mx: 'auto', color: '#fff'}}>
                                            {i + 1}
                                        </Typography>
                                    </Button>
                                </Box>
                            )
                        }
                    </Box>
                    <Button variant="contained" onClick={() => {customerToDesk()}} sx={{display: 'flex', alignItems: 'center', backgroundColor: 'primary.main', borderRadius: '16px'}}>
                        <Typography variant="labelLarge" sx={{mx: 'auto'}}>
                            Doorsturen
                        </Typography>
                    </Button>
                </Box>
            </Box>
            : 
            // Not mobile or tablet device
            <NotMobile />
            }
        </ThemeProvider>
    );
}



import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ConsumerOverview from './ConsumerOverview';
import BusinessOverview from './BusinessOverview';
import BranchesOverview from './BranchesOverview';


export default function Customers() {
    const [tab, setTab] = useState<string>('consumenten');

    const param_tab = String(useParams().active_tab);
    const navigate = useNavigate()

    if(param_tab !== "undefined" && param_tab !== tab) {
        setTab(param_tab)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(tab !== newValue) {
            navigate(`/klanten/${newValue}`);
        }
    };
    
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{backgroundColor: '#f2f4f4'}}>
            {/* Header with buttons */}
            <Box sx={{margin: '16px', display: 'flex', justifyContent: 'space-between', mr: '32px', gap: '8px'}}>
                <Typography variant="headlineLarge" sx={{}}>
                    Klanten  
                </Typography> 
                {
                    tab === 'zakelijk' &&
                    <ButtonFull Icon={AddOutlinedIcon} text='Zakelijke klant toevoegen' onClick={() => {}} />
                }

{
                    tab === 'filialen' &&
                    <ButtonFull Icon={AddOutlinedIcon} text='Filiaal toevoegen' onClick={() => {}} />
                }
            </Box>
            <TabContext value={tab}>
                <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{style: {background:'#D18C5C'}}}
                >
                <Tab icon={<PersonOutlineIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Consumenten   
                    </Typography>} value = 'consumenten' 
                />
                <Tab icon={<BusinessOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Zakelijk  
                    </Typography>} value = 'zakelijk'
                />
                <Tab icon={<StorefrontOutlinedIcon sx={{color: 'rgba(0,0,0,0.6)'}}/>} iconPosition="start" label={
                    <Typography variant="labelLarge" sx={{textTransform :"capitalize", color: 'rgba(0,0,0,0.6)'}}>
                        Filialen   
                    </Typography>} value = 'filialen' 
                />
                </TabList>

                <TabPanel value='consumenten' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <ConsumerOverview />
                    </Box>
                </TabPanel>
                <TabPanel value='zakelijk' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <BusinessOverview />
                    </Box>
                </TabPanel>
                <TabPanel value='filialen' sx={{p: 0}}>
                    <Box sx={{overflowY: 'auto', overflowX: 'hidden', mr: '16px'}}>
                        <BranchesOverview />
                    </Box>
                </TabPanel>
            </TabContext>
            </Box>
      </ThemeProvider>
    );
  }
import { Box, Typography } from "@mui/material";

interface StatusChipProps {
    status: string;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
    let color: string = '#72BA4F';

    switch (status) {
        case 'Besteld':
            color = '#EC63AC';
            break;
        case 'Orderbevestiging':
            color = '#D28CE3';
            break;
        case 'In behandeling':
            color = '#ED7644';
            break;
        case 'Voorraadcontrole':
            color = '#FFB366';
            break;
        case 'Gescand':
            color = '#EAAE3E';
            break;
        case 'Orderpicking':
            color = '#66CCCC';
            break;
        case 'Gereed voor levering':
            color = '#5189E3';
            break;
        case 'Onderweg':
            color = '#8836E2';
            break;
        case 'Geleverd':
            color = '#A4C954';
            break;
        case 'Facturatie':
            color = '#84C3A6';
            break;
        case 'Afgesloten':
            color = '#84BD62';
            break;
        case 'Geannuleerd':
            color = '#C00000';
            break;
        case 'Inactief':
            color = '#DC4A3A';
            break;
    }

    const addAlpha = (color: string) => {
        return color + '15';
    }

    const style = {
        display: 'flex',
        maxWidth: '150px',
        minWidth: '130px',
        bgcolor: addAlpha(color),
        color: color,
        borderRadius: '8px',
        justifyContent: 'center',
        height: '35px',
        alignItems: 'center',
        border: `1px solid ${color}`
      };

    return (
        <Box sx={style}>
            <Typography variant='labelLarge'>
                {status}
            </Typography>
        </Box>
    );
};

export default StatusChip;
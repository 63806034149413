import { Box, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";


interface USPCardProps {
    title: string
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    text: any
    isMobile?: boolean
}

const USPCard: React.FC<USPCardProps> = ({title, Icon, text, isMobile = false}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <Box sx={{display: 'flex', height: '40px', width: '40px', borderRadius: '32px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
              <Icon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
          </Box>
          <Typography variant='headlineSmall'>
            {title}
          </Typography>
        </Box>
        <Typography variant='bodyLarge' sx={{ml: isMobile ? '0px' : '48px'}}>
          {text}
        </Typography>
      </Box>
    );
  };

export default USPCard; 
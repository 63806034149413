import { Backdrop, Box, Checkbox, Fade, IconButton, Modal, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { v4 as uuid } from 'uuid'
import { ConfigProvider, DatePicker } from "antd";
import locale from 'antd/es/date-picker/locale/nl_NL';
import ButtonFull from "../buttons/ButtonFull";

interface openingTime {
    id: string
    name: string
    date: Date
    openFrom: Dayjs
    openTill: Dayjs
    active: boolean
}

interface DeviantTimeAddProps {
    open: Signal<boolean>
    deviantTimes: Signal<openingTime[]>
}

const DeviantTimeAdd: React.FC<DeviantTimeAddProps> = ({open, deviantTimes}) => {
    useSignals();

    const newDeviantTime: Signal<openingTime> = useSignal({
        id: uuid(),
        date: new Date(),
        name: new Date().toLocaleDateString(),
        openFrom: dayjs().set('hour', 9).set('minute', 0),
        openTill: dayjs().set('hour', 17).set('minute', 0),
        active: true
    });

    const reset = () => {
        newDeviantTime.value = {
            id: uuid(),
            date: new Date(),
            name: new Date().toLocaleDateString(),
            openFrom: dayjs().set('hour', 9).set('minute', 0),
            openTill: dayjs().set('hour', 17).set('minute', 0),
            active: true
        }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto',
        alignItems: 'center'
      };


    return (
        <Modal
            aria-labelledby="confirm-modal"
            aria-describedby="Modal to confirm action"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineMedium'>
                            Afwijkende openingstijden
                        </Typography>
                        <Typography variant='bodyLarge'>
                            Op welke dag heb je afwijkende openingstijden / ben je gesloten?
                        </Typography>
                        <ConfigProvider
                            theme={{
                                components: {
                                DatePicker: {
                                    activeBorderColor: '#28555e',
                                    cellRangeBorderColor: '#28555e',
                                    hoverBorderColor: '#28555e',
                                    colorPrimary: '#28555e',
                                    colorBorder: '#c4c4c4',
                                    borderRadius: 4,
                                    colorTextPlaceholder: '#000',
                                    activeShadow: 'none',
                                    presetsWidth: 360,
                                    zIndexPopup: 2000,
                                    zIndexPopupBase: 2000,
                                    zIndexBase: 2000
                                },
                                },
                            }}
                        >
                            <DatePicker  style={{width: '100%', height: '56px'}} onChange={(e) => {if (e) newDeviantTime.value = {...newDeviantTime.value, date: e.toDate()}}} value={dayjs(newDeviantTime.value.date)} format={'DD MMMM'} locale={locale} />
                        </ConfigProvider>
                        <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker onChange={(e: Dayjs | null) => {if (e) newDeviantTime.value = {...newDeviantTime.value, openFrom: e}}} value={newDeviantTime.value.openFrom} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open van" />
                                <TimePicker onChange={(e: Dayjs | null) => {if (e) newDeviantTime.value = {...newDeviantTime.value, openTill: e}}} value={newDeviantTime.value.openTill} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open tot" />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            <Checkbox onChange={(e) => {newDeviantTime.value = {...newDeviantTime.value, active: !e.currentTarget.checked}}}/>
                            <Typography variant='bodyMedium'>
                                Deze dag is de locatie gesloten
                            </Typography>
                        </Box>
                        <ButtonFull text='Afwijkende tijden opslaan' onClick={() => {
                            if (!deviantTimes.value.some(time => time.date.getDate() === newDeviantTime.value.date.getDate())) {
                                newDeviantTime.value = {...newDeviantTime.value, name: dayjs(newDeviantTime.value.date).format('DD MMMM')}
                                deviantTimes.value = [...deviantTimes.value, newDeviantTime.value]
                            }
                            open.value = false;
                            reset();
                        }}/>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default DeviantTimeAdd;
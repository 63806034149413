import { Box, Paper, ThemeProvider, Typography } from '@mui/material';
import { Signal } from '@preact/signals-react';
import theme from '../../Theme';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import { BarChart } from '@mui/x-charts/BarChart';
import { DatasetType } from '@mui/x-charts/models/seriesType/config';
import { useSignals } from '@preact/signals-react/runtime';


interface DashboardProps {
  orders: Signal<number>;
  revenue: Signal<number>;
  customers: Signal<number>;
  profit: Signal<number>;
  data: Signal<DatasetType>; 
}

const Dashboard: React.FC<DashboardProps> = ({ orders, revenue, customers, profit, data }) => {
    useSignals();
    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex', minHeight: '100px', gap: '16px'}}>
                {/* Overview */}
                <Box sx={{display: 'flex', flexDirection: 'column', width: '55%', gap: '16px'}}>
                    <Box sx={{display: 'flex', gap: '16px'}}>
                        <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', width: '50%', gap: '10px'}}>
                            <Box sx={{display: 'flex', height: '70px', width: '70px', borderRadius: '8px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                                <ReceiptOutlinedIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography variant='headlineLarge'>
                                    {orders.toString()}
                                </Typography>
                                <Typography variant='bodyLarge'>
                                    Orders
                                </Typography>
                            </Box>
                        </Paper>
                        <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', width: '50%', gap: '10px'}}>
                            <Box sx={{display: 'flex', height: '70px', width: '70px', borderRadius: '8px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                                <EuroSymbolOutlinedIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                    <EuroSymbolOutlinedIcon preserveAspectRatio='none' sx={{mt: '2px'}} />
                                    <Typography variant='headlineLarge'>
                                        {revenue.value.toFixed(2)}
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    Bedrag
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box sx={{display: 'flex', gap: '16px'}}>
                        <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', width: '50%', gap: '10px'}}>
                            <Box sx={{display: 'flex', height: '70px', width: '70px', borderRadius: '8px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                                <PeopleAltOutlinedIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography variant='headlineLarge'>
                                    {customers.toString()}
                                </Typography>
                                <Typography variant='bodyLarge'>
                                    Klanten
                                </Typography>
                            </Box>
                        </Paper>
                        <Paper variant='outlined'  sx={{borderRadius: '16px', p: '32px', display: 'flex', width: '50%', gap: '10px'}}>
                            <Box sx={{display: 'flex', height: '70px', width: '70px', borderRadius: '8px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                                <SavingsOutlinedIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                    <Typography variant='headlineLarge'>
                                        -
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge'>
                                    Brutowinst
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Box>

                {/* Graph */}
                <Box sx={{display: 'flex', width: '45%'}}>
                    <Paper variant='outlined' sx={{borderRadius: '16px', p: '32px', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            data.value.length !== 0 ?
                            <BarChart
                                dataset={data.value}
                                xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                                series={[{ dataKey: 'value'}]}
                                height={300}
                            />
                            :
                            <Typography variant='bodyLarge'>
                                Geen informatie beschikbaar.
                            </Typography>
                        }
                        
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Dashboard;
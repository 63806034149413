/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_admin_payment_create_mandate_admin_payment_create_mandate__tenant_company_id__post } from '../models/Body_admin_payment_create_mandate_admin_payment_create_mandate__tenant_company_id__post';
import type { Body_admin_signup_tenant_admin_signup_tenant_post } from '../models/Body_admin_signup_tenant_admin_signup_tenant_post';
import type { Body_admin_verify_email_code_admin_verify_email_code_post } from '../models/Body_admin_verify_email_code_admin_verify_email_code_post';
import type { Body_get_subscriptions_admin_subscriptions_post } from '../models/Body_get_subscriptions_admin_subscriptions_post';
import type { Page_PricingModelOut_ } from '../models/Page_PricingModelOut_';
import type { Page_SubscriptionOverviewOut_ } from '../models/Page_SubscriptionOverviewOut_';
import type { SubscriptionIn } from '../models/SubscriptionIn';
import type { SubscriptionOut } from '../models/SubscriptionOut';
import type { TenantCompanyEdit } from '../models/TenantCompanyEdit';
import type { TenantCompanyOut } from '../models/TenantCompanyOut';
import type { TenantCompanyOutList } from '../models/TenantCompanyOutList';
import type { TenantPaymentOrganizationOut } from '../models/TenantPaymentOrganizationOut';
import type { VatEdit } from '../models/VatEdit';
import type { VatIn } from '../models/VatIn';
import type { VatOut } from '../models/VatOut';
import type { VatOutList } from '../models/VatOutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Vats
     * Get all vats.
     * @returns VatOutList Successful Response
     * @throws ApiError
     */
    public getAllVatsAdminVatsGet(): CancelablePromise<VatOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/vats',
        });
    }

    /**
     * Create Vat
     * Create new vat.
     * @param requestBody
     * @returns VatOut Successful Response
     * @throws ApiError
     */
    public createVatAdminVatsPost(
        requestBody: VatIn,
    ): CancelablePromise<VatOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/vats',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Vat By Id
     * Get vat by id.
     * @param vatId
     * @returns VatOut Successful Response
     * @throws ApiError
     */
    public getVatByIdAdminVatsVatIdGet(
        vatId: string,
    ): CancelablePromise<VatOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/vats/{vat_id}',
            path: {
                'vat_id': vatId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Vat
     * Update a vat by id.
     * @param vatId
     * @param requestBody
     * @returns VatOut Successful Response
     * @throws ApiError
     */
    public editVatAdminVatsVatIdPatch(
        vatId: string,
        requestBody: VatEdit,
    ): CancelablePromise<VatOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/vats/{vat_id}',
            path: {
                'vat_id': vatId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Vat
     * Delete a vat by id.
     * @param vatId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteVatAdminVatsVatIdDelete(
        vatId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/vats/{vat_id}',
            path: {
                'vat_id': vatId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Vat
     * Restore a vat by id that was deleted before
     * @param vatId
     * @returns VatOut Successful Response
     * @throws ApiError
     */
    public restoreVatAdminVatsVatIdRestoreGet(
        vatId: string,
    ): CancelablePromise<VatOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/vats/{vat_id}/restore',
            path: {
                'vat_id': vatId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Set Mollie Organization
     * Create payment organization in admin
     * @returns TenantPaymentOrganizationOut Successful Response
     * @throws ApiError
     */
    public adminSetMollieOrganizationAdminResetTenantPaymentOrganizationGet(): CancelablePromise<TenantPaymentOrganizationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/reset_tenant_payment_organization',
        });
    }

    /**
     * Admin Signup Tenant
     * @param requestBody
     * @returns TenantCompanyOut Successful Response
     * @throws ApiError
     */
    public adminSignupTenantAdminSignupTenantPost(
        requestBody: Body_admin_signup_tenant_admin_signup_tenant_post,
    ): CancelablePromise<TenantCompanyOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/signup_tenant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Get Tenant Companies
     * Get all tenants
     * @returns TenantCompanyOutList Successful Response
     * @throws ApiError
     */
    public adminGetTenantCompaniesAdminTenantCompaniesGet(): CancelablePromise<TenantCompanyOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/tenant_companies',
        });
    }

    /**
     * Admin Update Tenant Company
     * Update tenant company
     * @param tenantCompanyId
     * @param requestBody
     * @returns TenantCompanyOut Successful Response
     * @throws ApiError
     */
    public adminUpdateTenantCompanyAdminTenantCompaniesTenantCompanyIdPatch(
        tenantCompanyId: string,
        requestBody: TenantCompanyEdit,
    ): CancelablePromise<TenantCompanyOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/tenant_companies/{tenant_company_id}',
            path: {
                'tenant_company_id': tenantCompanyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Delete Tenant Company
     * Delete tenant company, close subscription, revoke mandates
     * @param tenantCompanyId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public adminDeleteTenantCompanyAdminTenantCompaniesTenantCompanyIdDelete(
        tenantCompanyId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/tenant_companies/{tenant_company_id}',
            path: {
                'tenant_company_id': tenantCompanyId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Send Verification Email
     * Send verification email
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminSendVerificationEmailAdminSendVerificationEmailEmailGet(
        email: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/send_verification_email/{email}',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Verify Email Code
     * Verify email code
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminVerifyEmailCodeAdminVerifyEmailCodePost(
        requestBody: Body_admin_verify_email_code_admin_verify_email_code_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/verify_email_code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Payment Customer Mandates
     * Get customer payment mandates
     * @param tenantCompanyId
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminPaymentCustomerMandatesAdminPaymentCustomerTenantCompanyIdMandatesGet(
        tenantCompanyId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/payment_customer/{tenant_company_id}/mandates',
            path: {
                'tenant_company_id': tenantCompanyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Payment Create Mandate
     * Create customer payment mandate
     * @param tenantCompanyId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminPaymentCreateMandateAdminPaymentCreateMandateTenantCompanyIdPost(
        tenantCompanyId: string,
        requestBody: Body_admin_payment_create_mandate_admin_payment_create_mandate__tenant_company_id__post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/payment_create_mandate/{tenant_company_id}',
            path: {
                'tenant_company_id': tenantCompanyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Admin Payment Revoke Mandate
     * Revoke customer payment mandate
     * @param mandateId
     * @returns any Successful Response
     * @throws ApiError
     */
    public adminPaymentRevokeMandateAdminPaymentRevokeMandateMandateIdGet(
        mandateId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/payment_revoke_mandate/{mandate_id}',
            path: {
                'mandate_id': mandateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pricing Models
     * Get pricing models
     * @param page Page number
     * @param size Page size
     * @returns Page_PricingModelOut_ Successful Response
     * @throws ApiError
     */
    public getPricingModelsAdminPricingModelsGet(
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_PricingModelOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/pricing_models',
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscriptions
     * Get all subscriptions
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_SubscriptionOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSubscriptionsAdminSubscriptionsPost(
        search?: (string | null),
        sortingField?: 'Naam' | 'Contract_datum' | 'Renewal_datum' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_subscriptions_admin_subscriptions_post,
    ): CancelablePromise<Page_SubscriptionOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/subscriptions',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscription By Id
     * Get subscriptions by id
     * @param subscriptionId
     * @returns SubscriptionOut Successful Response
     * @throws ApiError
     */
    public getSubscriptionByIdAdminSubscriptionsSubscriptionIdGet(
        subscriptionId: string,
    ): CancelablePromise<SubscriptionOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/subscriptions/{subscription_id}',
            path: {
                'subscription_id': subscriptionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Subscription
     * Close subscription, end contract
     * @param subscriptionId
     * @returns void
     * @throws ApiError
     */
    public deleteSubscriptionAdminSubscriptionsSubscriptionIdDelete(
        subscriptionId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/subscriptions/{subscription_id}',
            path: {
                'subscription_id': subscriptionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Subscription
     * Create new subscription
     * @param requestBody
     * @returns SubscriptionOut Successful Response
     * @throws ApiError
     */
    public createSubscriptionAdminCreateSubscriptionPost(
        requestBody: SubscriptionIn,
    ): CancelablePromise<SubscriptionOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/create_subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
